// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {Form, useResponsive} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import ErrorCallout from '@shared/design/components/Callout/ErrorCallout';
import Switch from '@shared/design/components/Switch';
import TextTooltip from '@shared/design/components/TextTooltip';
import CreateCompanyStepKinds from '@shared/modules/Company/enums/CreateCompanyStepKinds';
import {CompanyFormProps} from '@shared/modules/Company/forms/CompanyForm';
import SupermoveProductKind from '@shared/modules/SupermoveProduct/enums/SupermoveProductKind';
import SupermoveProductStatus from '@shared/modules/SupermoveProduct/enums/SupermoveProductStatus';
import SupermoveProductForm, {
  SupermoveProductFormProps,
} from '@shared/modules/SupermoveProduct/forms/SupermoveProductForm';

const Row = Styled.View`
  flex-direction: row;
`;

const ProductButton = Styled.ButtonV2<{isSetupComplete?: boolean; hasError?: boolean}>`
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding-vertical: 14px;
  padding-horizontal: 12px;
  background-color: ${({isSetupComplete}) => (isSetupComplete ? colors.blue.accent : colors.white)};
  border-color: ${({hasError, isSetupComplete}) => (hasError ? colors.red.warning : isSetupComplete ? colors.blue.interactive : colors.gray.border)};
  border-width: 1px;
  border-radius: 4px;
`;

const ProductButtonText = Styled.Text<{vars: {isSetupComplete: boolean}}>`
  ${({vars}) => (vars.isSetupComplete ? Typography.Responsive.Label : Typography.Responsive.Body)}
  color: ${({vars}) => (vars.isSetupComplete ? colors.blue.interactive : colors.gray.secondary)};
`;

const ProductStatusText = Styled.Text<{vars: {isSetupComplete: boolean}}>`
  ${Typography.Responsive.Body}
  color: ${({vars}) => (vars.isSetupComplete ? colors.blue.interactive : colors.gray.secondary)};
`;

const IconContainer = Styled.View``;

const activateSupermoveProductForm = ({
  form,
  field,
  crewField,
  productKind,
}: {
  form: Form<{companyForm: CompanyFormProps}>;
  field: string;
  crewField: string;
  productKind: string;
}) => {
  form.setFieldValue(`${field}.status`, SupermoveProductStatus.ACTIVE);
  if (productKind === SupermoveProductKind.OFFICE) {
    form.setFieldValue(`${crewField}.status`, SupermoveProductStatus.ACTIVE);
  }
};

const deactivateSupermoveProductForm = ({
  form,
  field,
  crewField,
  productKind,
}: {
  form: Form<{companyForm: CompanyFormProps}>;
  field: string;
  crewField: string;
  productKind: string;
}) => {
  // When deactivating, we reset the form values to the initial state
  const existingProductForm = _.get(form.values, field);
  form.setFieldValue(
    field,
    SupermoveProductForm.new({
      kind: existingProductForm.kind,
      status: SupermoveProductStatus.INACTIVE,
    }),
  );
  if (productKind === SupermoveProductKind.OFFICE) {
    const crewProductForm = _.get(form.values, crewField);
    form.setFieldValue(
      crewField,
      SupermoveProductForm.new({
        kind: crewProductForm.kind,
        status: SupermoveProductStatus.INACTIVE,
      }),
    );
  }
};

const ProductSetupToggle = ({
  form,
  field,
  productFormsField,
  productStepKind,
  setCurrentStepKind,
  hasError,
  hasSetup,
  tooltip,
}: {
  form: Form<{companyForm: CompanyFormProps}>;
  field: string;
  productFormsField: string;
  productStepKind: string;
  setCurrentStepKind: (kind: string) => void;
  hasError: boolean;
  hasSetup: boolean;
  tooltip?: string;
}) => {
  const responsive = useResponsive();
  const isSetupComplete = _.get(form.values, `${field}.isSetupComplete`);
  const status = _.get(form.values, `${field}.status`);
  const isActive = status === SupermoveProductStatus.ACTIVE;
  const productKind = _.get(form.values, `${field}.kind`);
  return (
    <Row style={{alignItems: 'center'}}>
      {hasSetup ? (
        <Switch
          isResponsive
          isOn={isActive}
          onChange={() => {
            const crewField = `${productFormsField}.${_.findIndex(_.get(form.values, productFormsField), (productForm: SupermoveProductFormProps) => productForm.kind === SupermoveProductKind.CREW)}`;
            if (isActive) {
              deactivateSupermoveProductForm({form, field, crewField, productKind});
            } else {
              activateSupermoveProductForm({form, field, crewField, productKind});
            }
          }}
        />
      ) : (
        <Space width={24} />
      )}
      <Space width={12} />
      <ProductButton
        disabled={!hasSetup || !isActive}
        isSetupComplete={isSetupComplete}
        hasError={hasError}
        onPress={() => setCurrentStepKind(productStepKind)}
        style={isActive ? {} : {opacity: 0.5}}
      >
        <Icon
          source={Icon.PhoneAlt}
          size={16}
          color={isSetupComplete ? colors.blue.interactive : colors.gray.secondary}
        />
        <Space width={8} />
        <ProductButtonText vars={{isSetupComplete}} responsive={responsive}>
          {CreateCompanyStepKinds.getStepTitle(productStepKind)}
        </ProductButtonText>
        <Space flex={1} />
        {hasSetup && (
          <React.Fragment>
            <Space width={8} />
            <ProductStatusText vars={{isSetupComplete}} responsive={responsive}>
              {isSetupComplete ? 'Setup Complete' : 'Click to Set Up'}
            </ProductStatusText>
            <Space width={8} />
            <Icon
              source={Icon.AngleRight}
              size={12}
              color={isSetupComplete ? colors.blue.interactive : colors.gray.secondary}
            />
          </React.Fragment>
        )}
        {!!tooltip && (
          <React.Fragment>
            <Space width={8} />
            <TextTooltip text={tooltip} placement={'top'}>
              <IconContainer>
                <Icon source={Icon.InfoCircle} size={16} color={colors.gray.secondary} />
              </IconContainer>
            </TextTooltip>
          </React.Fragment>
        )}
      </ProductButton>
    </Row>
  );
};

const ProductsOverviewFields = ({
  form,
  field,
  setCurrentStepKind,
}: {
  form: Form<{companyForm: CompanyFormProps}>;
  field: string;
  setCurrentStepKind: (kind: string) => void;
}) => {
  const productFormsField = `${field}.supermoveProductForms`;
  const supermoveProductsError = _.get(form.errors, productFormsField);
  const supermoveProductForms = _.get(form.values, productFormsField);
  return (
    <React.Fragment>
      {supermoveProductsError && (
        <React.Fragment>
          <ErrorCallout text={supermoveProductsError} />
          <Space height={16} />
        </React.Fragment>
      )}
      {supermoveProductForms.map(
        (supermoveProductForm: SupermoveProductFormProps, index: number) => {
          const hasError =
            supermoveProductsError &&
            supermoveProductForm.status === SupermoveProductStatus.ACTIVE &&
            !supermoveProductForm.isSetupComplete;
          return (
            <React.Fragment>
              <ProductSetupToggle
                form={form}
                productFormsField={productFormsField}
                field={`${field}.supermoveProductForms.${index}`}
                productStepKind={CreateCompanyStepKinds.getStepKindFromSupermoveProductKind(
                  supermoveProductForm.kind,
                )}
                setCurrentStepKind={setCurrentStepKind}
                hasError={hasError}
                hasSetup={!_.includes([SupermoveProductKind.CREW], supermoveProductForm.kind)}
                tooltip={
                  supermoveProductForm.kind === SupermoveProductKind.CREW
                    ? 'Crew App and Office App are activated together.'
                    : undefined
                }
              />
              <Space height={16} />
            </React.Fragment>
          );
        },
      )}
    </React.Fragment>
  );
};

export default ProductsOverviewFields;
