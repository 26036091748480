// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {FixedSizeList} from 'react-window';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {useResponsive, useHover, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';

const Container = Styled.View`
  background-color: ${colors.white}
  border-width: 1px;
  border-top-width: ${({isEnabledHeader}: any) => (isEnabledHeader ? '1px' : '0px')};
  border-color: ${colors.gray.border};
  border-top-left-radius: ${({isEnabledHeader}: any) => (isEnabledHeader ? '4px' : '0px')};
  border-top-right-radius: ${({isEnabledHeader}: any) => (isEnabledHeader ? '4px' : '0px')};
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  overflow: hidden;
`;

const TableLoadingContainer = Styled.View`
  min-height: 70px;
`;

const Header = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: ${({isDense}: any) => (isDense ? '8px' : '12px')};
  min-height: 16px;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: ${({isDense}: any) => (isDense ? '8px' : '12px')};
  background-color: ${(props: any) => (props.isRowSelected ? colors.Blue10 : colors.white)};
`;

const RowButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  padding-vertical: ${({isDense}: any) => (isDense ? '8px' : '12px')};
  background-color: ${(props: any) =>
    props.isHovered || props.isRowSelected ? colors.Blue10 : colors.white};
  cursor: pointer;
`;

const Cell = Styled.View`
  flex-direction: row;
  padding-horizontal: ${({mobile}: any) => (mobile ? 8 : 20)}px;
`;

const EmptyStateContainer = Styled.View`
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: ${({height, isDense}: any) => height || (isDense ? '40px' : '120px')};
`;

const EmptyStateText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.secondary};
`;

const HeaderText = Styled.Text`
  ${Typography.Label3}
`;

const HeaderSubtext = Styled.Text`
  ${Typography.Micro}
  margin-top: 2px;
`;

const CellTextTypography = Typography.Responsive.Body;
const CellText = Styled.Text`
  ${CellTextTypography}
`;

const CellSubtext = Styled.Text`
  ${Typography.Body};
  color: ${colors.gray.secondary};
  margin-top: 2px;
`;

const CellLink = Styled.Text`
  ${Typography.Responsive.Link}
`;

const Line = Styled.View`
  height: 1px;
  width: 100%;
  background-color: ${colors.gray.border};
`;

const ScrollingTableContainer = Styled.View`
  flex: 1;
`;

const CollapsibleTableContainer = Styled.View`
  border-radius: 4px;
  border-color: ${colors.gray300};
  border-width: 1px;
`;

const CollapsedRow = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  padding-horizontal: 12px;
  height: 48px;
`;

const SectionHeader = Styled.Text`
  ${Typography.Subheading}
`;

const Skeleton = Styled.View`
  height: ${({height}: any) => height};
  width: ${({width}: any) => width};
  background-color: ${colors.gray.border};
  border-radius: 5px;
`;

const SkeletonTableContainer = Styled.View`
  background-color: ${colors.white}
  overflow: hidden;
`;

const SkeletonTableBodyContainer = Styled.View`
  border-width: 1px;
  border-top-width: 0px;
  border-color: ${colors.gray.border};
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
`;

const View = Styled.View`
`;

const PreventPropagationContainer = ({children, style}: any) => {
  return (
    <View
      onStartShouldSetResponder={(event: any) => true}
      onTouchEnd={(e: any) => {
        e.stopPropagation();
      }}
      style={style}
    >
      {children}
    </View>
  );
};

/**
 * Table column definitions support the following attributes:
 * {
 *  flex: int,
 *  minWidth: int, (Only applicable for flex)
 *  width: int, (Fixed width column, overridden by flex)
 *  isHidden: bool, (Flags whether a column should be hidden or not. Useful for feature flagged columns, etc.)
 *  headerContent: func, (A function that returns a React component to render in the column header)
 *  cellContent: func, (A function that returns a React component to render in each cell for the column)
 *  footerContent: func, (A function that returns a React component to render in the column footer)
 * }
 */

const HeaderTextContainer = ({isRequired = false, children}: any) => {
  return (
    <React.Fragment>
      {isRequired && <HeaderText style={{color: colors.red.warning}}>*</HeaderText>}
      <HeaderText>{children}</HeaderText>
      {isRequired && (
        <HeaderText
          style={{
            color: colors.gray.tertiary,
            fontStyle: 'italic',
          }}
        >
          {` –– required`}
        </HeaderText>
      )}
    </React.Fragment>
  );
};

const CellTextWithDefault = ({children, defaultText = '-'}: any) => {
  return <CellText>{children || defaultText}</CellText>;
};

const TableHeader = ({columnDefinitions, isDense, responsive, style}: any) => {
  return (
    <Header isDense={isDense} {...responsive} style={style}>
      {columnDefinitions.map(
        (
          {flex, minWidth, maxWidth, width, isHidden, cellStyle, headerContent}: any,
          index: any,
        ) => {
          if (isHidden) {
            return null;
          }

          const style = {
            ...(flex ? {flex, minWidth, maxWidth} : {width}),
            ...cellStyle,
          };

          return (
            <Cell key={index} style={style} {...responsive}>
              {headerContent({...responsive})}
            </Cell>
          );
        },
      )}
    </Header>
  );
};

const TableRow = ({
  item,
  rowIndex,
  columnDefinitions,
  onRowPress,
  isClickable,
  isDense,
  responsive,
  style,
  isRowSelected,
  isVirtualized,
  rowHook,
}: any) => {
  const hook = rowHook.hook(rowHook.hookArgument);
  const {isHovered, ref} = useHover();
  const RowComponent = isClickable ? RowButton : Row;

  return (
    <React.Fragment>
      {!isVirtualized && rowIndex > 0 && <Line />}
      <RowComponent
        ref={ref}
        isHovered={isClickable && isHovered}
        isDense={isDense}
        onPress={() => {
          onRowPress(item);
          hook && hook.handleOpen && hook.handleOpen();
        }}
        style={style}
        isRowSelected={isRowSelected}
      >
        {columnDefinitions.map(
          (
            {flex, minWidth, maxWidth, width, isHidden, cellStyle, cellContent}: any,
            index: any,
          ) => {
            if (isHidden) {
              return null;
            }

            const style = {
              ...(flex ? {flex, minWidth, maxWidth} : {width}),
              ...cellStyle,
            };

            return (
              <Cell key={index} style={style} {...responsive}>
                {cellContent({item, rowIndex, responsive})}
              </Cell>
            );
          },
        )}
      </RowComponent>
      <rowHook.Component {...hook} {...rowHook.componentProps} item={item} />
    </React.Fragment>
  );
};

const VirtualRow = ({data, index, style}: any) => {
  const {
    columnDefinitions,
    items,
    onRowPress,
    isClickable,
    isDense,
    rowStyle,
    calculateRowStyle,
    responsive,
    itemKey,
    activeRowIndex,
    getIsRowHidden,
  } = data;
  const item = items[index];
  return getIsRowHidden({item, index}) ? null : (
    <TableRow
      // @ts-expect-error TS(2322): Type '{ isVirtualized: true; key: any; columnDefin... Remove this comment to see the full error message
      isVirtualized
      key={itemKey ? _.get(item, itemKey) : index}
      columnDefinitions={columnDefinitions}
      item={item}
      rowIndex={index}
      onRowPress={onRowPress}
      isClickable={isClickable}
      isDense={isDense}
      responsive={responsive}
      style={{
        ...rowStyle,
        ...calculateRowStyle({item, rowIndex: index}),
        zIndex: 10000 - index,
        borderTopWidth: index > 0 ? '1px' : 0,
        borderTopColor: colors.gray.border,
        ...style,
      }}
      isRowSelected={index === activeRowIndex}
    />
  );
};

const VirtualBody = ({
  virtualizedTableHeight,
  virtualizedItemHeight,
  virtualizedListRef,
  columnDefinitions,
  items,
  onRowPress,
  isClickable,
  isDense,
  rowStyle,
  calculateRowStyle,
  responsive,
  itemKey,
  activeRowIndex,
  getIsRowHidden,
}: any) => (
  <FixedSizeList
    height={virtualizedTableHeight}
    itemCount={items.length}
    itemSize={virtualizedItemHeight}
    width={'100%'}
    itemData={{
      columnDefinitions,
      items,
      onRowPress,
      isClickable,
      isDense,
      rowStyle,
      calculateRowStyle,
      responsive,
      itemKey,
      activeRowIndex,
      getIsRowHidden,
    }}
    itemKey={(index) => index}
    ref={virtualizedListRef}
  >
    {VirtualRow}
  </FixedSizeList>
);

const TableBody = ({
  columnDefinitions,
  items,
  onRowPress,
  isClickable,
  emptyStateHeight,
  emptyStateText,
  EmptyStateComponent,
  loading,
  hasError,
  isDense,
  rowStyle,
  calculateRowStyle,
  rowHook,
  responsive,

  // itemKey is used to specify the key value of the row
  // so React knows which rows to re-render
  itemKey,

  activeRowIndex,
  getIsRowHidden,

  // virtualized
  isVirtualized,

  virtualizedListRef,
  virtualizedTableHeight,
  virtualizedItemHeight,
}: any) => {
  if (loading) {
    return (
      <TableLoadingContainer>
        <PageLoadingIndicator />
      </TableLoadingContainer>
    );
  } else if (hasError) {
    return (
      <EmptyStateContainer isDense={isDense}>
        {!isDense && (
          <React.Fragment>
            <Icon source={Icon.ExclamationTriangle} size={18} color={colors.gray.tertiary} />
            <Space height={8} />
          </React.Fragment>
        )}
        <EmptyStateText>
          An error has occurred, please refresh the page. If the problem persists, please contact
          us.
        </EmptyStateText>
      </EmptyStateContainer>
    );
  } else if (items.length === 0) {
    const containerHeight = EmptyStateComponent ? emptyStateHeight || 300 : emptyStateHeight;
    return (
      <EmptyStateContainer height={containerHeight} isDense={isDense}>
        {EmptyStateComponent ? (
          <EmptyStateComponent />
        ) : (
          <EmptyStateText>{emptyStateText}</EmptyStateText>
        )}
      </EmptyStateContainer>
    );
  }

  return isVirtualized ? (
    <VirtualBody
      virtualizedTableHeight={virtualizedTableHeight}
      virtualizedItemHeight={virtualizedItemHeight}
      virtualizedListRef={virtualizedListRef}
      columnDefinitions={columnDefinitions}
      items={items}
      onRowPress={onRowPress}
      isClickable={isClickable}
      isDense={isDense}
      rowStyle={rowStyle}
      calculateRowStyle={calculateRowStyle}
      responsive={responsive}
      itemKey={itemKey}
      activeRowIndex={activeRowIndex}
      getIsRowHidden={getIsRowHidden}
    />
  ) : (
    <React.Fragment>
      {items.map((item: any, index: any) =>
        getIsRowHidden({item, index}) ? null : (
          <TableRow
            key={(itemKey && _.get(item, itemKey)) || index}
            // @ts-expect-error TS(2322): Type '{ key: any; columnDefinitions: any; item: an... Remove this comment to see the full error message
            columnDefinitions={columnDefinitions}
            item={item}
            rowIndex={index}
            rowHook={rowHook}
            onRowPress={onRowPress}
            isClickable={isClickable}
            isDense={isDense}
            responsive={responsive}
            style={{
              ...rowStyle,
              ...calculateRowStyle({item, rowIndex: index}),
              zIndex: 10000 - index,
            }}
            isRowSelected={index === activeRowIndex}
          />
        ),
      )}
    </React.Fragment>
  );
};

const TableFooter = ({columnDefinitions, isDense, responsive, style}: any) => {
  return (
    <Header isDense={isDense} {...responsive} style={style}>
      {columnDefinitions.map(
        ({flex, minWidth, width, isHidden, cellStyle, footerContent}: any, index: any) => {
          if (isHidden) {
            return null;
          }

          const style = {
            ...(flex ? {flex, minWidth} : {width}),
            ...cellStyle,
          };

          return (
            <Cell key={index} style={style} {...responsive}>
              {footerContent({...responsive})}
            </Cell>
          );
        },
      )}
    </Header>
  );
};

const Table = ({
  columnDefinitions,
  items,
  onRowPress,
  isClickable,
  emptyStateHeight,
  emptyStateText,
  EmptyStateComponent,
  loading,
  hasError,
  hasFooter,
  isDense,
  headerStyle,
  rowStyle,
  calculateRowStyle,
  rowHook,
  itemKey,
  activeRowIndex,
  isEnabledHeader,
  containerStyle,
  getIsRowHidden,

  // virtualized
  isVirtualized,

  virtualizedListRef,
  virtualizedTableHeight,
  virtualizedItemHeight,
}: any) => {
  const responsive = useResponsive();

  return (
    <Container isEnabledHeader={isEnabledHeader} style={containerStyle}>
      {isEnabledHeader && (
        <React.Fragment>
          <TableHeader
            columnDefinitions={columnDefinitions}
            isDense={isDense}
            responsive={responsive}
            style={headerStyle}
          />
          <Line />
        </React.Fragment>
      )}
      <TableBody
        columnDefinitions={columnDefinitions}
        items={items}
        onRowPress={onRowPress}
        isClickable={isClickable}
        emptyStateHeight={emptyStateHeight}
        emptyStateText={emptyStateText}
        EmptyStateComponent={EmptyStateComponent}
        loading={loading}
        hasError={hasError}
        isDense={isDense}
        rowStyle={rowStyle}
        calculateRowStyle={calculateRowStyle}
        rowHook={rowHook}
        responsive={responsive}
        itemKey={itemKey}
        activeRowIndex={activeRowIndex}
        getIsRowHidden={getIsRowHidden}
        isVirtualized={isVirtualized}
        virtualizedListRef={virtualizedListRef}
        virtualizedTableHeight={virtualizedTableHeight}
        virtualizedItemHeight={virtualizedItemHeight}
      />
      {hasFooter && (
        <React.Fragment>
          <Line />
          <TableFooter
            columnDefinitions={columnDefinitions}
            isDense={isDense}
            responsive={responsive}
            style={headerStyle}
          />
        </React.Fragment>
      )}
    </Container>
  );
};

const FixedHeaderScrollTable = ({
  style,
  headerStyle,
  scrollViewStyle,
  scrollViewContentContainerStyle,
  columnDefinitions,
  isDense,
  ...props
}: any) => {
  const responsive = useResponsive();

  return (
    <ScrollingTableContainer style={style}>
      <TableHeader
        columnDefinitions={columnDefinitions}
        isDense={isDense}
        responsive={responsive}
        style={headerStyle}
      />
      <ScrollView style={scrollViewStyle} contentContainerStyle={scrollViewContentContainerStyle}>
        <Table
          {...props}
          columnDefinitions={columnDefinitions}
          isDense={isDense}
          isEnabledHeader={false}
        />
      </ScrollView>
    </ScrollingTableContainer>
  );
};

const CollapsibleTable = ({
  title,
  TitleComponent,
  columnDefinitions,
  emptyStateText,
  items,
  itemKey,
  defaultCollapsed,
  isClickable,
  onRowPress,
  collapsibleTableStyle,
  tableContainerStyle,
}: any) => {
  const responsive = useResponsive();
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);
  return (
    <CollapsibleTableContainer style={collapsibleTableStyle}>
      <CollapsedRow
        onPress={() => {
          setIsCollapsed(!isCollapsed);
        }}
      >
        <Icon
          source={isCollapsed ? Icon.AngleRight : Icon.AngleDown}
          color={colors.black}
          size={responsive.desktop ? 12 : 14}
          style={{width: 20}}
        />
        <Space width={8} />
        <SectionHeader>{title}</SectionHeader>
        <TitleComponent />
      </CollapsedRow>
      {!isCollapsed && (
        <Table
          columnDefinitions={columnDefinitions}
          emptyStateText={emptyStateText}
          items={items}
          itemKey={itemKey}
          onRowPress={onRowPress}
          isClickable={isClickable}
          containerStyle={tableContainerStyle}
        />
      )}
    </CollapsibleTableContainer>
  );
};

// VirtualizedTable sets isVirtualized by default and adds required props
// It's easier to read Table.Virtualized when being used
const VirtualizedTable = ({
  columnDefinitions,
  items,
  onRowPress,
  isClickable,
  emptyStateText,
  loading,
  hasError,
  hasFooter,
  isDense,
  headerStyle,
  rowStyle,
  calculateRowStyle,
  itemKey,
  activeRowIndex,
  isEnabledHeader,
  containerStyle,
  getIsRowHidden,

  // virtualized
  virtualizedListRef,

  virtualizedTableHeight,
  virtualizedItemHeight,
}: any) => (
  <Table
    columnDefinitions={columnDefinitions}
    items={items}
    onRowPress={onRowPress}
    isClickable={isClickable}
    emptyStateText={emptyStateText}
    loading={loading}
    hasError={hasError}
    hasFooter={hasFooter}
    isDense={isDense}
    headerStyle={headerStyle}
    rowStyle={rowStyle}
    calculateRowStyle={calculateRowStyle}
    itemKey={itemKey}
    activeRowIndex={activeRowIndex}
    isEnabledHeader={isEnabledHeader}
    containerStyle={containerStyle}
    getIsRowHidden={getIsRowHidden}
    // virtualized
    isVirtualized
    virtualizedListRef={virtualizedListRef}
    virtualizedTableHeight={virtualizedTableHeight}
    virtualizedItemHeight={virtualizedItemHeight}
  />
);

const SkeletonLoader = ({columnDefinitions, numberOfRows}: any) => {
  const responsive = useResponsive();

  // Occasionally the skeleton loader mounts for a moment before the columnDefinitions is not null
  // so this ensures that there is no error
  if (!columnDefinitions) {
    return null;
  }

  return (
    <SkeletonTableContainer>
      <TableHeader columnDefinitions={columnDefinitions} isDense responsive={responsive} />
      <SkeletonTableBodyContainer>
        {Array.from({length: numberOfRows}).map((_, index) => (
          <Row
            key={index}
            style={{
              borderTopWidth: index > 0 ? '1px' : 0,
              borderTopColor: colors.gray.border,
            }}
          >
            {columnDefinitions.map(({flex, minWidth, width, cellStyle}: any, index: any) => {
              const style = {
                ...(flex ? {flex, minWidth} : {width}),
                ...cellStyle,
              };
              return (
                <Cell key={index} style={style} {...responsive}>
                  <Skeleton height={18} width={'100%'} />
                </Cell>
              );
            })}
          </Row>
        ))}
      </SkeletonTableBodyContainer>
    </SkeletonTableContainer>
  );
};

Table.HeaderText = HeaderTextContainer;
Table.HeaderSubtext = HeaderSubtext;
Table.CellText = CellText;
Table.CellTextTypography = CellTextTypography;
Table.CellSubtext = CellSubtext;
Table.CellTextWithDefault = CellTextWithDefault;
Table.CellLink = CellLink;
Table.FixedHeaderScroll = FixedHeaderScrollTable;
Table.Collapsible = CollapsibleTable;
Table.Virtualized = VirtualizedTable;
Table.SkeletonLoader = SkeletonLoader;
Table.PreventPropagationContainer = PreventPropagationContainer;

// --------------------------------------------------
// Props
// --------------------------------------------------

Table.propTypes = {
  columnDefinitions: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  onRowPress: PropTypes.func,
  rowHook: PropTypes.object,
  isClickable: PropTypes.bool,
  emptyStateHeight: PropTypes.number,
  emptyStateText: PropTypes.string,
  EmptyStateComponent: PropTypes.func,
  loading: PropTypes.bool,
  hasError: PropTypes.bool,
  hasFooter: PropTypes.bool,
  isDense: PropTypes.bool,
  headerStyle: PropTypes.object,
  rowStyle: PropTypes.object,
  calculateRowStyle: PropTypes.func,
  itemKey: PropTypes.string,
  activeRowIndex: PropTypes.number,
  isEnabledHeader: PropTypes.bool,
  containerStyle: PropTypes.object,
  getIsRowHidden: PropTypes.func,
  isVirtualized: PropTypes.bool,
  virtualizedListRef: PropTypes.object,
  virtualizedTableHeight: PropTypes.number,
  virtualizedItemHeight: PropTypes.number,
};

Table.defaultProps = {
  onRowPress: () => null,
  rowHook: undefined,
  isClickable: false,
  emptyStateHeight: null,
  emptyStateText: 'No results',
  EmptyStateComponent: null,
  loading: false,
  hasError: false,
  hasFooter: false,
  isDense: false,
  headerStyle: {},
  rowStyle: {},
  calculateRowStyle: () => ({}),
  itemKey: null,
  activeRowIndex: null,
  isEnabledHeader: true,
  containerStyle: null,
  getIsRowHidden: () => false,
  isVirtualized: false,
  virtualizedListRef: null,
  virtualizedTableHeight: 0,
  virtualizedItemHeight: 0,
};

TableHeader.propTypes = {
  columnDefinitions: PropTypes.array.isRequired,
  responsive: PropTypes.object.isRequired,
  isDense: PropTypes.bool,
  style: PropTypes.object,
};

TableHeader.defaultProps = {
  isDense: false,
  style: {
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: colors.gray.border,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
};

TableRow.propTypes = {
  rowHook: PropTypes.shape({
    hook: PropTypes.func,
    hookArgument: PropTypes.object,
    Component: PropTypes.func,
    componentProps: PropTypes.object,
  }),
};

TableRow.defaultProps = {
  rowHook: {
    hook: () => ({}),
    hookArgument: {},
    Component: () => null,
    componentProps: {},
  },
};

CollapsibleTable.propTypes = {
  title: PropTypes.string,
  TitleComponent: PropTypes.func,
  columnDefinitions: PropTypes.array.isRequired,
  emptyStateText: PropTypes.string,
  items: PropTypes.array.isRequired,
  itemKey: PropTypes.string,
  defaultCollapsed: PropTypes.bool,
  onRowPress: PropTypes.func,
  isClickable: PropTypes.bool,
  collapsibleTableStyle: PropTypes.object,
  tableContainerStyle: PropTypes.object,
};

CollapsibleTable.defaultProps = {
  title: '',
  TitleComponent: () => null,
  emptyStateText: 'No results',
  itemKey: null,
  defaultCollapsed: true,
  onRowPress: () => null,
  isClickable: false,
  collapsibleTableStyle: {},
  tableContainerStyle: {
    borderTopStartRadius: 0,
    borderTopEndRadius: 0,
    borderBottomStartRadius: 8,
    borderBottomEndRadius: 8,
    borderTopWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
};

VirtualizedTable.propTypes = {
  columnDefinitions: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  onRowPress: PropTypes.func,
  isClickable: PropTypes.bool,
  emptyStateText: PropTypes.string,
  loading: PropTypes.bool,
  hasError: PropTypes.bool,
  hasFooter: PropTypes.bool,
  isDense: PropTypes.bool,
  headerStyle: PropTypes.object,
  rowStyle: PropTypes.object,
  calculateRowStyle: PropTypes.func,
  itemKey: PropTypes.string,
  activeRowIndex: PropTypes.number,
  isEnabledHeader: PropTypes.bool,
  containerStyle: PropTypes.object,
  getIsRowHidden: PropTypes.func,
  virtualizedListRef: PropTypes.object,
  virtualizedTableHeight: PropTypes.number.isRequired,
  virtualizedItemHeight: PropTypes.number.isRequired,
};

VirtualizedTable.defaultProps = {
  onRowPress: () => null,
  isClickable: false,
  emptyStateText: 'No results',
  loading: false,
  hasError: false,
  hasFooter: false,
  isDense: false,
  headerStyle: {},
  rowStyle: {},
  calculateRowStyle: () => ({}),
  itemKey: null,
  activeRowIndex: null,
  isEnabledHeader: true,
  containerStyle: null,
  getIsRowHidden: () => false,
  virtualizedListRef: null,
};

export default Table;
