// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  ScrollViewType,
  UrlFiltersType,
  useNavigation,
  usePagination,
  useQuery,
  useResponsive,
  useScrollView,
  useUrlFilters,
} from '@supermove/hooks';
import {Company, CompanyModel} from '@supermove/models';

// App
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import AdminAppPage from 'modules/App/components/AdminAppPage';
import CompaniesPageContentDesktop from 'modules/Company/List/components/CompaniesPageContentDesktop';
import CompaniesPageContentMobile from 'modules/Company/List/components/CompaniesPageContentMobile';
import CompaniesListPageHeader from 'modules/Company/components/CompaniesListPageHeader';

const getHandleUpdateGroupBy =
  ({urlFilters}: {urlFilters: UrlFiltersType}) =>
  (groupBy?: string) =>
    urlFilters.handleReset({
      groupBy,
      ...(groupBy === Company.GROUP_BY.STATUS ? {status: 'ALL'} : {}),
    });

const getHandleUpdateGroup =
  ({urlFilters}: {urlFilters: UrlFiltersType}) =>
  (group?: string) => {
    const groupBy = urlFilters.get('groupBy');
    const updates = {group, page: undefined};

    // The 'group' param is used by the ui to know which tab is selected.
    // The additional changes based on the 'groupBy' handles the filtering.
    if (groupBy === Company.GROUP_BY.KIND) {
      urlFilters.handleUpdate({...updates, companyKinds: group ? [group] : undefined});
    } else if (groupBy === Company.GROUP_BY.PRODUCT) {
      urlFilters.handleUpdate({...updates, productKinds: group ? [group] : undefined});
    } else if (groupBy === Company.GROUP_BY.STATUS) {
      // Status filtering uniquely handles None vs 'ALL'
      urlFilters.handleUpdate({...updates, status: group || 'ALL'});
    }
  };

const getVisibleCompanies = ({
  companies,
  urlFilters,
}: {
  companies: CompanyModel[];
  urlFilters: UrlFiltersType;
}) => {
  const groupBy = urlFilters.get('groupBy');
  const group = urlFilters.get('group');

  return companies.filter((company) => {
    if (groupBy === Company.GROUP_BY.STATUS) {
      if (_.includes(['ACTIVE', 'INACTIVE'], group)) {
        return urlFilters.get('group') === 'ACTIVE' ? company.isActive : !company.isActive;
      }
      // When viewing "All" companies by status, do not filter by status.
      return true;
    }

    // Default is return active companies. This handles immediately removing
    // the company from the list if the company is deactivated.
    return company.isActive;
  });
};

const MobileScrollWrapper = ({
  mobileScrollView,
  children,
}: {
  mobileScrollView: ScrollViewType;
  children: React.ReactNode;
}) => {
  const responsive = useResponsive();

  return (
    <React.Fragment>
      {responsive.desktop ? (
        children
      ) : (
        <ScrollView ref={mobileScrollView.ref} contentContainerStyle={{flex: 1}}>
          {children}
        </ScrollView>
      )}
    </React.Fragment>
  );
};

const CompaniesPageContent = ({urlFilters}: {urlFilters: UrlFiltersType}) => {
  const mobileScrollView = useScrollView();
  const responsive = useResponsive();
  const currentPage = _.toNumber(urlFilters.get('page')) || 1;

  const {loading, data, refetch} = useQuery(CompaniesListPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      searchQuery: urlFilters.get('searchQuery'),
      companyKinds: urlFilters.get('companyKinds'),
      organizationKinds: urlFilters.get('organizationKinds'),
      productKinds: urlFilters.get('productKinds'),
      status: urlFilters.get('status'),
      numberOfLicensesMin: urlFilters.get('numberOfLicensesMin'),
      numberOfLicensesMax: urlFilters.get('numberOfLicensesMax'),
      numberOfTrucksMin: urlFilters.get('numberOfTrucksMin'),
      numberOfTrucksMax: urlFilters.get('numberOfTrucksMax'),
      createdAtMin: urlFilters.get('createdAtMin'),
      createdAtMax: urlFilters.get('createdAtMax'),
      pagination: {
        page: currentPage,
        resultsPerPage: 50,
      },
    },
  });

  const pagination = usePagination({
    currentPage,
    paginationMetadata: _.get(data, 'paginatedList.paginationMetadata'),
    onChangePage: (page: number) => {
      urlFilters.handleUpdate({page});
      mobileScrollView.ref.current && mobileScrollView.handleScrollToTop({animated: true});
    },
  });

  return (
    <MobileScrollWrapper mobileScrollView={mobileScrollView}>
      <AdminAppPage HeaderElement={<CompaniesListPageHeader />}>
        <Loading loading={!data} as={PageLoadingIndicator}>
          {() => {
            const companies = getVisibleCompanies({
              companies: data.paginatedList.companies,
              urlFilters,
            });
            const pageContentProps = {
              urlFilters,
              companies,
              isLoading: loading,
              handleUpdateGroupBy: getHandleUpdateGroupBy({urlFilters}),
              handleUpdateGroup: getHandleUpdateGroup({urlFilters}),
              pagination,
              refetch,
            };

            return (
              <React.Fragment>
                {responsive.desktop ? (
                  <CompaniesPageContentDesktop {...pageContentProps} />
                ) : (
                  <CompaniesPageContentMobile {...pageContentProps} />
                )}
              </React.Fragment>
            );
          }}
        </Loading>
      </AdminAppPage>
    </MobileScrollWrapper>
  );
};

const CompaniesListPage = () => {
  const mobileScrollView = useScrollView();
  const {navigator, params} = useNavigation();
  const urlFilters = useUrlFilters({
    getRoute: () => '/companies',
    filterKeys: [
      'page',
      'groupBy',
      'group',
      'searchQuery',
      'companyKinds',
      'organizationKinds',
      'productKinds',
      'status',
      'numberOfLicensesMin',
      'numberOfLicensesMax',
      'numberOfTrucksMin',
      'numberOfTrucksMax',
      'createdAtMin',
      'createdAtMax',
    ],
    navigationLib: 'react-navigation',
  });

  if (!urlFilters.get('groupBy')) {
    navigator.replace('CompaniesListPage', {groupBy: Company.GROUP_BY_DEFINITIONS[0].value});
  }

  return (
    <MobileScrollWrapper mobileScrollView={mobileScrollView}>
      <CompaniesPageContent urlFilters={urlFilters} />
    </MobileScrollWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompaniesListPage.query = gql`
  ${usePagination.fragment}
  ${CompaniesPageContentMobile.fragment}
  ${CompaniesPageContentDesktop.fragment}

  query CompaniesListPage(
    $searchQuery: String
    $companyKinds: [String]
    $organizationKinds: [String]
    $productKinds: [String]
    $status: String
    $numberOfLicensesMin: Int
    $numberOfLicensesMax: Int
    $numberOfTrucksMin: Int
    $numberOfTrucksMax: Int
    $createdAtMin: String
    $createdAtMax: String
    $pagination: PaginationInput!
  ) {
    ${gql.query}
    paginatedList: filteredCompaniesPaginatedList(
      searchQuery: $searchQuery
      companyKinds: $companyKinds
      organizationKinds: $organizationKinds
      productKinds: $productKinds
      status: $status
      numberOfLicensesMin: $numberOfLicensesMin
      numberOfLicensesMax: $numberOfLicensesMax
      numberOfTrucksMin: $numberOfTrucksMin
      numberOfTrucksMax: $numberOfTrucksMax
      createdAtMin: $createdAtMin
      createdAtMax: $createdAtMax
      pagination: $pagination
    ) {
      companies: results {
        id
        isActive
        ...CompaniesPageContentMobile
        ...CompaniesPageContentDesktop
      }
      paginationMetadata {
        ...usePagination
      }
    }
  }
`;

export default CompaniesListPage;
