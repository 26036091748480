// Libraries
import {IconProp, library, SizeProp} from '@fortawesome/fontawesome-svg-core';
import {faCopy as farCopy, faTrashAlt as farTrashAlt} from '@fortawesome/free-regular-svg-icons';
import {
  faArchive,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBan,
  faBars,
  faBell,
  faBolt,
  faBox,
  faBoxes,
  faBoxOpen,
  faBullhorn,
  faCalculator,
  faCalendar,
  faCalendarCheck,
  faCalendarDay,
  faCamera,
  faCaretDown,
  faCaretUp,
  faChartLine,
  faCheckCircle,
  faCheckSquare,
  faCircle,
  faClipboardList,
  faClock,
  faCode,
  faCog,
  faCommentAlt,
  faCommentDots,
  faCompress,
  faCreditCard,
  faDollarSign,
  faDoorClosed,
  faDoorOpen,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faExpand,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileDownload,
  faFileInvoice,
  faFlagCheckered,
  faFolder,
  faGhost,
  faGraduationCap,
  faGripVertical,
  faHandHoldingUsd,
  faImage,
  faInfoCircle,
  faLightbulb,
  faListUl,
  faLock,
  faLockOpen,
  faMapMarkedAlt,
  faMapMarker,
  faMapPin,
  faMinus,
  faPen,
  faPencilAlt,
  faPhone,
  faPhoneAlt,
  faPlus,
  faPrint,
  faQuestion,
  faQuestionCircle,
  faRoad,
  faSearch,
  faSearchPlus,
  faSignOutAlt,
  faSlidersH,
  faSortAmountDownAlt,
  faSortAmountUpAlt,
  faSpinner,
  faStar,
  faStickyNote,
  faStopCircle,
  faSync,
  faTag,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faTrophy,
  faTruck,
  faUnlink,
  faUnlock,
  faUpload,
  faUser,
  faUserEdit,
  faUserPlus,
  faUserShield,
  faUsers,
  faVideo,
  faVideoSlash,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import {
  faArrowsFromLine,
  faArrowsToLine,
  faDownLeftAndUpRightToCenter,
  faStar as farStar,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faA,
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowLeftToLine,
  faArrowRightToLine,
  faArrowRotateLeft,
  faArrowRotateRight,
  faBookSparkles,
  faArrowUpRightFromSquare,
  faBold,
  faBoxArchive,
  faBriefcaseBlank,
  faBuilding,
  faBuildingCircleArrowRight,
  faCalendarCircleMinus,
  faCalendarClock,
  faCalendarExclamation,
  faCalendarXmark,
  faChartLineUp,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleCheck,
  faCircleInfo,
  faCirclePause,
  faCirclePlay,
  faCirclePlus,
  faCircleMinus,
  faCircleXmark,
  faClockRotateLeft,
  faComment,
  faCommentPen,
  faCommentQuestion,
  faComments,
  faCommentSms,
  faCopy,
  faDesktop,
  faDownload,
  faEraser,
  faFileArrowDown,
  faFileCheck,
  faFileCircleExclamation,
  faFileLines,
  faFilePlus,
  faFilter,
  faGear,
  faGrid2,
  faItalic,
  faHighlighter,
  faHourglassClock,
  faHouseBuilding,
  faHouseChimney,
  faLightbulbOn,
  faLink,
  faLinkSlash,
  faListOl,
  faMagnifyingGlass,
  faMagnifyingGlassPlus,
  faMoneyCheckDollarPen,
  faNoteSticky,
  faObjectsAlignCenterHorizontal,
  faObjectsAlignLeft,
  faObjectsAlignRight,
  faPaperclip,
  faPaperPlaneTop,
  faPenField,
  faPhoneArrowDownLeft,
  faPhoneArrowUpRight,
  faPhoneHangup,
  faPhoneXmark,
  faPlay,
  faRobot,
  faRocketLaunch,
  faRotate,
  faRulerTriangle,
  faShare,
  faSquareUser,
  faStarExclamation,
  faStrikethrough,
  faTabletScreenButton,
  faTextSlash,
  faUnderline,
  faUserCheck,
  faUserClock,
  faUserGroup,
  faWeightHanging,
  faXmark,
  faXmarkLarge,
  faSparkles,
  faPhoneSlash,
  faWaveformLines,
  faClipboardListCheck,
  faWandMagicSparkles,
} from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Platform} from '@supermove/sdk';

// Components
import Styled from '../Styled';

import FontAwesomeIcon from './FontAwesomeIcon';
import Image from './Image';

library.add(
  faA,
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArchive,
  faArrowDown,
  faArrowLeft,
  faArrowLeftToLine,
  faArrowRight,
  faArrowRightToLine,
  faArrowRotateLeft,
  faArrowRotateRight,
  faArrowsFromLine,
  faArrowsToLine,
  faArrowUp,
  faArrowUpRightFromSquare,
  faBan,
  faBars,
  faBell,
  faBold,
  faBolt,
  faBox,
  faBoxArchive,
  faBoxes,
  faBoxOpen,
  faBriefcaseBlank,
  faBuilding,
  faBuildingCircleArrowRight,
  faBullhorn,
  faCalendarCircleMinus,
  faCalculator,
  faCalendar,
  faCalendarCheck,
  faCalendarClock,
  faCalendarDay,
  faCalendarExclamation,
  faCalendarXmark,
  faCamera,
  faCaretDown,
  faCaretUp,
  faChartLine,
  faChartLineUp,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleCheck,
  faCircleInfo,
  faCirclePause,
  faCirclePlay,
  faCirclePlus,
  faCircleMinus,
  faCircleXmark,
  faClipboardList,
  faClipboardListCheck,
  faClock,
  faClockRotateLeft,
  faCode,
  faCog,
  faComment,
  faCommentAlt,
  faCommentDots,
  faCommentPen,
  faCommentQuestion,
  faComments,
  faCommentSms,
  faWaveformLines,
  faCompress,
  faCopy,
  faCreditCard,
  faDesktop,
  faDollarSign,
  faDoorClosed,
  faDoorOpen,
  faDownLeftAndUpRightToCenter,
  faDownload,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faEraser,
  faExclamationCircle,
  faExclamationTriangle,
  faExpand,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFile,
  faFileArrowDown,
  faFileAlt,
  faFileCheck,
  faFileCircleExclamation,
  faFileDownload,
  faFileInvoice,
  faFileLines,
  faFilePlus,
  faFilter,
  faFlagCheckered,
  faFolder,
  faGear,
  faGhost,
  faGraduationCap,
  faGrid2,
  faGripVertical,
  faHandHoldingUsd,
  faHighlighter,
  faHourglassClock,
  faHouseBuilding,
  faHouseChimney,
  faImage,
  faInfoCircle,
  faItalic,
  faLightbulb,
  faLightbulbOn,
  faLink,
  faLinkSlash,
  faListOl,
  faListUl,
  faLock,
  faLockOpen,
  faMagnifyingGlass,
  faMagnifyingGlassPlus,
  faMapMarkedAlt,
  faBookSparkles,
  faMapMarker,
  faMapPin,
  faMinus,
  faMoneyCheckDollarPen,
  faNoteSticky,
  faObjectsAlignCenterHorizontal,
  faObjectsAlignLeft,
  faObjectsAlignRight,
  faPaperclip,
  faPaperPlaneTop,
  faPen,
  faPencilAlt,
  faPenField,
  faPlay,
  faPhone,
  faPhoneAlt,
  faPhoneArrowDownLeft,
  faPhoneArrowUpRight,
  faPhoneHangup,
  faPhoneSlash,
  faPhoneXmark,
  faPlus,
  faPrint,
  faQuestion,
  faQuestionCircle,
  farCopy,
  faRoad,
  faRobot,
  faRocketLaunch,
  faRotate,
  farStar,
  farTrashAlt,
  faRulerTriangle,
  faSparkles,
  faSearch,
  faSearchPlus,
  faShare,
  faSignOutAlt,
  faSlidersH,
  faSortAmountDownAlt,
  faSortAmountUpAlt,
  faSpinner,
  faSquareUser,
  faStar,
  faStarExclamation,
  faStickyNote,
  faStopCircle,
  faStrikethrough,
  faSync,
  faTabletScreenButton,
  faTag,
  faTextSlash,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faTrophy,
  faTruck,
  faUnderline,
  faUnlink,
  faUnlock,
  faUpload,
  faUser,
  faUserCheck,
  faUserClock,
  faUserEdit,
  faUserGroup,
  faUserPlus,
  faUserShield,
  faUsers,
  faVideo,
  faVideoSlash,
  faWandMagicSparkles,
  faWaveformLines,
  faWeightHanging,
  faWrench,
  faXmark,
  faXmarkLarge,
);

const InternalImageComponentStyleWrapper = Styled.View`
`;

const IconSources = {
  A: 'a',
  AlignCenter: 'align-center',
  AlignJustify: 'align-justify',
  AlignLeft: 'align-left',
  AlignRight: 'align-right',
  AngleDown: 'angle-down',
  AngleLeft: 'angle-left',
  AngleRight: 'angle-right',
  AngleUp: 'angle-up',
  Archive: 'archive',
  ArrowDown: 'arrow-down',
  ArrowLeft: 'arrow-left',
  ArrowLeftToLine: 'arrow-left-to-line',
  ArrowRight: 'arrow-right',
  ArrowRightToLine: 'arrow-right-to-line',
  ArrowRotateLeft: ['fas', 'arrow-rotate-left'],
  ArrowRotateRight: ['fas', 'arrow-rotate-right'],
  ArrowsFromLine: ['far', 'arrows-from-line'],
  ArrowsToLine: ['far', 'arrows-to-line'],
  ArrowUp: 'arrow-up',
  ArrowUpRightFromSquare: 'arrow-up-right-from-square',
  Ban: 'ban',
  Bars: 'bars',
  Bell: 'bell',
  Bold: 'bold',
  Bolt: 'bolt',
  BookSparkles: 'book-sparkles',
  Box: 'box',
  BoxArchive: ['fas', 'box-archive'],
  Boxes: 'boxes',
  BoxOpen: 'box-open',
  BriefCaseBlank: 'briefcase-blank',
  Building: 'building',
  BuildingCircleArrowRight: ['fas', 'building-circle-arrow-right'],
  Bullhorn: 'bullhorn',
  Calculator: 'calculator',
  Calendar: 'calendar',
  CalendarCheck: 'calendar-check',
  CalendarCircleMinus: 'calendar-circle-minus',
  CalendarClock: 'calendar-clock',
  CalendarDay: 'calendar-day',
  CalendarExclamation: 'calendar-exclamation',
  CalendarXmark: 'calendar-xmark',
  Camera: 'camera',
  CaretDown: 'caret-down',
  CaretUp: 'caret-up',
  ChartLine: 'chart-line',
  ChartLineUp: 'chart-line-up',
  Check: 'check',
  CheckCircle: 'check-circle',
  CheckSquare: 'check-square',
  ChevronDown: 'chevron-down',
  ChevronLeft: 'chevron-left',
  ChevronRight: 'chevron-right',
  ChevronUp: 'chevron-up',
  Circle: 'circle',
  CircleCheck: 'circle-check',
  CircleInfo: 'circle-info',
  CirclePause: 'circle-pause',
  CirclePlay: 'circle-play',
  CirclePlus: 'circle-plus',
  CircleMinus: 'circle-minus',
  CircleXmark: 'circle-xmark',
  ClipboardList: 'clipboard-list',
  ClipboardListCheck: 'clipboard-list-check',
  Clock: 'clock',
  ClockRotateLeft: ['fas', 'clock-rotate-left'],
  Code: 'code',
  Cog: 'cog',
  Comment: 'comment',
  CommentAlt: 'comment-alt',
  CommentDots: 'comment-dots',
  CommentPen: ['fas', 'comment-pen'],
  CommentQuestion: 'comment-question',
  Comments: 'comments',
  CommentSms: ['fas', 'comment-sms'],
  Compress: 'compress',
  Copy: 'copy',
  CopyRegular: ['far', 'copy'],
  CreditCard: 'credit-card',
  Desktop: 'desktop',
  DollarSign: 'dollar-sign',
  DoorClosed: 'door-closed',
  DoorOpen: 'door-open',
  DownLeftAndUpRightToCenter: ['far', 'down-left-and-up-right-to-center'],
  Download: 'download',
  Edit: 'edit',
  EllipsisH: 'ellipsis-h',
  EllipsisV: 'ellipsis-v',
  Envelope: 'envelope',
  Eraser: 'eraser',
  ExclamationCircle: 'exclamation-circle',
  ExclamationTriangle: 'exclamation-triangle',
  Expand: 'expand',
  ExternalLinkAlt: 'external-link-alt',
  Eye: 'eye',
  EyeSlash: 'eye-slash',
  File: 'file',
  FileAlt: 'file-alt',
  FileArrowDown: 'file-arrow-down',
  FileCheck: ['fas', 'file-check'],
  FileCircleExclamation: 'file-circle-exclamation',
  FileDownload: 'file-download',
  FileInvoice: 'file-invoice',
  FileLines: 'file-lines',
  FilePlus: ['fas', 'file-plus'],
  Filter: 'filter',
  FlagCheckered: 'flag-checkered',
  Folder: 'folder',
  Gear: ['fas', 'gear'],
  Ghost: 'ghost',
  GraduationCap: 'graduation-cap',
  Grid2: 'grid-2',
  GripVertical: 'grip-vertical',
  HandHoldingUsd: 'hand-holding-usd',
  Highlighter: 'highlighter',
  HourglassClock: 'hourglass-clock',
  HouseBuilding: 'house-building',
  HouseChimney: 'house-chimney',
  Image: 'image',
  InfoCircle: 'info-circle',
  Italic: 'italic',
  Lightbulb: 'lightbulb',
  LightbulbOn: 'lightbulb-on',
  Link: 'link',
  LinkSlash: ['fas', 'link-slash'],
  ListUl: 'list-ul',
  ListOl: 'list-ol',
  Lock: 'lock',
  LockOpen: 'lock-open',
  MagnifyingGlass: 'magnifying-glass',
  MagnifyingGlassPlus: 'magnifying-glass-plus',
  MapMarkedAlt: 'map-marked-alt',
  MapMarker: 'map-marker',
  MapPin: 'map-pin',
  Minus: 'minus',
  MoneyCheckDollarPen: 'money-check-dollar-pen',
  NoteSticky: 'note-sticky',
  ObjectsAlignCenter: 'objects-align-center-horizontal',
  ObjectsAlignLeft: 'objects-align-left',
  ObjectsAlignRight: 'objects-align-right',
  Paperclip: ['fas', 'paperclip'],
  PaperPlaneTop: 'paper-plane-top',
  Pen: 'pen',
  Pencil: 'pencil-alt',
  PenField: 'pen-field',
  Phone: 'phone',
  PhoneAlt: 'phone-alt',
  PhoneHangup: 'phone-hangup',
  PhoneIncoming: 'phone-arrow-down-left',
  PhoneMissed: 'phone-xmark',
  PhoneOutgoing: 'phone-arrow-up-right',
  PhoneSlash: 'phone-slash',
  Play: 'play',
  Plus: 'plus',
  Print: 'print',
  Question: 'question',
  QuestionCircle: 'question-circle',
  Road: 'road',
  Robot: 'robot',
  RocketLauch: 'rocket-launch',
  Rotate: ['fas', 'rotate'],
  RulerTriangle: 'ruler-triangle',
  Search: 'search',
  SearchPlus: 'search-plus',
  Share: 'share',
  SignOut: 'sign-out-alt',
  Sliders: 'sliders-h',
  SortAmountDownAlt: 'sort-amount-down-alt',
  SortAmountUpAlt: 'sort-amount-up-alt',
  Spinner: 'spinner',
  SquareUser: 'square-user',
  Sparkles: 'sparkles',
  Star: 'star',
  StarExclamation: 'star-exclamation',
  StarOutline: ['far', 'star'],
  StickyNote: 'sticky-note',
  StopCircle: 'stop-circle',
  Strikethrough: 'strikethrough',
  Sync: 'sync',
  TabletScreenButton: 'tablet-screen-button',
  Tag: 'tag',
  TextSlash: 'text-slash',
  Times: 'times',
  TimesCircle: 'times-circle',
  Trash: 'trash',
  TrashAlt: 'trash-alt',
  TrashAltRegular: ['far', 'trash-alt'],
  Trophy: 'trophy',
  Truck: 'truck',
  Underline: 'underline',
  Unlink: 'unlink',
  Unlock: 'unlock',
  Upload: 'upload',
  User: 'user',
  UserCheck: 'user-check',
  UserClock: 'user-clock',
  UserEdit: 'user-edit',
  UserGroup: 'user-group',
  UserPlus: 'user-plus',
  UserShield: 'user-shield',
  Users: 'users',
  Video: 'video',
  VideoSlash: 'video-slash',
  WandMagicSparkles: 'wand-magic-sparkles',
  WeightHanging: 'weight-hanging',
  Wrench: 'wrench',
  Xmark: ['fas', 'xmark'],
  XmarkLarge: ['fas', 'xmark-large'],
  WaveformLines: 'waveform-lines',
  GoogleMaps: 'GoogleMaps',
  StreetView: 'StreetView',
  Zillow: 'Zillow',
} as const;

// This gets all the values in IconSources
export type IconSource = (typeof IconSources)[keyof typeof IconSources];

const IconSizes = {
  ExtraSmall: 10,
  Small: 12,
  Medium: 14,
  Large: 18,
  ExtraLarge: 25,
};

export interface IconProps {
  color?: string | null;
  size?: number;
  source: IconSource;
  style?: object;
}

type IconComponent = React.FC<IconProps> &
  typeof IconSources & {Sizes: typeof IconSizes} & {
    SourcePropType: ReturnType<typeof PropTypes.oneOfType>;
  };

const Icon: IconComponent = (({color = null, size = 16, source, style = {}}: IconProps) => {
  const InternalImageComponent = Image[source as keyof typeof Image];
  if (InternalImageComponent) {
    if (style) {
      return (
        <InternalImageComponentStyleWrapper style={style}>
          <InternalImageComponent color={color} size={size} />
        </InternalImageComponentStyleWrapper>
      );
    }
    return <InternalImageComponent color={color} size={size} />;
  }
  return (
    <FontAwesomeIcon
      color={color as string | undefined} // Doesn't like null type, but that doesn't actually cause a problem
      icon={source as IconProp}
      size={Platform.select({
        web: '1x',
        // The font library type expects a string, but we're using a number and it seems to work fine
        default: size as unknown as SizeProp,
      })}
      style={Platform.select({
        web: {
          ...style,
          fontSize: size,
        },
        default: style,
      })}
      // The fa-fw class is used to set a fixed width on all icons for a given
      // fontSize, which is set by our size prop.
      className={'fa-fw'}
    />
  );
}) as IconComponent;

Object.assign(Icon, IconSources);
Icon.Sizes = IconSizes;
// TODO (jay) remove this when we remove all uses of Icon.SourcePropType
Icon.SourcePropType = PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]);

export default Icon;
