// Libraries
import React from 'react';

// Supermove
import {Icon, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useQuery, useResponsive, useDrawer} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import WarningCallout from '@shared/design/components/Callout/WarningCallout';
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import FieldInput from '@shared/design/components/Field/FieldInput';
import FieldValue from '@shared/design/components/Field/FieldValue';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import useUpdateCompanyIdentifierMutation from '@shared/modules/Company/hooks/useUpdateCompanyIdentifierMutation';
import CompanyDetailsPage from 'modules/Company/CompanyDetails/CompanyDetailsPage';

const DrawerHeaderText = Styled.Text`
  ${Typography.Responsive.Subheading}
`;

const DrawerSubHeaderText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const ActionPanelBody = ({current}: {current: number}) => {
  return (
    <React.Fragment>
      <FieldValue isResponsive label={'Current Project Identifier'} value={`${current}`} />
      <Space height={16} />
    </React.Fragment>
  );
};

interface UpdateIdentifierDrawerProps {
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
  companyIdentifierId: number;
  current: number;
}

const UpdateIdentifierDrawer = ({
  isOpen,
  handleClose,
  refetch,
  companyIdentifierId,
  current,
}: UpdateIdentifierDrawerProps) => {
  const responsive = useResponsive();
  const {form, submitting, handleSubmit} = useUpdateCompanyIdentifierMutation({
    updateCompanyIdentifierForm: {
      companyIdentifierId,
      value: null,
    },
    onSuccess: () => {
      form.setFieldValue('updateCompanyIdentifierForm.value', null);
      handleClose();
      refetch();
    },
    onError: (errors: unknown) => console.error({errors}),
  });

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={'Update Project Identifier'}
      isDisabled={!form.values.updateCompanyIdentifierForm.value}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
    >
      <WarningCallout text={'The next identifier cannot be below the current identifier.'} />
      <Space height={16} />
      <DrawerHeaderText responsive={responsive}>Project Identifier</DrawerHeaderText>
      <Space height={4} />
      <DrawerSubHeaderText responsive={responsive}>
        Update the numeric identifier assigned to the next project for this company. This value
        increments by 1 each time a project is created.
      </DrawerSubHeaderText>
      <Space height={16} />
      <FieldInput
        label={'Current Project Identifier'}
        isResponsive
        input={{
          disabled: true,
          value: current,
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        name={`updateCompanyIdentifierForm.value`}
        label={'Next Project Identifier'}
        isResponsive
        isRequired
        input={{
          placeholder: 'Enter updated number of licenses',
          setFieldValue: form.setFieldValue,
        }}
      />
    </DrawerWithAction>
  );
};

const CompanyModulesProjectsPage = () => {
  const {params} = useNavigation();

  const updateProjectIdentifierDrawer = useDrawer({name: 'Update Project Identifier Drawer'});
  const {loading, data, refetch} = useQuery(CompanyModulesProjectsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {slug: params.slug},
  });

  return (
    <CompanyDetailsPage
      selectedLabel={'modules/projects'}
      pageTitle={'Projects'}
      pageDescription={
        'Configure project settings. These changes will apply to the company and, if applicable, its branches.'
      }
    >
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => (
          <React.Fragment>
            <ActionPanel
              title={'Identifiers'}
              BodyComponent={ActionPanelBody}
              bodyComponentProps={{
                current: data.company.projectIdentifier.current,
              }}
              ActionButtonComponent={TertiaryButton}
              actionButtonComponentProps={{
                iconLeft: Icon.Pen,
                text: 'Edit',
                onPress: updateProjectIdentifierDrawer.handleOpen,
              }}
            />
            <UpdateIdentifierDrawer
              isOpen={updateProjectIdentifierDrawer.isOpen}
              handleClose={updateProjectIdentifierDrawer.handleClose}
              refetch={refetch}
              companyIdentifierId={data.company.projectIdentifier.id}
              current={data.company.projectIdentifier.current}
            />
          </React.Fragment>
        )}
      </Loading>
    </CompanyDetailsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompanyModulesProjectsPage.query = gql`
  query CompanyModulesProjectsPage($slug: String!) {
    ${gql.query}
    viewer {
      id
    }
    company(slug: $slug) {
      id
      projectIdentifier {
        id
        current
      }
    }
  }
`;

export default CompanyModulesProjectsPage;
