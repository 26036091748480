// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useModal, useResponsive, useState} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import AdminAppPage from 'modules/App/components/AdminAppPage';
import MobileSearch from 'modules/App/components/MobileSearch';
import SearchBar from 'modules/App/components/SearchBar';
import CreateCompanyModal from 'modules/Company/components/CreateCompanyModal';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const HeaderContainer = Styled.View`
`;

const CompaniesListPageHeader = ({
  searchQuery = '',
  handleSearch,
}: {
  searchQuery?: string;
  handleSearch?: (query: string) => void;
}) => {
  const responsive = useResponsive();

  const createCompanyModal = useModal({name: 'Create Company Modal', enableTracking: true});
  const [isMobileSearchVisible, setIsMobileSearchVisible] = useState<boolean>(false);
  const toggleMobileSearch = () => setIsMobileSearchVisible((currentValue) => !currentValue);

  return (
    <React.Fragment>
      <HeaderContainer>
        <AdminAppPage.HeaderContainer
          responsive={responsive}
          style={{borderBottomWidth: responsive.desktop ? 1 : 0}}
        >
          <AdminAppPage.PageHeadingText responsive={responsive}>
            Companies
          </AdminAppPage.PageHeadingText>
          <Space style={{flex: 1}} />
          <Row>
            {handleSearch &&
              (responsive.desktop ? (
                <SearchBar
                  placeholder='Search by company name or identifier'
                  searchQuery={searchQuery}
                  setSearchQuery={handleSearch}
                />
              ) : (
                <TertiaryButton onPress={toggleMobileSearch}>
                  <Icon source={Icon.MagnifyingGlass} size={16} color={colors.gray.secondary} />
                </TertiaryButton>
              ))}
            <Row>
              <Space width={16} />
              <Button
                text={responsive.desktop ? 'Create Company' : 'Create'}
                iconLeft={Icon.Plus}
                onPress={createCompanyModal.handleOpen}
              />
            </Row>
          </Row>
        </AdminAppPage.HeaderContainer>
        {handleSearch && (
          <MobileSearch
            searchQuery={searchQuery}
            setSearchQuery={handleSearch}
            isVisible={isMobileSearchVisible}
            handleClose={() => setIsMobileSearchVisible(false)}
          />
        )}
      </HeaderContainer>
      <CreateCompanyModal
        key={createCompanyModal.key}
        isOpen={createCompanyModal.isOpen}
        handleClose={createCompanyModal.handleClose}
      />
    </React.Fragment>
  );
};

export default CompaniesListPageHeader;
