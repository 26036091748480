// Libraries
import _ from 'lodash';
// @ts-expect-error library is not typed
import {geocodeByAddress, getLatLng} from 'react-places-autocomplete';

import Retry from './Retry';

interface GeocodeType {
  // These types come from an outside library
  /* eslint-disable camelcase */
  address_components: {
    long_name: string;
    short_name: string;
    types: string[];
  }[];
}

const Location = {
  // We memoize this function to prevent re-rendering a component that has
  // the same latitude and longitude.
  create: _.memoize(
    ({latitude, longitude}: {latitude?: number; longitude?: number}) => {
      if (!latitude || !longitude) {
        return null;
      }
      // Returns the format that Google Maps expects.
      return {lat: latitude, lng: longitude};
    },
    // Specifies a key to use for the memoize cache.
    ({latitude, longitude}) => `${latitude}-${longitude}`,
  ),

  createGoogleMapsUrl: (address: string = '') => {
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
  },

  createGoogleMapsRouteUrl: ({
    origin,
    destination,
    waypoints = [],
  }: {
    origin: string;
    destination: string;
    waypoints: string[];
  }) => {
    const encodedOrigin = encodeURIComponent(origin);
    const encodedDestination = encodeURIComponent(destination);
    // If waypoints are empty, no need to include the parameter
    const waypointsParam = _.isEmpty(waypoints)
      ? ''
      : `&waypoints=${waypoints.map((waypoint) => encodeURIComponent(waypoint)).join('|')}`;

    return `https://www.google.com/maps/dir/?api=1&origin=${encodedOrigin}&destination=${encodedDestination}${waypointsParam}`;
  },

  // Getters
  getGeocode: async (address: string) => {
    const results = await Retry.withTimeout<GeocodeType[]>(() => geocodeByAddress(address), 2000);
    return results[0];
  },
  getAddresses: (geocode: GeocodeType, addressType: string) => {
    return _.filter(geocode.address_components, (address) => {
      return _.some(address.types, (type) => type === addressType);
    });
  },
  getFirstAddressLongName: (geocode: GeocodeType, addressType: string) => {
    const addresses = Location.getAddresses(geocode, addressType);
    return addresses.length === 0 ? '' : _.first(addresses)?.long_name;
  },
  getCity: (geocode: GeocodeType) => {
    // NOTE(mark): There are some addresses that do NOT have a 'locality'. For those,
    // we fallback to the 'neighborhood' and then 'political'.
    return (
      Location.getFirstAddressLongName(geocode, 'locality') ||
      Location.getFirstAddressLongName(geocode, 'neighborhood') ||
      Location.getFirstAddressLongName(geocode, 'political')
    );
  },
  getLatitudeLongitude: async (geocode: GeocodeType) => {
    const location = await Retry.withTimeout<{lat: number; lng: number}>(
      () => getLatLng(geocode),
      2000,
    );
    return {
      latitude: location.lat,
      longitude: location.lng,
    };
  },
  getZipCode: (geocode: GeocodeType) => {
    return Location.getFirstAddressLongName(geocode, 'postal_code');
  },
  getState: (geocode: GeocodeType) => {
    const state = Location.getAddresses(geocode, 'administrative_area_level_1');
    return state.length === 0 ? '' : _.first(state)?.short_name;
  },
  getCountry: (geocode: GeocodeType) => {
    const country = Location.getAddresses(geocode, 'country');
    return country.length === 0 ? '' : _.first(country)?.short_name;
  },
};

export default Location;
