// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import Modal from '@shared/design/components/Modal';
import Sheet from '@shared/design/components/Sheet';

const Container = Styled.View`
`;

const MobilePrimaryActionContainer = Styled.View`
  padding: 16px;
`;

const LargeModal = ({
  title = '',
  isOpen,
  handlePressOutside = () => {},
  handlePrimaryAction,
  handleSecondaryAction,
  handleDestructiveAction,
  primaryActionText = 'Save',
  primaryActionIcon = Icon.Check,
  secondaryActionText = '',
  destructiveActionText = 'Delete',
  isSubmitting = false,
  isDestroying,
  isDisabled = false,
  isScrollable = false,
  style = {},
  HeaderComponent = null,
  FooterComponent,
  children,
  isResponsive = false,
  bodyStyle = {},
  screenContainerStyle,
  isMobileSheet = false,
  mobileContainerStyle,
}: {
  title?: string;
  isOpen: boolean;
  handlePressOutside?: () => void;
  handlePrimaryAction?: () => void;
  handleSecondaryAction?: () => void;
  handleDestructiveAction?: () => void;
  primaryActionText?: string;
  primaryActionIcon?: string;
  secondaryActionText?: string;
  destructiveActionText?: string;
  isSubmitting?: boolean;
  isDestroying?: boolean;
  isDisabled?: boolean;
  isScrollable?: boolean;
  style?: object;
  HeaderComponent?: React.FC | null;
  FooterComponent?: React.FC;
  children: React.ReactNode;
  isResponsive?: boolean;
  bodyStyle?: object;
  screenContainerStyle?: object;
  isMobileSheet?: boolean;
  mobileContainerStyle?: object;
}) => {
  const responsive = useResponsive();

  if (isMobileSheet && !responsive.desktop) {
    return (
      <Sheet
        isOpen={isOpen}
        handleClose={handleSecondaryAction}
        headerText={title}
        height={'90%'}
        shouldCloseOnClickOutside
      >
        <Container style={{flex: 1, padding: 16, ...mobileContainerStyle}}>{children}</Container>
        <MobilePrimaryActionContainer>
          {!!handlePrimaryAction && (
            <Button
              text={primaryActionText}
              onPress={() => setTimeout(handlePrimaryAction, 0)}
              isWidthOfContainer
              isSubmitting={isSubmitting}
              isDisabled={isDisabled}
              iconLeft={primaryActionIcon || Icon.Check}
              isResponsive
            />
          )}
        </MobilePrimaryActionContainer>
      </Sheet>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      handlePressOutside={handlePressOutside}
      width={Modal.WIDTH.LARGE}
      style={style}
      screenContainerStyle={screenContainerStyle}
      isResponsive={isResponsive}
    >
      {(HeaderComponent && <HeaderComponent />) || (
        <Modal.Header isResponsive={isResponsive}>
          <Modal.HeaderText responsive={isResponsive ? responsive : null}>{title}</Modal.HeaderText>
        </Modal.Header>
      )}
      <Modal.Body style={bodyStyle} isScrollable={isScrollable}>
        {children}
      </Modal.Body>
      {!!handlePrimaryAction && (
        <Modal.Footer isResponsive={isResponsive}>
          {handleDestructiveAction && (
            <SecondaryButton
              isSubmitting={isDestroying}
              isDisabled={isDisabled}
              onPress={handleDestructiveAction}
              isResponsive={isResponsive}
              text={destructiveActionText}
              iconLeft={Icon.Trash}
              textColor={colors.red.warning}
            />
          )}
          <Space style={{flex: 1}} />
          {handleSecondaryAction && (
            <React.Fragment>
              <QuaternaryButton
                isDisabled={isDisabled}
                onPress={handleSecondaryAction}
                isResponsive={isResponsive}
                text={secondaryActionText}
                textColor={colors.blue.interactive}
              />
              <Space width={32} />
            </React.Fragment>
          )}
          <Modal.Button
            isSubmitting={isSubmitting}
            isDisabled={isDisabled}
            onPress={handlePrimaryAction}
            color={colors.blue.interactive}
            isWidthOfContainer={isResponsive && !handleSecondaryAction && !responsive.desktop}
            isResponsive={isResponsive}
            iconLeft={primaryActionIcon || Icon.Check}
          >
            {primaryActionText}
          </Modal.Button>
        </Modal.Footer>
      )}
      {FooterComponent && <FooterComponent />}
    </Modal>
  );
};

LargeModal.Header = Modal.Header;
LargeModal.HeaderText = Modal.HeaderText;
LargeModal.BoldText = Modal.BoldText;
LargeModal.Text = Modal.Text;
LargeModal.Button = Modal.Button;
LargeModal.ButtonText = Modal.ButtonText;
LargeModal.Block = Modal.Block;
LargeModal.BlockHeader = Modal.BlockHeader;
LargeModal.PreventPropagationContainer = Modal.PreventPropagationContainer;

export default LargeModal;
