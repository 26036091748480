// Libraries
import _ from 'lodash';

// Supermove
import {generated, gql} from '@supermove/graphql';
import {DashboardModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

const isVariablesChanged = withFragment(
  (
    dashboard: generated.Dashboard_IsVariablesChangedFragment,
    newVariables: Record<string, any> | undefined,
  ) => {
    return !_.isEqual(JSON.parse(dashboard.exploVariables ?? '{}'), newVariables ?? {});
  },
  gql`
    fragment Dashboard_isVariablesChanged on Dashboard {
      id
      exploVariables
    }
  `,
);

const isDashboardFiltersDifferentFromGlobalDashboard = withFragment(
  (dashboard: DashboardModel) => {
    if (!dashboard.globalDashboard) {
      return isVariablesChanged(dashboard, undefined);
    }
    return isVariablesChanged(dashboard, JSON.parse(dashboard.globalDashboard.exploVariables));
  },
  gql`
    ${isVariablesChanged.fragment}
    fragment Dashboard_isDashboardFiltersDifferentFromGlobalDashboard on Dashboard {
      id
      dashboardCategoryId
      name
      description
      exploVariables
      globalDashboard {
        dashboardCategoryId
        name
        description
        exploVariables
      }
      ...Dashboard_isVariablesChanged
    }
  `,
);

const formatJson = (value: string) => {
  try {
    const parsed = JSON.parse(value);
    if (typeof parsed !== 'object' || Object.keys(parsed).length === 0) {
      return value;
    }
    return JSON.stringify(parsed, null, 2);
  } catch (error) {
    return value;
  }
};

const Dashboard = {
  isVariablesChanged,
  isDashboardFiltersDifferentFromGlobalDashboard,
  formatJson,
};

export default Dashboard;
