// Libraries
import _ from 'lodash';

// App
import VariableKind from '@shared/modules/Billing/enums/VariableKind';

const BILL_ADDITIONAL_HOURS = 'BILL_ADDITIONAL_HOURS';
const BILL_TO_CLIENT_COMPANY_NAME = 'BILL_TO_CLIENT_COMPANY_NAME';
const BILL_TO_CLIENT_CONTACT_FIRST_NAME = 'BILL_TO_CLIENT_CONTACT_FIRST_NAME';
const BILL_TO_CLIENT_CONTACT_LAST_NAME = 'BILL_TO_CLIENT_CONTACT_LAST_NAME';
const BILL_TO_CLIENT_CONTACT_PHONE_NUMBER = 'BILL_TO_CLIENT_CONTACT_PHONE_NUMBER';
const BILL_TO_CLIENT_CONTACT_EMAIL = 'BILL_TO_CLIENT_CONTACT_EMAIL';
const BILL_TO_CLIENT_CONTACT_ADDRESS = 'BILL_TO_CLIENT_CONTACT_ADDRESS';
const BILL_TO_CLIENT_BILLING_ADDRESS = 'BILL_TO_CLIENT_BILLING_ADDRESS';
const BILL_TOTAL = 'BILL_TOTAL';
const CLIENT_COMPANY_NAME = 'CLIENT_COMPANY_NAME';
const COMPANY_ADDRESS = 'COMPANY_ADDRESS';
const COMPANY_PHONE_NUMBER = 'COMPANY_PHONE_NUMBER';
const COMPANY_WEBSITE = 'COMPANY_WEBSITE';
const COMPANY_NAME = 'COMPANY_NAME';
const CONFIRMATION_CREATED_DATE = 'CONFIRMATION_CREATED_DATE';
const CONFIRMATION_CREATED_TIME = 'CONFIRMATION_CREATED_TIME';
const CONFIRMATION_LINK = 'CONFIRMATION_LINK';
const CONFIRMATION_STEPS = 'CONFIRMATION_STEPS';
const CONFIRMATION_URL = 'CONFIRMATION_URL';
const CONTACT_EMAIL = 'CONTACT_EMAIL';
const CONTACT_FIRST_NAME = 'CONTACT_FIRST_NAME';
const CONTACT_FULL_NAME = 'CONTACT_FULL_NAME';
const CONTACT_LAST_NAME = 'CONTACT_LAST_NAME';
const CONTACT_PHONE_NUMBER = 'CONTACT_PHONE_NUMBER';
const COORDINATOR_EMAIL = 'COORDINATOR_EMAIL';
const COORDINATOR_NAME = 'COORDINATOR_NAME';
const COORDINATOR_PHONE_NUMBER = 'COORDINATOR_PHONE_NUMBER';
const CUSTOMER_NAME = 'CUSTOMER_NAME';
const CUSTOMER_EMAIL = 'CUSTOMER_EMAIL';
const CUSTOMER_PHONE_NUMBER = 'CUSTOMER_PHONE_NUMBER';
const DATES_DDDD_MMMM_D = 'DATES_DDDD_MMMM_D';
const DATES_DDDD_MMMM_DO = 'DATES_DDDD_MMMM_DO';
const DATETIMES_DDDD_MMMM_D = 'DATETIMES_DDDD_MMMM_D';
const DATETIMES_DDDD_MMMM_DO = 'DATETIMES_DDDD_MMMM_DO';
const DAY_DDDD_MMMM_D = 'DAY_DDDD_MMMM_D';
const DAY_DDDD_MMMM_DO = 'DAY_DDDD_MMMM_DO';
const DAY_MM_DD_YY = 'DAY_MM_DD_YY';
const INVENTORY_WEIGHT = 'INVENTORY_WEIGHT';
const JOB_ARRIVAL_WINDOW = 'JOB_ARRIVAL_WINDOW';
const JOB_CREW_NOTES = 'JOB_CREW_NOTES';
const JOB_DATES_WITH_ARRIVAL_WINDOWS = 'JOB_DATES_WITH_ARRIVAL_WINDOWS';
const JOB_DESCRIPTION = 'JOB_DESCRIPTION';
const JOB_DESTINATION_LOCATION_ADDRESS = 'JOB_DESTINATION_LOCATION_ADDRESS';
const JOB_DISPATCH_NOTES = 'JOB_DISPATCH_NOTES';
const JOB_DISPLAY_DATE = 'JOB_DISPLAY_DATE';
const JOB_DRIVER_NAMES = 'JOB_DRIVER_NAMES';
const JOB_DROP_OFF_ADDRESSES = 'JOB_DROP_OFF_ADDRESSES';
const JOB_ESTIMATED_HOURS = 'JOB_ESTIMATED_HOURS';
const JOB_HOUR_MINIMUM = 'JOB_HOUR_MINIMUM';
const JOB_NAME = 'JOB_NAME';
const JOB_OFFICE_NOTES = 'JOB_OFFICE_NOTES';
const JOB_ORIGIN_DESTINATION_MINUTES = 'JOB_ORIGIN_DESTINATION_MINUTES';
const JOB_ORIGIN_LOCATION_ADDRESS = 'JOB_ORIGIN_LOCATION_ADDRESS';
const JOB_IDENTIFIER = 'JOB_IDENTIFIER';
const JOB_LOCATIONS_DETAILS = 'JOB_LOCATIONS_DETAILS';
const JOB_LOCATIONS_DISPLAY = 'JOB_LOCATIONS_DISPLAY';
const JOB_LOCATIONS_SUMMARY = 'JOB_LOCATIONS_SUMMARY';
const JOB_MAX_ESTIMATE_HOURS = 'JOB_MAX_ESTIMATE_HOURS';
const JOB_MILEAGE = 'JOB_MILEAGE';
const JOB_MIN_ESTIMATE_HOURS = 'JOB_MIN_ESTIMATE_HOURS';
const JOB_MOVER_NAMES = 'JOB_MOVER_NAMES';
const JOB_NUMBER_OF_MOVERS = 'JOB_NUMBER_OF_MOVERS';
const JOB_NUMBER_OF_TRUCKS = 'JOB_NUMBER_OF_TRUCKS';
const JOB_PICK_UP_ADDRESSES = 'JOB_PICK_UP_ADDRESSES';
const JOB_STARTED_BY_FULL_NAME = 'JOB_STARTED_BY_FULL_NAME';
const JOB_STOPS_AND_NOTES = 'JOB_STOPS_AND_NOTES';
const JOB_TRUCK_NAMES = 'JOB_TRUCK_NAMES';
const JOB_TYPE_NAME = 'JOB_TYPE_NAME';
const MOVE_ARRIVAL_WINDOW = 'MOVE_ARRIVAL_WINDOW';
const MOVE_CREW_MEMBERS = 'MOVE_CREW_MEMBERS';
const MOVE_CREW_SIZE = 'MOVE_CREW_SIZE';
const MOVE_DATE = 'MOVE_DATE';
const MOVE_DATE_DDMMYYYY = 'MOVE_DATE_DDMMYYYY';
const MOVE_DESTINATION_ADDRESS = 'MOVE_DESTINATION_ADDRESS';
const MOVE_DESTINATION_CITY = 'MOVE_DESTINATION_CITY';
const MOVE_DESTINATION_RAW_ADDRESS = 'MOVE_DESTINATION_RAW_ADDRESS';
const MOVE_DESTINATION_STATE = 'MOVE_DESTINATION_STATE';
const MOVE_NOT_TO_EXCEED_PRICE = 'MOVE_NOT_TO_EXCEED_PRICE';
const MOVE_NOT_TO_EXCEED_SERVICES = 'MOVE_NOT_TO_EXCEED_SERVICES';
const MOVE_ORIGIN_ADDRESS = 'MOVE_ORIGIN_ADDRESS';
const MOVE_ORIGIN_CITY = 'MOVE_ORIGIN_CITY';
const MOVE_ORIGIN_DESTINATION_ADDRESSES = 'MOVE_ORIGIN_DESTINATION_ADDRESSES';
const MOVE_ORIGIN_STATE = 'MOVE_ORIGIN_STATE';
const MOVE_PARTY_NOTIFIED = 'MOVE_PARTY_NOTIFIED';
const MOVE_PAYMENT_METHODS = 'MOVE_PAYMENT_METHODS';
const MOVE_PICKUP_DELIVERY_DATES = 'MOVE_PICKUP_DELIVERY_DATES';
const MOVE_SHIPMENT_DESCRIPTION = 'MOVE_SHIPMENT_DESCRIPTION';
const MOVE_START_TIME = 'MOVE_START_TIME';
const MOVE_VALUATION_COVERAGE = 'MOVE_VALUATION_COVERAGE';
const MOVE_VALUATION_MINIMUM = 'MOVE_VALUATION_MINIMUM';
const MOVE_VALUATION_VALUE = 'MOVE_VALUATION_VALUE';
const ORGANIZATION_NAME = 'ORGANIZATION_NAME';
const ORGANIZATION_BUSINESS_ADDRESS = 'ORGANIZATION_BUSINESS_ADDRESS';
const ORGANIZATION_BUSINESS_NAME = 'ORGANIZATION_BUSINESS_NAME';
const ORGANIZATION_BUSINESS_PHONE_NUMBER = 'ORGANIZATION_BUSINESS_PHONE_NUMBER';
const ORGANIZATION_PHONE_NUMBER = 'ORGANIZATION_PHONE_NUMBER';
const ORGANIZATION_EMAIL = 'ORGANIZATION_EMAIL';
const PROJECT_BALANCE = 'PROJECT_BALANCE';
const PROJECT_CREDIT_AMOUNT = 'PROJECT_CREDIT_AMOUNT';
const PROJECT_DELIVER_DATE = 'PROJECT_DELIVER_DATE';
const PROJECT_DESCRIPTION = 'PROJECT_DESCRIPTION';
const PROJECT_END_DATE = 'PROJECT_END_DATE';
const PROJECT_ESTIMATE_DATE = 'PROJECT_ESTIMATE_DATE';
const PROJECT_ESTIMATE_ARRIVAL_WINDOW = 'PROJECT_ESTIMATE_ARRIVAL_WINDOW';
const PROJECT_JOB_CREW_NOTES = 'PROJECT_JOB_CREW_NOTES';
const PROJECT_JOB_DATES = 'PROJECT_JOB_DATES';
const PROJECT_JOB_DESCRIPTIONS = 'PROJECT_JOB_DESCRIPTIONS';
const PROJECT_JOB_INFORMATION = 'PROJECT_JOB_INFORMATION';
const PROJECT_IDENTIFIER = 'PROJECT_IDENTIFIER';
const PROJECT_LINK = 'PROJECT_LINK';
const PROJECT_LOAD_DATE = 'PROJECT_LOAD_DATE';
const PROJECT_OFFICE_NOTES = 'PROJECT_OFFICE_NOTES';
const PROJECT_MILEAGE = 'PROJECT_MILEAGE';
const PROJECT_NAME = 'PROJECT_NAME';
const PROJECT_ORIGIN_DESTINATION_MINUTES = 'PROJECT_ORIGIN_DESTINATION_MINUTES';
const PROJECT_PACK_DATE = 'PROJECT_PACK_DATE';
const PROJECT_REFERRAL_DETAILS = 'PROJECT_REFERRAL_DETAILS';
const PROJECT_REFERRAL_SOURCE = 'PROJECT_REFERRAL_SOURCE';
const PROJECT_SIZE = 'PROJECT_SIZE';
const PROJECT_START_DATE = 'PROJECT_START_DATE';
const PROJECT_TOTAL_COST = 'PROJECT_TOTAL_COST';
const PROJECT_TOTAL_REVENUE = 'PROJECT_TOTAL_REVENUE';
const PROJECT_TOTAL_PROFIT = 'PROJECT_TOTAL_PROFIT';
const PROJECT_TYPE_NAME = 'PROJECT_TYPE_NAME';
const PROJECT_URL = 'PROJECT_URL';
const PROJECT_WAREHOUSE_NOTES = 'PROJECT_WAREHOUSE_NOTES';
const PROJECT_WEIGHT = 'PROJECT_WEIGHT';
const QUOTE_LINK = 'QUOTE_LINK';
const QUOTE_URL = 'QUOTE_URL';
const SALESPERSON_EMAIL = 'SALESPERSON_EMAIL';
const SALESPERSON_FULL_NAME = 'SALESPERSON_FULL_NAME';
const SALESPERSON_NAME = 'SALESPERSON_NAME';
const SALESPERSON_PHONE_NUMBER = 'SALESPERSON_PHONE_NUMBER';

// Invoice Variables
const INVOICE_IDENTIFIER = 'INVOICE_IDENTIFIER';
const INVOICE_DATE = 'INVOICE_DATE';
const INVOICE_DUE_DATE = 'INVOICE_DUE_DATE';
const INVOICE_LINK = 'INVOICE_LINK';
const INVOICE_PAYMENT_TERMS = 'INVOICE_PAYMENT_TERMS';
const INVOICE_RECEIPT_LINK = 'INVOICE_RECEIPT_LINK';
const INVOICE_RECEIPT_URL = 'INVOICE_RECEIPT_URL';
const INVOICE_URL = 'INVOICE_URL';

// User Flow Variables
const DOCUMENT_FLOW_URL = 'DOCUMENT_FLOW_URL';

// DEPRECATED
const URL_CONFIRMATION = 'URL_CONFIRMATION';
const URL_INVOICE = 'URL_INVOICE';
const URL_INVOICE_RECEIPT = 'URL_INVOICE_RECEIPT';
const URL_PROJECT = 'URL_PROJECT';
const URL_QUOTE = 'URL_QUOTE';

const ALL_VARIABLES = {
  BILL_ADDITIONAL_HOURS: {
    value: BILL_ADDITIONAL_HOURS,
    kind: VariableKind.JOB,
    identifier: `{${BILL_ADDITIONAL_HOURS}}`,
    displayDescription: 'Number of hours included via bill rules',
  },
  BILL_TO_CLIENT_COMPANY_NAME: {
    value: BILL_TO_CLIENT_COMPANY_NAME,
    kind: VariableKind.PROJECT,
    identifier: `{${BILL_TO_CLIENT_COMPANY_NAME}}`,
    displayDescription: `Bill To client company name`,
  },
  BILL_TO_CLIENT_CONTACT_FIRST_NAME: {
    value: BILL_TO_CLIENT_CONTACT_FIRST_NAME,
    kind: VariableKind.PROJECT,
    identifier: `{${BILL_TO_CLIENT_CONTACT_FIRST_NAME}}`,
    displayDescription: `Bill To client contact first name`,
  },
  BILL_TO_CLIENT_CONTACT_LAST_NAME: {
    value: BILL_TO_CLIENT_CONTACT_LAST_NAME,
    kind: VariableKind.PROJECT,
    identifier: `{${BILL_TO_CLIENT_CONTACT_LAST_NAME}}`,
    displayDescription: `Bill To client contact last name`,
  },
  BILL_TO_CLIENT_CONTACT_PHONE_NUMBER: {
    value: BILL_TO_CLIENT_CONTACT_PHONE_NUMBER,
    kind: VariableKind.PROJECT,
    identifier: `{${BILL_TO_CLIENT_CONTACT_PHONE_NUMBER}}`,
    displayDescription: `Bill To client phone number`,
  },
  BILL_TO_CLIENT_CONTACT_EMAIL: {
    value: BILL_TO_CLIENT_CONTACT_EMAIL,
    kind: VariableKind.PROJECT,
    identifier: `{${BILL_TO_CLIENT_CONTACT_EMAIL}}`,
    displayDescription: `Bill To client email address`,
  },
  BILL_TO_CLIENT_CONTACT_ADDRESS: {
    value: BILL_TO_CLIENT_CONTACT_ADDRESS,
    kind: VariableKind.PROJECT,
    identifier: `{${BILL_TO_CLIENT_CONTACT_ADDRESS}}`,
    displayDescription: `Bill To client client address`,
  },
  BILL_TO_CLIENT_BILLING_ADDRESS: {
    value: BILL_TO_CLIENT_BILLING_ADDRESS,
    kind: VariableKind.PROJECT,
    identifier: `{${BILL_TO_CLIENT_BILLING_ADDRESS}}`,
    displayDescription: `Bill To client billing address`,
  },
  BILL_TOTAL: {
    value: BILL_TOTAL,
    kind: VariableKind.PROJECT,
    identifier: `{${BILL_TOTAL}}`,
    displayDescription: 'Grand total of the bill',
  },
  CLIENT_COMPANY_NAME: {
    value: CLIENT_COMPANY_NAME,
    kind: VariableKind.PROJECT,
    identifier: `{${CLIENT_COMPANY_NAME}}`,
    displayDescription: 'Client company name',
  },
  COMPANY_ADDRESS: {
    value: COMPANY_ADDRESS,
    kind: VariableKind.PROJECT,
    identifier: `{${COMPANY_ADDRESS}}`,
    displayDescription: `Company's address`,
  },
  COMPANY_NAME: {
    value: COMPANY_NAME,
    kind: VariableKind.PROJECT,
    identifier: `{${COMPANY_NAME}}`,
    displayDescription: 'Business name',
  },
  COMPANY_PHONE_NUMBER: {
    value: COMPANY_PHONE_NUMBER,
    kind: VariableKind.PROJECT,
    identifier: `{${COMPANY_PHONE_NUMBER}}`,
    displayDescription: `Company's phone number`,
  },
  COMPANY_WEBSITE: {
    value: COMPANY_WEBSITE,
    kind: VariableKind.PROJECT,
    identifier: `{${COMPANY_WEBSITE}}`,
    displayDescription: `Company's website link`,
  },
  CONFIRMATION_CREATED_DATE: {
    value: CONFIRMATION_CREATED_DATE,
    kind: VariableKind.PROJECT,
    identifier: `{${CONFIRMATION_CREATED_DATE}}`,
    displayDescription: 'Date the confirmation was created at',
  },
  CONFIRMATION_CREATED_TIME: {
    value: CONFIRMATION_CREATED_TIME,
    kind: VariableKind.PROJECT,
    identifier: `{${CONFIRMATION_CREATED_TIME}}`,
    displayDescription: 'Time the confirmation was created at',
  },
  CONFIRMATION_LINK: {
    value: CONFIRMATION_LINK,
    kind: VariableKind.PROJECT,
    identifier: `{${CONFIRMATION_LINK}}`,
    displayDescription: 'Online confirmation link',
  },
  CONFIRMATION_STEPS: {
    value: CONFIRMATION_STEPS,
    kind: VariableKind.PROJECT,
    identifier: `{${CONFIRMATION_STEPS}}`,
    displayDescription: 'List of confirmation process steps',
  },
  CONFIRMATION_URL: {
    value: CONFIRMATION_URL,
    kind: VariableKind.PROJECT,
    identifier: `{${CONFIRMATION_URL}}`,
    displayDescription: 'Online confirmation URL',
  },
  CONTACT_EMAIL: {
    value: CONTACT_EMAIL,
    kind: VariableKind.PROJECT,
    identifier: `{${CONTACT_EMAIL}}`,
    displayDescription: `Contact's email`,
  },
  CONTACT_FIRST_NAME: {
    value: CONTACT_FIRST_NAME,
    kind: VariableKind.PROJECT,
    identifier: `{${CONTACT_FIRST_NAME}}`,
    displayDescription: `Contact's first name`,
  },
  CONTACT_FULL_NAME: {
    value: CONTACT_FULL_NAME,
    kind: VariableKind.PROJECT,
    identifier: `{${CONTACT_FULL_NAME}}`,
    displayDescription: `Contact's full name`,
  },
  CONTACT_LAST_NAME: {
    value: CONTACT_LAST_NAME,
    kind: VariableKind.PROJECT,
    identifier: `{${CONTACT_LAST_NAME}}`,
    displayDescription: `Contact's last name`,
  },
  CONTACT_PHONE_NUMBER: {
    value: CONTACT_PHONE_NUMBER,
    kind: VariableKind.PROJECT,
    identifier: `{${CONTACT_PHONE_NUMBER}}`,
    displayDescription: `Contact's phone number`,
  },
  COORDINATOR_EMAIL: {
    value: COORDINATOR_EMAIL,
    kind: VariableKind.PROJECT,
    identifier: `{${COORDINATOR_EMAIL}}`,
    displayDescription: `Coordinator's email`,
  },
  COORDINATOR_NAME: {
    value: COORDINATOR_NAME,
    kind: VariableKind.PROJECT,
    identifier: `{${COORDINATOR_NAME}}`,
    displayDescription: `Coordinator's name`,
  },
  COORDINATOR_PHONE_NUMBER: {
    value: COORDINATOR_PHONE_NUMBER,
    kind: VariableKind.PROJECT,
    identifier: `{${COORDINATOR_PHONE_NUMBER}}`,
    displayDescription: `Coordinator's phone number`,
  },
  CUSTOMER_NAME: {
    value: CUSTOMER_NAME,
    kind: VariableKind.JOB,
    identifier: `{${CUSTOMER_NAME}}`,
    displayDescription: "Customer's full name",
  },
  CUSTOMER_EMAIL: {
    value: CUSTOMER_EMAIL,
    kind: VariableKind.JOB,
    identifier: `{${CUSTOMER_EMAIL}}`,
    displayDescription: "Customer's email",
  },
  CUSTOMER_PHONE_NUMBER: {
    value: CUSTOMER_PHONE_NUMBER,
    kind: VariableKind.JOB,
    identifier: `{${CUSTOMER_PHONE_NUMBER}}`,
    displayDescription: "Customer's phone number",
  },
  DATES_DDDD_MMMM_D: {
    value: DATES_DDDD_MMMM_D,
    kind: VariableKind.JOB,
    identifier: `{${DATES_DDDD_MMMM_D}}`,
    displayDescription: 'Multi-job dates with format: Monday, January 1',
  },
  DATES_DDDD_MMMM_DO: {
    value: DATES_DDDD_MMMM_DO,
    kind: VariableKind.JOB,
    identifier: `{${DATES_DDDD_MMMM_DO}}`,
    displayDescription: 'Multi-job dates with format: Monday, January 1st',
  },
  DATETIMES_DDDD_MMMM_D: {
    value: DATETIMES_DDDD_MMMM_D,
    kind: VariableKind.PROJECT,
    identifier: `{${DATETIMES_DDDD_MMMM_D}}`,
    displayDescription:
      'Multi-job dates with format: Monday, January 1: 09:00 AM - 09:30 AM arrival window',
  },
  DATETIMES_DDDD_MMMM_DO: {
    value: DATETIMES_DDDD_MMMM_DO,
    kind: VariableKind.PROJECT,
    identifier: `{${DATETIMES_DDDD_MMMM_DO}}`,
    displayDescription:
      'Multi-job dates with format: Monday, January 1st: 09:00 AM - 09:30 AM arrival window',
  },
  DAY_DDDD_MMMM_D: {
    value: DAY_DDDD_MMMM_D,
    kind: VariableKind.JOB,
    identifier: `{${DAY_DDDD_MMMM_D}}`,
    displayDescription: 'Job start date with format: Monday, January 1',
  },
  DAY_DDDD_MMMM_DO: {
    value: DAY_DDDD_MMMM_DO,
    kind: VariableKind.JOB,
    identifier: `{${DAY_DDDD_MMMM_DO}}`,
    displayDescription: 'Job start date with format: Monday, January 1st',
  },
  DAY_MM_DD_YY: {
    value: DAY_MM_DD_YY,
    kind: VariableKind.JOB,
    identifier: `{${DAY_MM_DD_YY}}`,
    displayDescription: 'Job start date with format: 01/01/2020',
  },
  INVENTORY_WEIGHT: {
    value: INVENTORY_WEIGHT,
    kind: VariableKind.PROJECT,
    identifier: `{${INVENTORY_WEIGHT}}`,
    displayDescription: 'Inventory weight from the survey',
  },
  JOB_ARRIVAL_WINDOW: {
    value: JOB_ARRIVAL_WINDOW,
    kind: VariableKind.JOB,
    identifier: `{${JOB_ARRIVAL_WINDOW}}`,
    displayDescription: 'Job arrival window',
  },
  JOB_CREW_NOTES: {
    value: JOB_CREW_NOTES,
    kind: VariableKind.JOB,
    identifier: `{${JOB_CREW_NOTES}}`,
    displayDescription: 'Notes for the crew',
  },
  JOB_DATES_WITH_ARRIVAL_WINDOWS: {
    value: JOB_DATES_WITH_ARRIVAL_WINDOWS,
    kind: VariableKind.JOB,
    identifier: `{${JOB_DATES_WITH_ARRIVAL_WINDOWS}}`,
    displayDescription: `Multi-job names, dates, and arrival windows`,
  },
  JOB_DESCRIPTION: {
    value: JOB_DESCRIPTION,
    kind: VariableKind.JOB,
    identifier: `{${JOB_DESCRIPTION}}`,
    displayDescription: 'Job description',
  },
  JOB_DESTINATION_LOCATION_ADDRESS: {
    value: JOB_DESTINATION_LOCATION_ADDRESS,
    kind: VariableKind.JOB,
    identifier: `{${JOB_DESTINATION_LOCATION_ADDRESS}}`,
    displayDescription: `Job's destination location address`,
  },
  JOB_DISPATCH_NOTES: {
    value: JOB_DISPATCH_NOTES,
    kind: VariableKind.JOB,
    identifier: `{${JOB_DISPATCH_NOTES}}`,
    displayDescription: 'Notes for the dispatch team',
  },
  JOB_DISPLAY_DATE: {
    value: JOB_DISPLAY_DATE,
    kind: VariableKind.JOB,
    identifier: `{${JOB_DISPLAY_DATE}}`,
    displayDescription: 'Job Information Display Date',
  },
  JOB_DRIVER_NAMES: {
    value: JOB_DRIVER_NAMES,
    kind: VariableKind.JOB,
    identifier: `{${JOB_DRIVER_NAMES}}`,
    displayDescription: 'Driver names assigned to the job',
  },
  JOB_DROP_OFF_ADDRESSES: {
    value: JOB_DROP_OFF_ADDRESSES,
    kind: VariableKind.JOB,
    identifier: `{${JOB_DROP_OFF_ADDRESSES}}`,
    displayDescription: 'List of drop off addresses of the job',
  },
  JOB_ESTIMATED_HOURS: {
    value: JOB_ESTIMATED_HOURS,
    kind: VariableKind.JOB,
    identifier: `{${JOB_ESTIMATED_HOURS}}`,
    displayDescription: 'Job estimated hours',
  },
  JOB_HOUR_MINIMUM: {
    value: JOB_HOUR_MINIMUM,
    kind: VariableKind.JOB,
    identifier: `{${JOB_HOUR_MINIMUM}}`,
    displayDescription: 'Job hour minimum',
  },
  JOB_NAME: {
    value: JOB_NAME,
    kind: VariableKind.JOB,
    identifier: `{${JOB_NAME}}`,
    displayDescription: 'Job custom name',
  },
  JOB_OFFICE_NOTES: {
    value: JOB_OFFICE_NOTES,
    kind: VariableKind.JOB,
    identifier: `{${JOB_OFFICE_NOTES}}`,
    displayDescription: 'Office notes on the job',
  },
  JOB_ORIGIN_DESTINATION_MINUTES: {
    value: JOB_ORIGIN_DESTINATION_MINUTES,
    kind: VariableKind.JOB,
    identifier: `{${JOB_ORIGIN_DESTINATION_MINUTES}}`,
    displayDescription: 'Sum of all ETAs for job between origin and destination',
  },
  JOB_ORIGIN_LOCATION_ADDRESS: {
    value: JOB_ORIGIN_LOCATION_ADDRESS,
    kind: VariableKind.JOB,
    identifier: `{${JOB_ORIGIN_LOCATION_ADDRESS}}`,
    displayDescription: `Job's origin location address`,
  },
  JOB_IDENTIFIER: {
    value: JOB_IDENTIFIER,
    kind: VariableKind.JOB,
    identifier: `{${JOB_IDENTIFIER}}`,
    displayDescription: 'Job unique identifier',
  },
  JOB_LOCATIONS_DETAILS: {
    value: JOB_LOCATIONS_DETAILS,
    kind: VariableKind.JOB,
    identifier: `{${JOB_LOCATIONS_DETAILS}}`,
    displayDescription: 'List of detailed job locations',
  },
  JOB_LOCATIONS_DISPLAY: {
    value: JOB_LOCATIONS_DISPLAY,
    kind: VariableKind.JOB,
    identifier: `{${JOB_LOCATIONS_DISPLAY}}`,
    displayDescription: 'List of job locations',
  },
  JOB_LOCATIONS_SUMMARY: {
    value: JOB_LOCATIONS_SUMMARY,
    kind: VariableKind.JOB,
    identifier: `{${JOB_LOCATIONS_SUMMARY}}`,
    displayDescription: 'List of job locations',
  },
  JOB_MAX_ESTIMATE_HOURS: {
    value: JOB_MAX_ESTIMATE_HOURS,
    kind: VariableKind.JOB,
    identifier: `{${JOB_MAX_ESTIMATE_HOURS}}`,
    displayDescription: 'Job max estimate hours',
  },
  JOB_MILEAGE: {
    value: JOB_MILEAGE,
    kind: VariableKind.JOB,
    identifier: `{${JOB_MILEAGE}}`,
    displayDescription: 'Total distance in miles for the job',
  },
  JOB_MIN_ESTIMATE_HOURS: {
    value: JOB_MIN_ESTIMATE_HOURS,
    kind: VariableKind.JOB,
    identifier: `{${JOB_MIN_ESTIMATE_HOURS}}`,
    displayDescription: 'Job min estimate hours',
  },
  JOB_MOVER_NAMES: {
    value: JOB_MOVER_NAMES,
    kind: VariableKind.JOB,
    identifier: `{${JOB_MOVER_NAMES}}`,
    displayDescription: 'List of movers assigned to this job',
  },
  JOB_NUMBER_OF_MOVERS: {
    value: JOB_NUMBER_OF_MOVERS,
    kind: VariableKind.JOB,
    identifier: `{${JOB_NUMBER_OF_MOVERS}}`,
    displayDescription: 'Job number of movers',
  },
  JOB_NUMBER_OF_TRUCKS: {
    value: JOB_NUMBER_OF_TRUCKS,
    kind: VariableKind.JOB,
    identifier: `{${JOB_NUMBER_OF_TRUCKS}}`,
    displayDescription: 'Job number of trucks',
  },
  JOB_PICK_UP_ADDRESSES: {
    value: JOB_PICK_UP_ADDRESSES,
    kind: VariableKind.JOB,
    identifier: `{${JOB_PICK_UP_ADDRESSES}}`,
    displayDescription: 'List of pick up addresses of the job',
  },
  JOB_STARTED_BY_FULL_NAME: {
    value: JOB_STARTED_BY_FULL_NAME,
    kind: VariableKind.JOB,
    identifier: `{${JOB_STARTED_BY_FULL_NAME}}`,
    displayDescription: 'Mover that started the job on the tablet',
  },
  JOB_STOPS_AND_NOTES: {
    value: JOB_STOPS_AND_NOTES,
    kind: VariableKind.JOB,
    identifier: `{${JOB_STOPS_AND_NOTES}}`,
    displayDescription: 'List of stops and notes for the job',
  },
  JOB_TRUCK_NAMES: {
    value: JOB_TRUCK_NAMES,
    kind: VariableKind.JOB,
    identifier: `{${JOB_TRUCK_NAMES}}`,
    displayDescription: 'Shows a list of trucks: Truck #1, Truck #4',
  },
  JOB_TYPE_NAME: {
    value: JOB_TYPE_NAME,
    kind: VariableKind.JOB,
    identifier: `{${JOB_TYPE_NAME}}`,
    displayDescription: 'Name of the job type',
  },
  MOVE_ARRIVAL_WINDOW: {
    value: MOVE_ARRIVAL_WINDOW,
    kind: VariableKind.JOB,
    identifier: `{${MOVE_ARRIVAL_WINDOW}}`,
    displayDescription: 'Job start time window',
  },
  MOVE_CREW_MEMBERS: {
    value: MOVE_CREW_MEMBERS,
    kind: VariableKind.JOB,
    identifier: `{${MOVE_CREW_MEMBERS}}`,
    displayDescription: 'List of movers assigned to this job',
  },
  MOVE_CREW_SIZE: {
    value: MOVE_CREW_SIZE,
    kind: VariableKind.JOB,
    identifier: `{${MOVE_CREW_SIZE}}`,
    displayDescription: 'Number of movers needed for the job',
  },
  MOVE_DATE: {
    value: MOVE_DATE,
    kind: VariableKind.JOB,
    identifier: `{${MOVE_DATE}}`,
    displayDescription: 'Date this job is scheduled for',
  },
  MOVE_DATE_DDMMYYYY: {
    value: MOVE_DATE_DDMMYYYY,
    kind: VariableKind.JOB,
    identifier: `{${MOVE_DATE_DDMMYYYY}}`,
    displayDescription: 'Date this job is scheduled for',
  },
  MOVE_DESTINATION_ADDRESS: {
    value: MOVE_DESTINATION_ADDRESS,
    kind: VariableKind.JOB,
    identifier: `{${MOVE_DESTINATION_ADDRESS}}`,
    displayDescription: 'Destination address for this job',
  },
  MOVE_DESTINATION_RAW_ADDRESS: {
    value: MOVE_DESTINATION_RAW_ADDRESS,
    kind: VariableKind.JOB,
    identifier: `{${MOVE_DESTINATION_RAW_ADDRESS}}`,
    displayDescription: 'Destination address',
  },
  MOVE_DESTINATION_STATE: {
    value: MOVE_DESTINATION_STATE,
    kind: VariableKind.JOB,
    identifier: `{${MOVE_DESTINATION_STATE}}`,
    displayDescription: 'Destination state',
  },
  MOVE_DESTINATION_CITY: {
    value: MOVE_DESTINATION_CITY,
    kind: VariableKind.JOB,
    identifier: `{${MOVE_DESTINATION_CITY}}`,
    displayDescription: 'Destination city for this job',
  },
  MOVE_NOT_TO_EXCEED_PRICE: {
    value: MOVE_NOT_TO_EXCEED_PRICE,
    kind: VariableKind.JOB,
    identifier: `{${MOVE_NOT_TO_EXCEED_PRICE}}`,
    displayDescription: 'Job not to exceed price',
  },
  MOVE_NOT_TO_EXCEED_SERVICES: {
    value: MOVE_NOT_TO_EXCEED_SERVICES,
    kind: VariableKind.JOB,
    identifier: `{${MOVE_NOT_TO_EXCEED_SERVICES}}`,
    displayDescription: 'Job not to exceed list of services',
  },
  MOVE_ORIGIN_ADDRESS: {
    value: MOVE_ORIGIN_ADDRESS,
    kind: VariableKind.JOB,
    identifier: `{${MOVE_ORIGIN_ADDRESS}}`,
    displayDescription: 'Origin address for this job',
  },
  MOVE_ORIGIN_CITY: {
    value: MOVE_ORIGIN_CITY,
    kind: VariableKind.JOB,
    identifier: `{${MOVE_ORIGIN_CITY}}`,
    displayDescription: 'Origin city',
  },
  MOVE_ORIGIN_DESTINATION_ADDRESSES: {
    value: MOVE_ORIGIN_DESTINATION_ADDRESSES,
    kind: VariableKind.PROJECT,
    identifier: `{${MOVE_ORIGIN_DESTINATION_ADDRESSES}}`,
    displayDescription: 'Origin and destination addresses',
  },
  MOVE_ORIGIN_STATE: {
    value: MOVE_ORIGIN_STATE,
    kind: VariableKind.JOB,
    identifier: `{${MOVE_ORIGIN_STATE}}`,
    displayDescription: 'Origin state',
  },
  MOVE_PARTY_NOTIFIED: {
    value: MOVE_PARTY_NOTIFIED,
    kind: VariableKind.JOB,
    identifier: `{${MOVE_PARTY_NOTIFIED}}`,
    displayDescription: 'Primary contact information',
  },
  MOVE_PAYMENT_METHODS: {
    value: MOVE_PAYMENT_METHODS,
    kind: VariableKind.JOB,
    identifier: `{${MOVE_PAYMENT_METHODS}}`,
    displayDescription: 'List of payment methods',
  },
  MOVE_PICKUP_DELIVERY_DATES: {
    value: MOVE_PICKUP_DELIVERY_DATES,
    kind: VariableKind.PROJECT,
    identifier: `{${MOVE_PICKUP_DELIVERY_DATES}}`,
    displayDescription: 'Job pickup and delivery dates',
  },
  MOVE_SHIPMENT_DESCRIPTION: {
    value: MOVE_SHIPMENT_DESCRIPTION,
    kind: VariableKind.JOB,
    identifier: `{${MOVE_SHIPMENT_DESCRIPTION}}`,
    displayDescription: "List of shipper's goods",
  },
  MOVE_START_TIME: {
    value: MOVE_START_TIME,
    kind: VariableKind.PROJECT,
    identifier: `{${MOVE_START_TIME}}`,
    displayDescription: 'Initial start time of the job',
  },
  MOVE_VALUATION_COVERAGE: {
    value: MOVE_VALUATION_COVERAGE,
    kind: VariableKind.JOB,
    identifier: `{${MOVE_VALUATION_COVERAGE}}`,
    displayDescription: 'Category of selected valuation coverage',
  },
  MOVE_VALUATION_MINIMUM: {
    value: MOVE_VALUATION_MINIMUM,
    kind: VariableKind.JOB,
    identifier: `{${MOVE_VALUATION_MINIMUM}}`,
    displayDescription: 'Minimum valuation coverage you can purchase',
  },
  MOVE_VALUATION_VALUE: {
    value: MOVE_VALUATION_VALUE,
    kind: VariableKind.JOB,
    identifier: `{${MOVE_VALUATION_VALUE}}`,
    displayDescription: 'Current valuation coverage you have selected',
  },
  ORGANIZATION_NAME: {
    value: ORGANIZATION_NAME,
    kind: VariableKind.JOB,
    identifier: `{${ORGANIZATION_NAME}}`,
    displayDescription: 'Organization name',
  },
  ORGANIZATION_BUSINESS_ADDRESS: {
    value: ORGANIZATION_BUSINESS_ADDRESS,
    kind: VariableKind.JOB,
    identifier: `{${ORGANIZATION_BUSINESS_ADDRESS}}`,
    displayDescription: 'Organization address',
  },
  ORGANIZATION_BUSINESS_NAME: {
    value: ORGANIZATION_BUSINESS_NAME,
    kind: VariableKind.JOB,
    identifier: `{${ORGANIZATION_BUSINESS_NAME}}`,
    displayDescription: 'Organization business name',
  },
  ORGANIZATION_BUSINESS_PHONE_NUMBER: {
    value: ORGANIZATION_BUSINESS_PHONE_NUMBER,
    kind: VariableKind.JOB,
    identifier: `{${ORGANIZATION_BUSINESS_PHONE_NUMBER}}`,
    displayDescription: 'Organization business phone number',
  },
  ORGANIZATION_PHONE_NUMBER: {
    value: ORGANIZATION_PHONE_NUMBER,
    kind: VariableKind.JOB,
    identifier: `{${ORGANIZATION_PHONE_NUMBER}}`,
    displayDescription: 'Organization phone number',
  },
  ORGANIZATION_EMAIL: {
    value: ORGANIZATION_EMAIL,
    kind: VariableKind.JOB,
    identifier: `{${ORGANIZATION_EMAIL}}`,
    displayDescription: 'Organization email',
  },
  PROJECT_BALANCE: {
    value: PROJECT_BALANCE,
    kind: VariableKind.PROJECT,
    identifier: `{${PROJECT_BALANCE}}`,
    displayDescription: 'Project balance',
  },
  PROJECT_CREDIT_AMOUNT: {
    value: PROJECT_CREDIT_AMOUNT,
    kind: VariableKind.PROJECT,
    identifier: `{${PROJECT_CREDIT_AMOUNT}}`,
    displayDescription: 'Project credit amount',
  },
  PROJECT_JOB_CREW_NOTES: {
    value: PROJECT_JOB_CREW_NOTES,
    kind: VariableKind.PROJECT,
    identifier: `{${PROJECT_JOB_CREW_NOTES}}`,
    displayDescription: 'List of job crew notes for a project',
  },
  PROJECT_DELIVER_DATE: {
    value: PROJECT_DELIVER_DATE,
    kind: VariableKind.PROJECT,
    identifier: `{${PROJECT_DELIVER_DATE}}`,
    displayDescription: 'Date or range of the deliver date on the project',
  },
  PROJECT_DESCRIPTION: {
    value: PROJECT_DESCRIPTION,
    kind: VariableKind.PROJECT,
    identifier: `{${PROJECT_DESCRIPTION}}`,
    displayDescription: 'Description from the project page',
  },
  PROJECT_END_DATE: {
    value: PROJECT_END_DATE,
    kind: VariableKind.PROJECT,
    identifier: `{${PROJECT_END_DATE}}`,
    displayDescription: 'Project end date',
  },
  PROJECT_ESTIMATE_DATE: {
    value: PROJECT_ESTIMATE_DATE,
    kind: VariableKind.PROJECT,
    identifier: `{${PROJECT_ESTIMATE_DATE}}`,
    displayDescription: 'Project estimate date with format: 01/01/2020',
  },
  PROJECT_ESTIMATE_ARRIVAL_WINDOW: {
    value: PROJECT_ESTIMATE_ARRIVAL_WINDOW,
    kind: VariableKind.PROJECT,
    identifier: `{${PROJECT_ESTIMATE_ARRIVAL_WINDOW}}`,
    displayDescription: 'Project estimate job arrival window',
  },
  PROJECT_LOAD_DATE: {
    value: PROJECT_LOAD_DATE,
    kind: VariableKind.PROJECT,
    identifier: `{${PROJECT_LOAD_DATE}}`,
    displayDescription: 'Date or range of the load date on the project',
  },
  PROJECT_OFFICE_NOTES: {
    value: PROJECT_OFFICE_NOTES,
    kind: VariableKind.PROJECT,
    identifier: `{${PROJECT_OFFICE_NOTES}}`,
    displayDescription: 'Office notes on a project',
  },
  PROJECT_JOB_DATES: {
    value: PROJECT_JOB_DATES,
    kind: VariableKind.PROJECT,
    identifier: `{${PROJECT_JOB_DATES}}`,
    displayDescription: 'List of job dates on a project',
  },
  PROJECT_JOB_DESCRIPTIONS: {
    value: PROJECT_JOB_DESCRIPTIONS,
    kind: VariableKind.PROJECT,
    identifier: `{${PROJECT_JOB_DESCRIPTIONS}}`,
    displayDescription: 'List of job descriptions on a project',
  },
  PROJECT_JOB_INFORMATION: {
    value: PROJECT_JOB_INFORMATION,
    kind: VariableKind.PROJECT,
    identifier: `{${PROJECT_JOB_INFORMATION}}`,
    displayDescription: 'List of job information on a project',
  },
  PROJECT_IDENTIFIER: {
    value: PROJECT_IDENTIFIER,
    kind: VariableKind.PROJECT,
    identifier: `{${PROJECT_IDENTIFIER}}`,
    displayDescription: 'Identifier from the project page',
  },
  PROJECT_LINK: {
    value: PROJECT_LINK,
    kind: VariableKind.PROJECT,
    identifier: `{${PROJECT_LINK}}`,
    displayDescription: 'Project link',
  },
  PROJECT_MILEAGE: {
    value: PROJECT_MILEAGE,
    kind: VariableKind.PROJECT,
    identifier: `{${PROJECT_MILEAGE}}`,
    displayDescription: 'Total distance in miles for the project',
  },
  PROJECT_NAME: {
    value: PROJECT_NAME,
    kind: VariableKind.PROJECT,
    identifier: `{${PROJECT_NAME}}`,
    displayDescription: 'Name from the project page',
  },
  PROJECT_ORIGIN_DESTINATION_MINUTES: {
    value: PROJECT_ORIGIN_DESTINATION_MINUTES,
    kind: VariableKind.PROJECT,
    identifier: `{${PROJECT_ORIGIN_DESTINATION_MINUTES}}`,
    displayDescription: 'Sum of all ETAs for all jobs on a project',
  },
  PROJECT_PACK_DATE: {
    value: PROJECT_PACK_DATE,
    kind: VariableKind.PROJECT,
    identifier: `{${PROJECT_PACK_DATE}}`,
    displayDescription: 'Date or range of the pack date on the project',
  },
  PROJECT_REFERRAL_DETAILS: {
    value: PROJECT_REFERRAL_DETAILS,
    kind: VariableKind.PROJECT,
    identifier: `{${PROJECT_REFERRAL_DETAILS}}`,
    displayDescription: 'Referral details from the project',
  },
  PROJECT_REFERRAL_SOURCE: {
    value: PROJECT_REFERRAL_SOURCE,
    kind: VariableKind.PROJECT,
    identifier: `{${PROJECT_REFERRAL_SOURCE}}`,
    displayDescription: 'Referral source from this project',
  },
  PROJECT_SIZE: {
    value: PROJECT_SIZE,
    kind: VariableKind.PROJECT,
    identifier: `{${PROJECT_SIZE}}`,
    displayDescription: 'Project size',
  },
  PROJECT_START_DATE: {
    value: PROJECT_START_DATE,
    kind: VariableKind.PROJECT,
    identifier: `{${PROJECT_START_DATE}}`,
    displayDescription: 'Project start date',
  },
  PROJECT_TOTAL_COST: {
    value: PROJECT_TOTAL_COST,
    kind: VariableKind.PROJECT,
    identifier: `{${PROJECT_TOTAL_COST}}`,
    displayDescription: 'Total cost of the entire project',
  },
  PROJECT_TOTAL_PROFIT: {
    value: PROJECT_TOTAL_PROFIT,
    kind: VariableKind.PROJECT,
    identifier: `{${PROJECT_TOTAL_PROFIT}}`,
    displayDescription: 'Total profit of the entire project',
  },
  PROJECT_TOTAL_REVENUE: {
    value: PROJECT_TOTAL_REVENUE,
    kind: VariableKind.PROJECT,
    identifier: `{${PROJECT_TOTAL_REVENUE}}`,
    displayDescription: 'Total revenue of the entire project',
  },
  PROJECT_TYPE_NAME: {
    value: PROJECT_TYPE_NAME,
    kind: VariableKind.PROJECT,
    identifier: `{${PROJECT_TYPE_NAME}}`,
    displayDescription: 'Name of the project type',
  },
  PROJECT_URL: {
    value: PROJECT_URL,
    kind: VariableKind.PROJECT,
    identifier: `{${PROJECT_URL}}`,
    displayDescription: 'Project URL',
  },
  PROJECT_WAREHOUSE_NOTES: {
    value: PROJECT_WAREHOUSE_NOTES,
    kind: VariableKind.PROJECT,
    identifier: `{${PROJECT_WAREHOUSE_NOTES}}`,
    displayDescription: 'Warehouse notes on a project',
  },
  PROJECT_WEIGHT: {
    value: PROJECT_WEIGHT,
    kind: VariableKind.PROJECT,
    identifier: `{${PROJECT_WEIGHT}}`,
    displayDescription: 'Most up-to-date weight value for a project',
  },
  QUOTE_URL: {
    value: QUOTE_URL,
    kind: VariableKind.PROJECT,
    identifier: `{${QUOTE_URL}}`,
    displayDescription: 'Quote URL',
  },
  QUOTE_LINK: {
    value: QUOTE_LINK,
    kind: VariableKind.PROJECT,
    identifier: `{${QUOTE_LINK}}`,
    displayDescription: 'Quote link',
  },
  SALESPERSON_EMAIL: {
    value: SALESPERSON_EMAIL,
    kind: VariableKind.PROJECT,
    identifier: `{${SALESPERSON_EMAIL}}`,
    displayDescription: "Salesperson's email",
  },
  SALESPERSON_FULL_NAME: {
    value: SALESPERSON_FULL_NAME,
    kind: VariableKind.JOB,
    identifier: `{${SALESPERSON_FULL_NAME}}`,
    displayDescription: "Salesperson's name",
  },
  SALESPERSON_NAME: {
    value: SALESPERSON_NAME,
    kind: VariableKind.PROJECT,
    identifier: `{${SALESPERSON_NAME}}`,
    displayDescription: "Salesperson's name",
  },
  SALESPERSON_PHONE_NUMBER: {
    value: SALESPERSON_PHONE_NUMBER,
    kind: VariableKind.PROJECT,
    identifier: `{${SALESPERSON_PHONE_NUMBER}}`,
    displayDescription: "Salesperson's phone number",
  },
  URL_CONFIRMATION: {
    value: URL_CONFIRMATION,
    kind: VariableKind.PROJECT,
    identifier: `{${URL_CONFIRMATION}}`,
    displayDescription: 'Online confirmation URL',
  },
  URL_INVOICE: {
    value: URL_INVOICE,
    kind: VariableKind.INVOICE,
    identifier: `{${URL_INVOICE}}`,
    displayDescription: 'Invoice URL',
  },
  URL_INVOICE_RECEIPT: {
    value: URL_INVOICE_RECEIPT,
    kind: VariableKind.INVOICE,
    identifier: `{${URL_INVOICE_RECEIPT}}`,
    displayDescription: 'Invoice Receipt URL',
  },
  URL_PROJECT: {
    value: URL_PROJECT,
    kind: VariableKind.PROJECT,
    identifier: `{${URL_PROJECT}}`,
    displayDescription: 'Project URL',
  },
  URL_QUOTE: {
    value: URL_QUOTE,
    kind: VariableKind.PROJECT,
    identifier: `{${URL_QUOTE}}`,
    displayDescription: 'Online quote URL',
  },
  INVOICE_IDENTIFIER: {
    value: INVOICE_IDENTIFIER,
    kind: VariableKind.INVOICE,
    identifier: `{${INVOICE_IDENTIFIER}}`,
    displayDescription: 'Invoice identifier',
  },
  INVOICE_DATE: {
    value: INVOICE_DATE,
    kind: VariableKind.INVOICE,
    identifier: `{${INVOICE_DATE}}`,
    displayDescription: 'Invoice date',
  },
  INVOICE_DUE_DATE: {
    value: INVOICE_DUE_DATE,
    kind: VariableKind.INVOICE,
    identifier: `{${INVOICE_DUE_DATE}}`,
    displayDescription: 'Invoice due date',
  },
  INVOICE_LINK: {
    value: INVOICE_LINK,
    kind: VariableKind.INVOICE,
    identifier: `{${INVOICE_LINK}}`,
    displayDescription: 'Invoice link',
  },
  INVOICE_PAYMENT_TERMS: {
    value: INVOICE_PAYMENT_TERMS,
    kind: VariableKind.INVOICE,
    identifier: `{${INVOICE_PAYMENT_TERMS}}`,
    displayDescription: 'Invoice payment term',
  },
  INVOICE_RECEIPT_LINK: {
    value: INVOICE_RECEIPT_LINK,
    kind: VariableKind.INVOICE,
    identifier: `{${INVOICE_RECEIPT_LINK}}`,
    displayDescription: 'Invoice receipt link',
  },
  INVOICE_RECEIPT_URL: {
    value: INVOICE_RECEIPT_URL,
    kind: VariableKind.INVOICE,
    identifier: `{${INVOICE_RECEIPT_URL}}`,
    displayDescription: 'Invoice receipt URL',
  },
  INVOICE_URL: {
    value: INVOICE_URL,
    kind: VariableKind.INVOICE,
    identifier: `{${INVOICE_URL}}`,
    displayDescription: 'Invoice URL',
  },
  DOCUMENT_FLOW_URL: {
    value: DOCUMENT_FLOW_URL,
    kind: VariableKind.PROJECT,
    identifier: `{${DOCUMENT_FLOW_URL}}`,
    displayDescription: 'Document flow URL',
  },
};

const SHARED_VARIABLES = [
  ALL_VARIABLES.BILL_TO_CLIENT_COMPANY_NAME,
  ALL_VARIABLES.BILL_TO_CLIENT_CONTACT_FIRST_NAME,
  ALL_VARIABLES.BILL_TO_CLIENT_CONTACT_LAST_NAME,
  ALL_VARIABLES.BILL_TO_CLIENT_CONTACT_PHONE_NUMBER,
  ALL_VARIABLES.BILL_TO_CLIENT_CONTACT_EMAIL,
  ALL_VARIABLES.BILL_TO_CLIENT_CONTACT_ADDRESS,
  ALL_VARIABLES.BILL_TO_CLIENT_BILLING_ADDRESS,
  ALL_VARIABLES.CLIENT_COMPANY_NAME,
  ALL_VARIABLES.COMPANY_ADDRESS,
  ALL_VARIABLES.COMPANY_NAME,
  ALL_VARIABLES.COMPANY_PHONE_NUMBER,
  ALL_VARIABLES.COMPANY_WEBSITE,
  ALL_VARIABLES.CONTACT_EMAIL,
  ALL_VARIABLES.CONTACT_FIRST_NAME,
  ALL_VARIABLES.CONTACT_FULL_NAME,
  ALL_VARIABLES.CONTACT_LAST_NAME,
  ALL_VARIABLES.CONTACT_PHONE_NUMBER,
  ALL_VARIABLES.COORDINATOR_EMAIL,
  ALL_VARIABLES.COORDINATOR_NAME,
  ALL_VARIABLES.COORDINATOR_PHONE_NUMBER,
  ALL_VARIABLES.JOB_ARRIVAL_WINDOW,
  ALL_VARIABLES.JOB_DESCRIPTION,
  ALL_VARIABLES.JOB_DESTINATION_LOCATION_ADDRESS,
  ALL_VARIABLES.JOB_DISPLAY_DATE,
  ALL_VARIABLES.JOB_DRIVER_NAMES,
  ALL_VARIABLES.JOB_DROP_OFF_ADDRESSES,
  ALL_VARIABLES.JOB_IDENTIFIER,
  ALL_VARIABLES.JOB_LOCATIONS_DISPLAY,
  ALL_VARIABLES.JOB_MAX_ESTIMATE_HOURS,
  ALL_VARIABLES.JOB_MIN_ESTIMATE_HOURS,
  ALL_VARIABLES.JOB_NAME,
  ALL_VARIABLES.JOB_NUMBER_OF_MOVERS,
  ALL_VARIABLES.JOB_NUMBER_OF_TRUCKS,
  ALL_VARIABLES.JOB_PICK_UP_ADDRESSES,
  ALL_VARIABLES.JOB_OFFICE_NOTES,
  ALL_VARIABLES.JOB_ORIGIN_DESTINATION_MINUTES,
  ALL_VARIABLES.JOB_ORIGIN_LOCATION_ADDRESS,
  ALL_VARIABLES.JOB_STOPS_AND_NOTES,
  ALL_VARIABLES.JOB_TYPE_NAME,
  ALL_VARIABLES.ORGANIZATION_NAME,
  ALL_VARIABLES.ORGANIZATION_EMAIL,
  ALL_VARIABLES.ORGANIZATION_BUSINESS_ADDRESS,
  ALL_VARIABLES.ORGANIZATION_BUSINESS_NAME,
  ALL_VARIABLES.ORGANIZATION_BUSINESS_PHONE_NUMBER,
  ALL_VARIABLES.ORGANIZATION_PHONE_NUMBER,
  ALL_VARIABLES.PROJECT_BALANCE,
  ALL_VARIABLES.PROJECT_CREDIT_AMOUNT,
  ALL_VARIABLES.PROJECT_DELIVER_DATE,
  ALL_VARIABLES.PROJECT_DESCRIPTION,
  ALL_VARIABLES.PROJECT_END_DATE,
  ALL_VARIABLES.PROJECT_ESTIMATE_DATE,
  ALL_VARIABLES.PROJECT_ESTIMATE_ARRIVAL_WINDOW,
  ALL_VARIABLES.PROJECT_JOB_CREW_NOTES,
  ALL_VARIABLES.PROJECT_JOB_DATES,
  ALL_VARIABLES.PROJECT_JOB_DESCRIPTIONS,
  ALL_VARIABLES.PROJECT_JOB_INFORMATION,
  ALL_VARIABLES.PROJECT_IDENTIFIER,
  ALL_VARIABLES.PROJECT_LOAD_DATE,
  ALL_VARIABLES.PROJECT_OFFICE_NOTES,
  ALL_VARIABLES.PROJECT_MILEAGE,
  ALL_VARIABLES.PROJECT_NAME,
  ALL_VARIABLES.PROJECT_ORIGIN_DESTINATION_MINUTES,
  ALL_VARIABLES.PROJECT_PACK_DATE,
  ALL_VARIABLES.PROJECT_REFERRAL_DETAILS,
  ALL_VARIABLES.PROJECT_REFERRAL_SOURCE,
  ALL_VARIABLES.PROJECT_SIZE,
  ALL_VARIABLES.PROJECT_START_DATE,
  ALL_VARIABLES.PROJECT_TOTAL_COST,
  ALL_VARIABLES.PROJECT_TOTAL_REVENUE,
  ALL_VARIABLES.PROJECT_TOTAL_PROFIT,
  ALL_VARIABLES.PROJECT_TYPE_NAME,
  ALL_VARIABLES.PROJECT_WAREHOUSE_NOTES,
  ALL_VARIABLES.PROJECT_WEIGHT,
  ALL_VARIABLES.SALESPERSON_EMAIL,
  ALL_VARIABLES.SALESPERSON_NAME,
  ALL_VARIABLES.SALESPERSON_PHONE_NUMBER,
];

const DOCUMENT_VARIABLES = [
  ...SHARED_VARIABLES,
  ALL_VARIABLES.BILL_ADDITIONAL_HOURS,
  ALL_VARIABLES.BILL_TOTAL,
  ALL_VARIABLES.CONFIRMATION_CREATED_DATE,
  ALL_VARIABLES.CONFIRMATION_CREATED_TIME,
  ALL_VARIABLES.CUSTOMER_NAME,
  ALL_VARIABLES.CUSTOMER_EMAIL,
  ALL_VARIABLES.CUSTOMER_PHONE_NUMBER,
  ALL_VARIABLES.INVENTORY_WEIGHT,
  ALL_VARIABLES.JOB_CREW_NOTES,
  ALL_VARIABLES.JOB_DISPATCH_NOTES,
  ALL_VARIABLES.JOB_LOCATIONS_DETAILS,
  ALL_VARIABLES.JOB_LOCATIONS_SUMMARY,
  ALL_VARIABLES.JOB_MILEAGE,
  ALL_VARIABLES.JOB_MOVER_NAMES,
  ALL_VARIABLES.JOB_STARTED_BY_FULL_NAME,
  ALL_VARIABLES.JOB_TRUCK_NAMES,
  ALL_VARIABLES.MOVE_ARRIVAL_WINDOW,
  ALL_VARIABLES.MOVE_CREW_MEMBERS,
  ALL_VARIABLES.MOVE_CREW_SIZE,
  ALL_VARIABLES.MOVE_DATE,
  ALL_VARIABLES.MOVE_DATE_DDMMYYYY,
  ALL_VARIABLES.MOVE_DESTINATION_ADDRESS,
  ALL_VARIABLES.MOVE_DESTINATION_CITY,
  ALL_VARIABLES.MOVE_DESTINATION_RAW_ADDRESS,
  ALL_VARIABLES.MOVE_DESTINATION_STATE,
  ALL_VARIABLES.MOVE_NOT_TO_EXCEED_PRICE,
  ALL_VARIABLES.MOVE_NOT_TO_EXCEED_SERVICES,
  ALL_VARIABLES.MOVE_ORIGIN_ADDRESS,
  ALL_VARIABLES.MOVE_ORIGIN_CITY,
  ALL_VARIABLES.MOVE_ORIGIN_DESTINATION_ADDRESSES,
  ALL_VARIABLES.MOVE_ORIGIN_STATE,
  ALL_VARIABLES.MOVE_PARTY_NOTIFIED,
  ALL_VARIABLES.MOVE_PAYMENT_METHODS,
  ALL_VARIABLES.MOVE_PICKUP_DELIVERY_DATES,
  ALL_VARIABLES.MOVE_SHIPMENT_DESCRIPTION,
  ALL_VARIABLES.MOVE_START_TIME,
  ALL_VARIABLES.MOVE_VALUATION_COVERAGE,
  ALL_VARIABLES.MOVE_VALUATION_MINIMUM,
  ALL_VARIABLES.MOVE_VALUATION_VALUE,
  ALL_VARIABLES.SALESPERSON_FULL_NAME,
  ALL_VARIABLES.INVOICE_IDENTIFIER,
  ALL_VARIABLES.INVOICE_DATE,
  ALL_VARIABLES.INVOICE_PAYMENT_TERMS,
  ALL_VARIABLES.INVOICE_DUE_DATE,
];

const EMAIL_VARIABLES = [
  ...SHARED_VARIABLES,
  ALL_VARIABLES.CONFIRMATION_LINK,
  ALL_VARIABLES.CONFIRMATION_STEPS,
  ALL_VARIABLES.CONFIRMATION_URL,
  ALL_VARIABLES.DATES_DDDD_MMMM_D,
  ALL_VARIABLES.DATES_DDDD_MMMM_DO,
  ALL_VARIABLES.DATETIMES_DDDD_MMMM_D,
  ALL_VARIABLES.DATETIMES_DDDD_MMMM_DO,
  ALL_VARIABLES.DAY_DDDD_MMMM_D,
  ALL_VARIABLES.DAY_DDDD_MMMM_DO,
  ALL_VARIABLES.DAY_MM_DD_YY,
  ALL_VARIABLES.DOCUMENT_FLOW_URL,
  ALL_VARIABLES.INVOICE_LINK,
  ALL_VARIABLES.INVOICE_RECEIPT_LINK,
  ALL_VARIABLES.INVOICE_RECEIPT_URL,
  ALL_VARIABLES.INVOICE_URL,
  ALL_VARIABLES.JOB_DATES_WITH_ARRIVAL_WINDOWS,
  ALL_VARIABLES.JOB_ESTIMATED_HOURS,
  ALL_VARIABLES.JOB_HOUR_MINIMUM,
  ALL_VARIABLES.PROJECT_LINK,
  ALL_VARIABLES.PROJECT_URL,
  ALL_VARIABLES.QUOTE_LINK,
  ALL_VARIABLES.QUOTE_URL,

  // DEPRECATED
  ALL_VARIABLES.URL_CONFIRMATION,
  ALL_VARIABLES.URL_PROJECT,
  ALL_VARIABLES.URL_QUOTE,
  ALL_VARIABLES.URL_INVOICE,
  ALL_VARIABLES.URL_INVOICE_RECEIPT,
];

const getDisplayValues = (variables: any) => {
  return _.sortBy(variables, ['identifier']);
};

const getProjectAndFilteredVariablesByKind = (variables: any, kinds: any) => {
  // PROJECT is always included
  kinds = [VariableKind.PROJECT, ...kinds];

  return variables.filter((variable: any) => {
    return kinds.includes(variable.kind);
  });
};

const HardcodedSystemVariables = {
  ALL_VARIABLES,
  DOCUMENT_VARIABLES,
  EMAIL_VARIABLES,
  SHARED_VARIABLES,
  // Getters
  getDisplayValues,
  getProjectAndFilteredVariablesByKind,
};

export default HardcodedSystemVariables;
