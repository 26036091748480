// Libraries
import _ from 'lodash';

// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {JobModel} from '@supermove/models';
import Location from '@supermove/models/src/Location';
import {Clipboard} from '@supermove/sdk';
import {colors} from '@supermove/styles';
import {
  Currency,
  Datetime,
  Distance,
  Float,
  Json,
  Phone,
  makeUrl,
  pluralize,
  withFragment,
} from '@supermove/utils';

import UserRole from '../../modules/User/enums/UserRole';

import Project from './Project';

const getNoDemoSlug = (slug: any) => {
  return slug.replace('-demo', '');
};

const PRIMARY_STATUS = {
  CANCELLED: 'CANCELLED',
  NOT_READY: 'NOT_READY',
  READY: 'READY',
  IN_PROGRESS: 'IN_PROGRESS',
  INCOMPLETE: 'INCOMPLETE',
  NOT_FINAL: 'NOT_FINAL',
  FINAL: 'FINAL',
  // DEPRECATED(dan) Not used any jobs anymore. Can remove once updating code to not use.
  COMPLETE: 'COMPLETE',
  LEAD: 'LEAD',
  HOLD: 'HOLD',
};

const KIND = {
  COMMERCIAL: 'COMMERCIAL',
  ESTIMATE: 'ESTIMATE',
  LONG_DISTANCE_MOVE: 'LONG_DISTANCE_MOVE',
  RESIDENTIAL: 'RESIDENTIAL',
  TIMESHEET: 'TIMESHEET',
  MOVE: 'MOVE',
};

const getKindLabel = (kind: any) => {
  switch (kind) {
    case KIND.COMMERCIAL:
      return 'Commercial';
    case KIND.ESTIMATE:
      return 'Survey';
    case KIND.LONG_DISTANCE_MOVE:
      return 'Long Distance Move';
    case KIND.RESIDENTIAL:
      return 'Residential';
    case KIND.TIMESHEET:
      return 'Timesheet';
    case KIND.MOVE:
      return 'Move';
    default:
      return '';
  }
};

const SCHEDULE_STATUS = {
  ESTIMATED_RANGE: 'ESTIMATED_RANGE',
  NOT_SCHEDULED: 'NOT_SCHEDULED',
  SCHEDULED: 'SCHEDULED',
};

const getIsEstimatedRange = withFragment(
  (job) => {
    return (job as any).scheduleStatus === SCHEDULE_STATUS.ESTIMATED_RANGE;
  },
  gql`
    fragment Job_getIsEstimatedRange on Job {
      id
      scheduleStatus
    }
  `,
);

const getIsChildJob = withFragment(
  (job) => {
    return !!(job as any).parentJobId;
  },
  gql`
    fragment Job_getIsChildJob on Job {
      id
      parentJobId
    }
  `,
);

const getIsCompletable = withFragment(
  (job) => {
    return !(
      (job as any).isUnscheduled ||
      Datetime.isFuture((job as any).startDate) ||
      (job as any).isComplete
    );
  },
  gql`
    fragment Job_getIsCompletable on Job {
      id
      isComplete
      isUnscheduled
      startDate
    }
  `,
);

const getIsResetable = withFragment(
  (job) => {
    return (
      (job as any).move &&
      (job as any).primaryStatus === PRIMARY_STATUS.IN_PROGRESS &&
      !(job as any).move?.isPreMoveDocumentsPrepared &&
      !(job as any).move?.hasSignedBillOfLading
    );
  },
  gql`
    fragment Job_getIsResetable on Job {
      id
      move {
        id
        isPreMoveDocumentsPrepared
        hasSignedBillOfLading
      }
      primaryStatus
    }
  `,
);

const getJobDate = withFragment(
  (job) => {
    // Used on crew JobsList for JobItem. All jobs should be for today.
    if ((job as any).day) {
      return Datetime.toDisplayDate(Datetime.fromDate((job as any).day.value), 'YYYY/MM/DD');
    }
    // Return today's date for all estimated range jobs. This will group
    // in estimated range jobs with today's jobs.
    if (getIsEstimatedRange(job)) {
      return Datetime.toDisplayDate(Datetime.today, 'YYYY/MM/DD');
    }
    return '';
  },
  gql`
    ${getIsEstimatedRange.fragment}
    fragment Job_getJobDate on Job {
      id
      day {
        id
        value
      }
      ...Job_getIsEstimatedRange
    }
  `,
);

const hasEstimatedRangeJobCounter = withFragment(
  (job) => {
    if ((job as any).isEstimatedRange && (job as any).startDate && (job as any).endDate) {
      return true;
    }
    // @ts-expect-error TS(2339): Property 'parentJob' does not exist on type 'unkno... Remove this comment to see the full error message
    const {parentJob} = job;
    if (parentJob && parentJob.isEstimatedRange && parentJob.startDate && parentJob.endDate) {
      return true;
    }
    return false;
  },
  gql`
    fragment Job_hasEstimatedRangeJobCounter on Job {
      id
      isEstimatedRange
      startDate
      endDate
      parentJob {
        id
        isEstimatedRange
        startDate
        endDate
      }
    }
  `,
);

const getEstimatedRangeJobCounter = withFragment(
  (job, date) => {
    if ((job as any).isEstimatedRange && (job as any).startDate && (job as any).endDate) {
      return Datetime.getDayCounterText({
        startDate: (job as any).startDate,
        endDate: (job as any).endDate,
        currentDate: date,
      });
    }
    // @ts-expect-error TS(2339): Property 'parentJob' does not exist on type 'unkno... Remove this comment to see the full error message
    const {parentJob} = job;
    if (parentJob && parentJob.isEstimatedRange && parentJob.startDate && parentJob.endDate) {
      return Datetime.getDayCounterText({
        startDate: parentJob.startDate,
        endDate: parentJob.endDate,
        currentDate: (job as any).day.value,
      });
    }
    return '';
  },
  gql`
    fragment Job_getEstimatedRangeJobCounter on Job {
      id
      startDate
      endDate
      isEstimatedRange
      day {
        id
        value
      }
      parentJob {
        id
        startDate
        endDate
        isEstimatedRange
      }
    }
  `,
);

const getDisplayArrivalWindow = withFragment(
  (job, options = {}) => {
    if (!(job as any).startTime1 || !(job as any).startTime2) {
      return '';
    }

    const text =
      (job as any).startTime1 === (job as any).startTime2
        ? Datetime.convertToDisplayTime((job as any).startTime1)
        : `${Datetime.convertToDisplayTime((job as any).startTime1)} - ` +
          `${Datetime.convertToDisplayTime((job as any).startTime2)}`;
    if (options.shouldIncludeDescription) {
      return (job as any).startTime1 === (job as any).startTime2
        ? `${text} arrival time`
        : `${text} arrival window`;
    } else {
      return text;
    }
  },
  gql`
    fragment Job_getDisplayArrivalWindow on Job {
      id
      startTime1
      startTime2
    }
  `,
);

const getDisplayDate = withFragment(
  (job, format, defaultText = 'Date not scheduled') => {
    if (getIsEstimatedRange(job)) {
      const startDate = (job as any).startDate
        ? Datetime.convertToDisplayDate((job as any).startDate, format)
        : 'TBD';
      const endDate = (job as any).endDate
        ? Datetime.convertToDisplayDate((job as any).endDate, format)
        : 'TBD';
      return `${startDate} - ${endDate}`;
    }
    if (getIsChildJob(job)) {
      const parentStartDate = (job as any).parentJob.startDate
        ? Datetime.convertToDisplayDate((job as any).parentJob.startDate, format)
        : 'TBD';
      const parentEndDate = (job as any).parentJob.endDate
        ? Datetime.convertToDisplayDate((job as any).parentJob.endDate, format)
        : 'TBD';
      return `${parentStartDate} - ${parentEndDate}`;
    }
    return (job as any).day
      ? Datetime.convertToDisplayDate((job as any).day.value, format)
      : defaultText;
  },
  gql`
    ${getIsEstimatedRange.fragment}
    ${getIsChildJob.fragment}
    fragment Job_getDisplayDate on Job {
      id
      startDate
      endDate
      day {
        id
        value
      }
      parentJob {
        id
        startDate
        endDate
      }
      ...Job_getIsChildJob
      ...Job_getIsEstimatedRange
    }
  `,
);

const getDispatchDisplayDate = withFragment(
  (job, format) => {
    if (getIsEstimatedRange(job)) {
      const startDate = (job as any).startDate
        ? Datetime.convertToDisplayDate((job as any).startDate, format)
        : 'TBD';
      const endDate = (job as any).endDate
        ? Datetime.convertToDisplayDate((job as any).endDate, format)
        : 'TBD';
      return `${startDate} - ${endDate}`;
    }
    return (job as any).day ? Datetime.convertToDisplayDate((job as any).day.value, format) : 'TBD';
  },
  gql`
    ${getIsEstimatedRange.fragment}
    fragment Job_getDispatchDisplayDate on Job {
      id
      startDate
      endDate
      day {
        id
        value
      }
      ...Job_getIsEstimatedRange
    }
  `,
);

const getJobDisplayDate = withFragment(
  (job, format = Datetime.DISPLAY_SHORT_DATE) => {
    if (getIsEstimatedRange(job)) {
      const startDate = (job as any).startDate
        ? Datetime.convertToDisplayDate((job as any).startDate, format)
        : 'TBD';
      const endDate = (job as any).endDate
        ? Datetime.convertToDisplayDate((job as any).endDate, format)
        : 'TBD';
      return `${startDate} - ${endDate}`;
    }
    return (job as any).startDate
      ? Datetime.convertToDisplayDate((job as any).startDate, format)
      : 'TBD';
  },
  gql`
    ${getIsEstimatedRange.fragment}
    fragment Job_getJobDisplayDate on Job {
      id
      startDate
      endDate
      ...Job_getIsEstimatedRange
    }
  `,
);

const getCrewSizeText = withFragment(
  (job) => {
    return pluralize('mover', Number((job as any).crewSize), true);
  },
  gql`
    fragment Job_getCrewSizeText on Job {
      id
      crewSize
    }
  `,
);

const getTruckCountText = withFragment(
  (job) => {
    return pluralize('truck', Number((job as any).numberOfTrucks), true);
  },
  gql`
    fragment Job_getTruckCountText on Job {
      id
      numberOfTrucks
    }
  `,
);

const getActiveJobUsersCountText = withFragment(
  (job) => {
    return pluralize('mover', Number((job as any).activeJobUsersCount), true);
  },
  gql`
    fragment Job_getActiveJobUsersCountText on Job {
      id
      activeJobUsersCount
    }
  `,
);

const getHourMinimumText = withFragment(
  (job) => {
    return (job as any).hourMinimum
      ? pluralize('hour', Number((job as any).hourMinimum), true)
      : '';
  },
  gql`
    fragment Job_getHourMinimumText on Job {
      id
      hourMinimum
    }
  `,
);

const getStartLocationTitle = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ startTime1, startTime2, start... Remove this comment to see the full error message
  ({startTime1, startTime2, startLocation = {}}) => {
    const startTimeText = startTime1 ? `${Datetime.convertToDisplayTime(startTime1)}` : 'TBD';
    const endTimeText = startTime2 ? ` - ${Datetime.convertToDisplayTime(startTime2)}` : ``;
    return `${startTimeText}${endTimeText} in ${(startLocation as any).city}`;
  },
  gql`
    fragment Job_getStartLocationTitle on Job {
      id
      startTime1
      startTime2
      startLocation {
        id
        city
      }
    }
  `,
);

const getTimesheetScreen = withFragment(
  (job) => {
    if ((job as any).project.projectType.features.timesheetsV2) {
      return 'JobTimesheetActionJob';
    }
    // TODO(mark): After all previous job_types are updated, we can remove this.
    if ((job as any).kind === KIND.COMMERCIAL) {
      return 'EditCommercialTimesheetCrewJob';
    }
    if ((job as any).hasJobFeatureCrewPerMoverTimesheet) {
      return 'EditCommercialTimesheetCrewJob';
    }
    return 'EditResidentialTimesheetCrewJob';
  },
  gql`
    fragment Job_getTimesheetScreen on Job {
      id
      kind
      hasJobFeatureCrewPerMoverTimesheet: hasJobFeature(kind: "CREW_PER_MOVER_TIMESHEET")
      project {
        id
        projectType {
          id
          features {
            timesheetsV2
          }
        }
      }
    }
  `,
);

const getTimesheetMissingRequirements = withFragment(
  (job: JobModel, {isCompletionRequired}: {isCompletionRequired?: boolean} = {}) => {
    const timeRequirements = {isMissingTimesheetBlock: false, isMissingEndTime: false};

    if (isCompletionRequired) {
      const {timesheetBillableEntries} = job;
      const timesheetBlocks = _.flatten(
        timesheetBillableEntries.map((entry: any) => entry.timesheetBlocks),
      );

      if (_.isEmpty(timesheetBlocks)) {
        return {...timeRequirements, isMissingTimesheetBlock: true};
      }

      for (const timesheetBlock of timesheetBlocks) {
        if (!timesheetBlock.rangeTo) {
          return {...timeRequirements, isMissingEndTime: true};
        }
      }
    }

    return timeRequirements;
  },
  gql`
    fragment Job_getTimesheetMissingRequirements on Job {
      id
      timesheetBillableEntries {
        id
        timesheetBlocks {
          id
          rangeTo
        }
      }
    }
  `,
);

const getStartTimeAt = withFragment(
  (job) => {
    switch ((job as any).startTimeDefault) {
      case 'MOVE_PREPARE_JOB_FINISHED':
        return (job as any).prepareJobFinishedAt
          ? Datetime.fromDatetime((job as any).prepareJobFinishedAt)
          : Datetime.now;
      case 'NOW':
      default:
        return Datetime.now;
    }
  },
  gql`
    fragment Job_getStartTimeAt on Job {
      id
      startTimeDefault
      prepareJobFinishedAt
    }
  `,
);

const getTotalDistanceText = withFragment(
  (job) => {
    return Distance.display((job as any).totalDistance);
  },
  gql`
    fragment Job_getTotalDistanceText on Job {
      id
      totalDistance
    }
  `,
);

const getTravelFeeText = ({travelFee}: any) => {
  if (travelFee === 0) {
    return '---';
  }
  return Currency.display(travelFee * 100);
};

const getDisplayReferral = withFragment(
  (job) => {
    if ((job as any).referralDetails) {
      return `${(job as any).referralSource}: ${(job as any).referralDetails}`;
    }
    return (job as any).referralSource;
  },
  gql`
    fragment Job_getDisplayReferral on Job {
      id
      referralSource
      referralDetails
    }
  `,
);

const getDisplayEstimateHours = withFragment(
  (job) => {
    // @ts-expect-error TS(2339): Property 'estimateHours1' does not exist on type '... Remove this comment to see the full error message
    const {estimateHours1, estimateHours2} = job;

    if (!estimateHours1) {
      return 'TBD';
    }

    const hourText = pluralize('hour', estimateHours2 || estimateHours1);

    if (estimateHours1 === estimateHours2) {
      return `${estimateHours1} ${hourText}`;
    }

    const rangeEnd = estimateHours2 ? `- ${estimateHours2} ` : '';
    return `${estimateHours1} ${rangeEnd}${hourText}`;
  },
  gql`
    fragment Job_getEstimatedHours on Job {
      id
      estimateHours1
      estimateHours2
    }
  `,
);

const hasEstimateHours = withFragment(
  (job) => {
    return !!(job as any).estimateHours1;
  },
  gql`
    fragment Job_hasEstimateHours on Job {
      id
      estimateHours1
      estimateHours2
    }
  `,
);

const hasFuelFee = withFragment(
  (job) => {
    return !!(job as any).fuelFee;
  },
  gql`
    fragment Job_hasFuelFee on Job {
      id
      fuelFee
    }
  `,
);

const getMaxEstimateHours = withFragment(
  (job) => {
    const estimateHours1 = Float.toFloat((job as any).estimateHours1);
    const estimateHours2 = Float.toFloat((job as any).estimateHours2);
    return Math.max(estimateHours1, estimateHours2, 0);
  },
  gql`
    fragment Job_getMaxEstimateHours on Job {
      id
      estimateHours1
      estimateHours2
    }
  `,
);

const getEstimateTotal = withFragment(
  (job: any): any => {
    // TODO(mark): Guarantee types and non-null values.
    const maxEstimateHours = Job.getMaxEstimateHours(job);
    const hourlyRate = Float.toFloat((job as any).hourlyRate);
    const travelFee = Float.toFloat((job as any).travelFee);
    const fuelFee = Float.toFloat((job as any).fuelFee) / 100;
    return maxEstimateHours * hourlyRate + travelFee + fuelFee;
  },
  gql`
    ${getMaxEstimateHours.fragment}

    fragment Job_getEstimateTotal on Job {
      id
      hourlyRate
      travelFee
      fuelFee
      ...Job_getMaxEstimateHours
    }
  `,
);

const getFullName = withFragment(
  (job) => {
    return `${(job as any).fullName}: ${(job as any).name}`;
  },
  gql`
    fragment Job_getFullName on Job {
      id
      fullName
      name
    }
  `,
);

const getJobNameForCrew = withFragment(
  (job) => {
    const jobFullName = getFullName(job);
    const currentDate = Datetime.toDisplayDate(Datetime.today, 'YYYY/MM/DD');
    if (getIsEstimatedRange(job)) {
      return `[${getEstimatedRangeJobCounter(job, currentDate)}] ${jobFullName}`;
    }
    if (getIsChildJob(job)) {
      return `[${getEstimatedRangeJobCounter((job as any).parentJob, currentDate)}] ${jobFullName}`;
    }
    return jobFullName;
  },
  gql`
    ${getFullName.fragment}
    ${getIsChildJob.fragment}
    ${getIsEstimatedRange.fragment}
    ${getEstimatedRangeJobCounter.fragment}
    fragment Job_getJobNameForCrew on Job {
      id
      parentJob {
        id
        ...Job_getIsEstimatedRange
        ...Job_getEstimatedRangeJobCounter
      }
      ...Job_getFullName
      ...Job_getIsChildJob
      ...Job_getIsEstimatedRange
      ...Job_getEstimatedRangeJobCounter
    }
  `,
);

const getPaymentName = withFragment(
  (job) => {
    return `Payment for ${(job as any).fullName}`;
  },
  gql`
    fragment Job_getPaymentName on Job {
      id
      fullName
    }
  `,
);

// getTipName is used by Project.js too, if fragment here changes
// we need to update getActiveJobsDropdownOptions as well in Project.js
const getTipName = withFragment(
  (job) => {
    return `Tip for ${(job as any).fullName}`;
  },
  gql`
    fragment Job_getTipName on Job {
      id
      fullName
    }
  `,
);

// Returns text describing job's hourly rate and flat fee.
// Only includes non-zero values for both options.
const getPrimaryJobRate = withFragment(
  (job) => {
    if ((job as any).hourlyRate) {
      return `$${(job as any).hourlyRate} / hr${(job as any).travelFee ? ` + $${(job as any).travelFee}` : ''}`;
    } else {
      return `$${(job as any).travelFee}`;
    }
  },
  gql`
    fragment Job_getPrimaryJobRate on Job {
      id
      hourlyRate
      travelFee
    }
  `,
);

/**
 * Gets the display text for the job.
 * ie. "Job 43: job display nmae"
 */
const getDisplayText = withFragment(
  (job) => {
    return `Job ${(job as any).identifier}: ${(job as any).displayName}`;
  },
  gql`
    ${getEstimatedRangeJobCounter.fragment}

    fragment Job_getDisplayText on Job {
      id
      identifier
      displayName
      ...Job_getEstimatedRangeJobCounter
    }
  `,
);

const getEstimatedArrivalText = withFragment(
  (job) => {
    if ((job as any).latestEnrichedPosition) {
      const datetime = Datetime.fromDatetime(
        (job as any).latestEnrichedPosition.estimatedLocationArrivalAt,
      );
      const datetimeLatestPosition = Datetime.fromDatetime((job as any).latestPosition.createdAt);
      return (
        `ETA: ${Datetime.convertToDisplayTime(datetime)} ` +
        `(Updated at ${Datetime.convertToDisplayTime(datetimeLatestPosition)})`
      );
    }
    return 'ETA: N/A';
  },
  gql`
    fragment Job_getEstimatedArrivalText on Job {
      id
      latestEnrichedPosition {
        id
        estimatedLocationArrivalAt
      }
      latestPosition {
        id
        createdAt
      }
    }
  `,
);

const getDrivingStatusText = withFragment(
  (job) => {
    switch ((job as any).drivingStatus) {
      case 'DRIVING':
        return 'Driving';
      case 'NOT_DRIVING':
        return 'Not Driving';
      case 'NO_POSITION_DATA':
        return 'No Position Data';
      default:
        return 'Unknown';
    }
  },
  gql`
    fragment Job_getDrivingStatusText on Job {
      id
      drivingStatus
    }
  `,
);

const getDrivingPrimaryStatusColor = withFragment(
  (job) => {
    switch ((job as any).drivingStatus) {
      case 'DRIVING':
        return colors.green.status;
      case 'NOT_DRIVING':
        return colors.orange.status;
      case 'NO_POSITION_DATA':
        return colors.red.warning;
      default:
        return colors.gray.primary;
    }
  },
  gql`
    fragment Job_getDrivingPrimaryStatusColor on Job {
      id
      drivingStatus
    }
  `,
);

const getPrimaryStatusColor = (primaryStatus: any) => {
  switch (primaryStatus) {
    case PRIMARY_STATUS.CANCELLED:
      return colors.red.warning;
    case PRIMARY_STATUS.NOT_READY:
    case PRIMARY_STATUS.READY:
      return colors.yellow.status;
    case PRIMARY_STATUS.IN_PROGRESS:
    case PRIMARY_STATUS.INCOMPLETE:
    case PRIMARY_STATUS.NOT_FINAL:
      return colors.orange.status;
    case PRIMARY_STATUS.FINAL:
      return colors.green.status;
    default:
      return colors.gray.primary;
  }
};

// Calendar Statuses
const getCalendarPrimaryStatusColor = withFragment(
  (job: JobModel, {showCancelledProject}: {showCancelledProject?: boolean} = {}) => {
    if (job.isCancelled) {
      return colors.gray.secondary;
    }
    if (showCancelledProject && job.project.isCancelled) {
      return colors.Pink600;
    }

    const salesStatus = Project.getSalesStatus((job as any).project);
    switch (salesStatus) {
      case 'LEAD':
      case 'HOLD':
        return colors.orange.status;
      case 'BOOKED':
        return getPrimaryStatusColor(job.calendarPrimaryStatus);
      default:
        return colors.gray.primary;
    }
  },
  gql`
    ${Project.getSalesStatus.fragment}

    fragment Job_getCalendarPrimaryStatusColor on Job {
      id
      calendarPrimaryStatus
      isCancelled
      project {
        id
        isCancelled
        ...Project_getSalesStatus
      }
    }
  `,
);

const getCalendarPrimaryStatusTextWithName = withFragment(
  (job) => {
    const {features} = (job as any).project.projectType;

    switch ((job as any).calendarPrimaryStatus) {
      case PRIMARY_STATUS.CANCELLED:
        return `Job ${(job as any).identifier} is cancelled`;
      case PRIMARY_STATUS.NOT_READY:
        return `Job ${(job as any).identifier} is not ready yet`;
      case PRIMARY_STATUS.READY:
        return `Job ${(job as any).identifier} is ready`;
      case PRIMARY_STATUS.IN_PROGRESS:
        return `Job ${(job as any).identifier} is in progress`;
      case PRIMARY_STATUS.INCOMPLETE:
        return `Job ${(job as any).identifier} is incomplete`;
      case PRIMARY_STATUS.NOT_FINAL:
        return `Job ${(job as any).identifier}${features.timesheetsV2 ? ' is completed' : ': report is not final'}`;
      case PRIMARY_STATUS.FINAL:
        return `Job ${(job as any).identifier}${features.timesheetsV2 ? ' is finalized' : ': report is final'}`;
      default:
        return '';
    }
  },
  gql`
    fragment Job_getCalendarPrimaryStatusTextWithName on Job {
      id
      identifier
      calendarPrimaryStatus
      project {
        id
        projectType {
          id
          features {
            timesheetsV2
          }
        }
      }
    }
  `,
);

const getCalendarPrimaryStatusText = withFragment(
  (job: JobModel, {isEnabledCancelProject, showCancelledProject} = {}) => {
    const {features} = job.project.projectType;
    if (showCancelledProject && job.project.isCancelled) {
      return 'Cancelled';
    }

    switch (job.calendarPrimaryStatus) {
      // TODO(dan) Remove this once fully enabled isEnabledCancelProject
      case PRIMARY_STATUS.CANCELLED:
        return `${isEnabledCancelProject ? 'Removed' : 'Cancelled'}`;
      case PRIMARY_STATUS.NOT_READY:
        return 'Dispatch Not Done';
      case PRIMARY_STATUS.READY:
        return 'Dispatch Done';
      case PRIMARY_STATUS.IN_PROGRESS:
        return 'In Progress';
      case PRIMARY_STATUS.INCOMPLETE:
        return 'Incomplete';
      case PRIMARY_STATUS.NOT_FINAL:
        return features.timesheetsV2 ? 'Job Completed' : 'Report Not final';
      case PRIMARY_STATUS.FINAL:
        return features.timesheetsV2 ? 'Job Finalized' : 'Report is final';
      default:
        return 'Unknown';
    }
  },
  gql`
    fragment Job_getCalendarPrimaryStatusText on Job {
      id
      calendarPrimaryStatus
      project {
        id
        isCancelled
        projectType {
          id
          features {
            timesheetsV2
          }
        }
      }
    }
  `,
);

const getCalendarPrimaryStatusIcon = withFragment(
  (job: JobModel, {showCancelledProject}: {showCancelledProject?: boolean} = {}) => {
    if (job.isCancelled) {
      return Icon.CalendarCircleMinus;
    }
    if (showCancelledProject && job.project.isCancelled) {
      return Icon.CalendarXmark;
    }
    switch (job.calendarPrimaryStatus) {
      case PRIMARY_STATUS.CANCELLED:
        return Icon.CalendarXmark;
      case PRIMARY_STATUS.NOT_READY:
        return Icon.UserClock;
      case PRIMARY_STATUS.READY:
        return Icon.UserCheck;
      case PRIMARY_STATUS.IN_PROGRESS:
        return Icon.HourglassClock;
      case PRIMARY_STATUS.INCOMPLETE:
        return Icon.CalendarExclamation;
      case PRIMARY_STATUS.COMPLETE:
      case PRIMARY_STATUS.NOT_FINAL:
      case PRIMARY_STATUS.FINAL:
        return Icon.CheckCircle;
      default:
        return Icon.QuestionCircle;
    }
  },
  gql`
    fragment Job_getCalendarPrimaryStatusIcon on Job {
      id
      isCancelled
      calendarPrimaryStatus
      project {
        id
        isCancelled
      }
    }
  `,
);

const getCalendarSecondaryStatusIcon = withFragment(
  (job: JobModel, {showCancelledProject}: {showCancelledProject?: boolean} = {}) => {
    if (job.isCancelled) {
      return Icon.CalendarCircleMinus;
    }
    if (showCancelledProject && job.project.isCancelled) {
      return Icon.CalendarXmark;
    }

    const salesStatus = Project.getSalesStatus(job.project);
    if (['LEAD', 'HOLD'].includes(salesStatus)) {
      return null;
    }

    switch (job.calendarPrimaryStatus) {
      case PRIMARY_STATUS.READY:
        return Icon.Check;
      case PRIMARY_STATUS.COMPLETE:
      case PRIMARY_STATUS.FINAL:
        return Icon.Lock;
      default:
        return null;
    }
  },
  gql`
    ${Project.getSalesStatus.fragment}

    fragment Job_getCalendarSecondaryStatusIcon on Job {
      id
      calendarPrimaryStatus
      calendarSecondaryStatus
      isCancelled
      project {
        id
        isCancelled
        ...Project_getSalesStatus
      }
    }
  `,
);

const getCalendarSecondaryStatusText = withFragment(
  (job) => {
    switch ((job as any).calendarSecondaryStatus) {
      // CANCELLED
      case 'CANCELLED':
        return '';
      // NOT_READY
      case 'NOT_READY_CONFIRMATION_NOT_SENT':
        return 'Confirmation not sent';
      case 'NOT_READY_CONFIRMATION_NOT_SIGNED':
        return 'Confirmation not complete';
      case 'NOT_READY_NTE':
        return 'NTE not set';
      case 'NOT_READY_TRUCK':
        return 'Trucks not assigned';
      case 'NOT_READY_CREW':
        return 'Movers not ready';
      // READY
      case 'READY':
        return '';
      // IN_PROGRESS
      case 'IN_PROGRESS_CREW_CONFIRMED':
        return 'Movers confirmed';
      case 'IN_PROGRESS_ON_THE_WAY':
        return 'Movers on the way';
      case 'IN_PROGRESS_ARRIVED':
        return 'Movers on-site';
      case 'IN_PROGRESS_SIGNED_DOCUMENTS':
        return 'Documents signed';
      case 'IN_PROGRESS_CONFIRMED_TIMES':
        return 'Bill is ready';
      case 'IN_PROGRESS_BILL_PAID':
        return 'Bill is paid';
      // INCOMPLETE
      case 'INCOMPLETE':
      case 'PAST_AND_INCOMPLETE':
        return 'Action required';
      // COMPLETE
      case 'COMPLETE':
        return '';
      // NOT_FINAL
      case 'NOT_FINAL_REPORT_INCOMPLETE':
        return 'Report not done';
      // FINAL
      case 'FINAL':
        return '';
      default:
        return (job as any).calendarSecondaryStatus;
    }
  },
  gql`
    fragment Job_getCalendarSecondaryStatusText on Job {
      id
      calendarSecondaryStatus
    }
  `,
);

const getOperationsStatus = withFragment(
  (job) => {
    switch ((job as any).calendarPrimaryStatus) {
      case PRIMARY_STATUS.CANCELLED:
        return 'CANCELLED';
      case PRIMARY_STATUS.LEAD:
        return 'DISPATCH_NOT_DONE';
      case PRIMARY_STATUS.HOLD:
        return 'DISPATCH_NOT_DONE';
      case PRIMARY_STATUS.NOT_READY:
        return 'DISPATCH_NOT_DONE';
      case PRIMARY_STATUS.READY:
        return 'DISPATCH_DONE';
      case PRIMARY_STATUS.IN_PROGRESS:
        return 'IN_PROGRESS';
      case PRIMARY_STATUS.INCOMPLETE:
        return 'INCOMPLETE';
      case PRIMARY_STATUS.NOT_FINAL:
        return 'NOT_FINAL';
      case PRIMARY_STATUS.FINAL:
        return 'FINAL';
      default:
        return 'UNKNOWN';
    }
  },
  gql`
    fragment Job_getOperationsStatus on Job {
      id
      calendarPrimaryStatus
    }
  `,
);

const getOperationsStatusText = withFragment(
  (job) => {
    const operationStatus = getOperationsStatus(job);
    switch (operationStatus) {
      case 'CANCELLED':
        return 'Cancelled';
      case 'DISPATCH_NOT_DONE':
        return 'Dispatch not done';
      case 'DISPATCH_DONE':
        return 'Dispatch done';
      case 'IN_PROGRESS':
        return 'In progress';
      case 'INCOMPLETE':
        return 'Incomplete';
      case 'NOT_FINAL':
        return (job as any).project.projectType.features.timesheetsV2
          ? 'Job completed'
          : 'Report not final';
      case 'FINAL':
        return 'Report final';
      default:
        return 'UNKNOWN';
    }
  },
  gql`
    ${getOperationsStatus.fragment}
    fragment Job_getOperationsStatusText on Job {
      id
      project {
        id
        projectType {
          id
          features {
            timesheetsV2
          }
        }
      }
      ...Job_getOperationsStatus
    }
  `,
);

/**
 * Returns the location forms that have longitude and latitude that are numbers.
 *
 * @param {Job} job
 */
const getLocations = withFragment(
  (job) => {
    return (job as any).locations.filter(
      (location: any) => !!location.latitude && !!location.longitude,
    );
  },
  gql`
    fragment Job_getLocations on Job {
      id
      locations {
        id
        latitude
        longitude
      }
    }
  `,
);

const getDisplayFirstAndLastCityState = withFragment(
  (job) => {
    const locations = getLocations(job);

    const firstCityState = Location.getDisplayCityState(locations[0]);

    const lastCityState =
      locations.length > 1
        ? Location.getDisplayCityState(locations[locations.length - 1])
        : undefined;

    return lastCityState ? `${firstCityState} - ${lastCityState}` : firstCityState;
  },
  gql`
    ${getLocations.fragment}
    ${Location.getDisplayCityState.fragment}

    fragment Job_getFirstAndLastDisplayCityState on Job {
      id
      locations {
        id
        ...Location_getDisplayCityState
      }
      ...Job_getLocations
    }
  `,
);

const getIsAfterMove = withFragment(
  (job) => {
    return [PRIMARY_STATUS.COMPLETE, PRIMARY_STATUS.NOT_FINAL, PRIMARY_STATUS.FINAL].includes(
      (job as any).primaryStatus,
    );
  },
  gql`
    fragment Job_getIsAfterMove on Job {
      id
      primaryStatus: calendarPrimaryStatus
    }
  `,
);

const getIsDuringMove = withFragment(
  (job) => {
    return [PRIMARY_STATUS.IN_PROGRESS, PRIMARY_STATUS.INCOMPLETE].includes(
      (job as any).primaryStatus,
    );
  },
  gql`
    fragment Job_getIsDuringMove on Job {
      id
      primaryStatus: calendarPrimaryStatus
    }
  `,
);

const getIsBeforeMove = withFragment(
  (job) => {
    return !getIsDuringMove(job) && !getIsAfterMove(job);
  },
  gql`
    ${getIsDuringMove.fragment}
    ${getIsAfterMove.fragment}
    fragment Job_getIsBeforeMove on Job {
      id
      ...Job_getIsDuringMove
      ...Job_getIsAfterMove
    }
  `,
);

const getIsCommercial = withFragment(
  (job) => {
    return (job as any).kind === 'COMMERCIAL';
  },
  gql`
    fragment Job_getIsCommercial on Job {
      id
      kind
    }
  `,
);

const getIsEstimate = withFragment(
  (job) => {
    return (job as any).kind === 'ESTIMATE';
  },
  gql`
    fragment Job_getIsEstimate on Job {
      id
      kind
    }
  `,
);

const getIsMove = withFragment(
  (job) => {
    return !['ESTIMATE', 'REQUEST'].includes((job as any).kind);
  },
  gql`
    fragment Job_getIsMove on Job {
      id
      kind
    }
  `,
);

const getIsSalesLocked = withFragment(
  (job) => {
    return (job as any).isProjectSalesLocked && !(job as any).isTest;
  },
  gql`
    fragment Job_getIsSalesLocked on Job {
      id
      isProjectSalesLocked
      isTest
    }
  `,
);

const getShowMovers = withFragment(
  (job) => {
    if (getIsBeforeMove(job)) {
      return getIsMove(job);
    }
    return true;
  },
  gql`
    ${getIsBeforeMove.fragment}
    ${getIsMove.fragment}
    fragment Job_getShowMovers on Job {
      id
      ...Job_getIsBeforeMove
      ...Job_getIsMove
    }
  `,
);

const getShowTrucks = withFragment(
  (job) => {
    if (getIsBeforeMove(job)) {
      return (job as any).hasJobStepDispatchTrucks;
    }
    return true;
  },
  gql`
    ${getIsBeforeMove.fragment}
    fragment Job_getShowTrucks on Job {
      id
      hasJobStepDispatchTrucks: hasJobStep(kind: "DISPATCH_TRUCKS")
      ...Job_getIsBeforeMove
    }
  `,
);

/**
 * Returns true if the job is in a preparation state. Returns false otherwise
 * (in_progress, complete, incomplete).
 *
 * @param {Job} job
 */
const isInPrepareState = (job: any) => {
  return ![PRIMARY_STATUS.IN_PROGRESS, PRIMARY_STATUS.COMPLETE, PRIMARY_STATUS.INCOMPLETE].includes(
    job.primaryStatus,
  );
};

/**
 * Gets the text to render above the location map. Currently, the last update location of the truck.
 * ie. "Location as of: 1:31 PM"
 *
 * @param {object} job
 */
const getLocationUpdateText = withFragment(
  (job) => {
    // @ts-expect-error TS(2339): Property 'jobTrucks' does not exist on type 'unkno... Remove this comment to see the full error message
    const {jobTrucks} = job;
    if (jobTrucks.length === 0) {
      return '';
    }

    // TODO(peter) jobTruck - once we add more trucks we need to scan the entire array
    const jobTruck = _.get(job, 'jobTrucks.0');
    if (jobTruck.latestPosition == null) {
      return '';
    }

    const datetime = Datetime.fromDatetime(jobTruck.latestPosition.timestamp);
    return `Location as of: ${Datetime.convertToDisplayTime(datetime)}`;
  },
  gql`
    fragment Job_getLocationUpdateText on Job {
      jobTrucks {
        id
        latestPosition {
          id
          latitude
          longitude
          timestamp
        }
      }
    }
  `,
);

// Dispatch calendar helpers
const getDispatchJobUsersNames = withFragment(
  (job) => {
    if ((job as any).activeJobUsers.length > 0) {
      return (job as any).activeJobUsers.map((jobUser: any) => jobUser.user.fullName).join(', ');
    }
    return ``;
  },
  gql`
    fragment Job_getDispatchJobUsersNames on Job {
      id
      activeJobUsers {
        id
        user {
          id
          fullName
        }
      }
    }
  `,
);

const getDispatchJobUsersCount = withFragment(
  (job) => {
    const numberOfAssignedMovers = _.get(job, 'assignedJobUsersCount', 0);
    const numberOfRequiredMovers = _.get(job, 'numberOfMovers', 0);
    return `${numberOfAssignedMovers}/${numberOfRequiredMovers}`;
  },
  gql`
    fragment Job_getDispatchJobUsersCount on Job {
      id
      assignedJobUsersCount
      numberOfMovers
    }
  `,
);

const getDispatchJobTrucksCount = withFragment(
  (job) => {
    const numberOfAssignedTrucks = _.get(job, 'numberOfAssignedTrucks', 0);
    const numberOfRequiredTrucks = _.get(job, 'numberOfTrucks', 0);
    return `${numberOfAssignedTrucks}/${numberOfRequiredTrucks}`;
  },
  gql`
    fragment Job_getDispatchJobTrucksCount on Job {
      id
      numberOfAssignedTrucks
      numberOfTrucks
    }
  `,
);

const getAdditionalItems = withFragment(
  (job) => {
    return Json.toObject((job as any).additionalItems);
  },
  gql`
    fragment Job_getAdditionalItems on Job {
      id
      additionalItems
    }
  `,
);

const getAuthorizeDotNetUrlWithAmount = withFragment(
  (job, {amount}) => {
    return `${(job as any).authorizeDotNetUrl}&id3=${Currency.display(amount)}`;
  },
  gql`
    fragment Job_getAuthorizeDotNetUrlWithAmount on Job {
      id
      authorizeDotNetUrl
    }
  `,
);

const sortJobsBySequence = withFragment(
  (jobs) => {
    // @ts-expect-error TS(2769): No overload matches this call.
    return _.sortBy(jobs, [
      (job) => ((job as any).kind === 'REQUEST' ? 0 : 1),
      (job) => (job as any).date,
      (job) => (job as any).startTime1,
      (job) => (job as any).endDate,
    ]);
  },
  gql`
    fragment Job_sortJobsBySequence on Job {
      id
      kind
      date
      startTime1
      endDate
    }
  `,
);

const getOrganizationBusinessName = withFragment(
  (job) => {
    const crew = (job as any).primaryCrew;
    if (!crew) {
      return '';
    }
    const {organization} = crew;
    return organization.businessName || 'COMPANY';
  },
  gql`
    fragment Job_getOrganizationBusinessName on Job {
      id
      primaryCrew {
        id
        organization {
          id
          businessName
        }
      }
    }
  `,
);

const getOrganizationBusinessAddress = withFragment(
  (job) => {
    const crew = (job as any).primaryCrew;
    if (!crew) {
      return '';
    }
    const {organization} = crew;
    return organization.businessAddress || '';
  },
  gql`
    fragment Job_getOrganizationBusinessAddress on Job {
      id
      primaryCrew {
        id
        organization {
          id
          businessAddress
        }
      }
    }
  `,
);

const getOrganizationBusinessPhoneNumber = withFragment(
  (job) => {
    const crew = (job as any).primaryCrew;
    if (!crew) {
      return '';
    }
    const {organization} = crew;
    return Phone.display(organization.phoneNumber) || '';
  },
  gql`
    fragment Job_getOrganizationBusinessPhoneNumber on Job {
      id
      primaryCrew {
        id
        organization {
          id
          phoneNumber
        }
      }
    }
  `,
);

const getOriginArrivalWindow = withFragment(
  (job) => {
    return (job as any).kind === 'HOLD'
      ? 'All Day'
      : `${(job as any).startTime1 ? Datetime.toDisplayTime(Datetime.fromTime((job as any).startTime1)) : 'TBD'} - ` +
          `${(job as any).startTime2 ? Datetime.toDisplayTime(Datetime.fromTime((job as any).startTime2)) : 'TBD'}`;
  },
  gql`
    fragment Job_getOriginArrivalWindow on Job {
      id
      kind
      startTime1
      startTime2
    }
  `,
);

const getCrews = withFragment(
  (job, {viewer}) => {
    // Only the primary organization shows all crews
    const crews = viewer.viewingOrganization.isPrimary
      ? (job as any).crews
      : (job as any).crews.filter(
          (crew: any) => crew.organization.id === viewer.viewingOrganization.id,
        );

    return _.sortBy(
      crews,
      (crew) => !crew.isPrimary,
      (crew) => crew.organization.name,
    );
  },
  gql`
    fragment Job_getCrews_Job on Job {
      id
      crews {
        id
        organization {
          id
        }
      }
    }

    fragment Job_getCrews_User on User {
      id
      viewingOrganization {
        id
        isPrimary
      }
    }
  `,
);

const primaryStatusFilteringOptions = [
  {label: 'Dispatch not done', value: 'NOT_READY'},
  {label: 'Dispatch done', value: 'READY'},
  {label: 'In Progress', value: 'IN_PROGRESS'},
  {label: 'Incomplete', value: 'INCOMPLETE'},
  {label: 'Report not final', value: 'NOT_FINAL'},
  {label: 'Report is final', value: 'FINAL'},
];

// Navigations
const _getParamsString = ({params}: any) => {
  const paramKeys = Object.keys(params);
  return paramKeys.reduce((result, key) => `${result}&${key}=${params[key]}`, '');
};

const goToEditProjectJobs = withFragment(
  (job, navigator, {projectUuid, ...params}) => {
    navigator.push(
      `/projects/${projectUuid}/edit/jobs?jobUuid=${(job as any).uuid}${_getParamsString({params})}`,
    );
  },
  gql`
    fragment Job_goToEditProjectJobs on Job {
      id
      uuid
    }
  `,
);

const _getMarkAsCompletedTooltip = withFragment(
  (job) => {
    if (!Job.getIsCompletable(job)) {
      if ((job as any).isUnscheduled) {
        return 'Date is required';
      }
      if (Datetime.isFuture((job as any).startDate)) {
        return 'Job has not started';
      }
      if ((job as any).isComplete) {
        return 'Job is already completed';
      }
    }

    return '';
  },
  gql`
    ${getIsCompletable.fragment}
    fragment Job_getMarkAsCompletedTooltip on Job {
      id
      isUnscheduled
      startDate
      isComplete
      ...Job_getIsCompletable
    }
  `,
);

const MAX_JOBS_TOOLTIP = `Maximum number of jobs reached`;

const getUpdateActions = withFragment(
  (
    job: JobModel,
    {
      responsive,
      navigator,
      projectUuid,
      isProjectAtMaxJobs,
      userRole,

      // Desktop hook handlers
      markAsCompletedActionHook,
      cancelJobActionHook,
      restoreJobActionHook,
      resetJobActionHook,
      finalizeJobActionHook,
      unfinalizeJobActionHook,

      // Mobile onPress handlers
      onPressMarkAsCompleted,
      onPressResetJob,
      onPressCancelJob,
      onPressRestoreJob,
      onPressFinalizeJob,
      onPressUnfinalizeJob,
    },
  ) => {
    return [
      responsive.desktop
        ? {
            text: 'Edit job',
            isDisabled: job.isFinal || job.isCancelled,
            tooltip: job.isFinal ? UserRole.getJobActionDisabledTooltip(userRole) : '',
            onPress: () => goToEditProjectJobs(job, navigator, {projectUuid}),
          }
        : {
            text: 'Add job',
            isDisabled: job.isFinal || isProjectAtMaxJobs || job.isCancelled,
            tooltip: job.isFinal
              ? UserRole.getJobActionDisabledTooltip(userRole)
              : isProjectAtMaxJobs
                ? MAX_JOBS_TOOLTIP
                : '',
            onPress: () =>
              goToEditProjectJobs(job, navigator, {projectUuid, action: Project.JOB_ACTIONS.ADD}),
          },
      {
        text: 'Duplicate job',
        isHidden: job.isCancelled,
        isDisabled: isProjectAtMaxJobs,
        tooltip: isProjectAtMaxJobs ? MAX_JOBS_TOOLTIP : '',
        onPress: () =>
          goToEditProjectJobs(job, navigator, {
            projectUuid,
            action: Project.JOB_ACTIONS.DUPLICATE,
            actionUuid: job.uuid,
          }),
      },
      {
        text: 'Mark as completed',
        isHidden: job.isCancelled,
        isDisabled: !getIsCompletable(job),
        tooltip: _getMarkAsCompletedTooltip(job),
        ...(responsive.desktop
          ? {actionHook: markAsCompletedActionHook}
          : {onPress: onPressMarkAsCompleted}),
      },
      {
        text: 'Finalize job',
        isHidden:
          !UserRole.ADMIN_ROLES_PLUS_SUPER.includes(userRole) || job.isCancelled || job.isFinal,
        isDisabled: !job.isComplete,
        tooltip: !job.isComplete ? 'This job must be complete before finalizing.' : '',
        ...(responsive.desktop
          ? {actionHook: finalizeJobActionHook}
          : {onPress: onPressFinalizeJob}),
      },
      {
        text: 'Unfinalize job',
        isHidden: !UserRole.getIsStaffAdmin(userRole) || job.isCancelled || !job.isFinal,
        ...(responsive.desktop
          ? {actionHook: unfinalizeJobActionHook}
          : {onPress: onPressUnfinalizeJob}),
      },
      {
        text: 'Reset job',
        color: colors.red.warning,
        isDisabled: !getIsResetable(job),
        tooltip: !getIsResetable(job) ? 'Can only reset while in progress' : '',
        isHidden: job.isCancelled,
        ...(responsive.desktop ? {actionHook: resetJobActionHook} : {onPress: onPressResetJob}),
      },
      {
        text: 'Print job card',
        isHidden: !job.organization.features.isEnabledPrintJobCard || job.isCancelled,
        onPress: () =>
          navigator.pushNewTab(`/0/${job.organization.slug}/jobs/${job.uuid}/print/card`),
      },
      {
        text: job.organization.features.isEnabledCancelProject ? 'Remove job' : 'Cancel job',
        color: colors.red.warning,
        isDisabled: job.isFinal || job.hasActiveChildJobs,
        tooltip: job.isFinal
          ? UserRole.getJobActionDisabledTooltip(userRole)
          : job.hasActiveChildJobs
            ? 'Please cancel all individual jobs before cancelling the spread date job.'
            : '',
        isHidden: job.isCancelled,
        ...(responsive.desktop ? {actionHook: cancelJobActionHook} : {onPress: onPressCancelJob}),
      },
      {
        text: 'Restore job',
        isHidden: !job.isCancelled,
        isDisabled: isProjectAtMaxJobs,
        ...(responsive.desktop ? {actionHook: restoreJobActionHook} : {onPress: onPressRestoreJob}),
      },
    ];
  },
  gql`
    ${goToEditProjectJobs.fragment}
    ${_getMarkAsCompletedTooltip.fragment}
    ${getIsCompletable.fragment}
    ${getIsResetable.fragment}

    fragment Job_getUpdateActions on Job {
      id
      uuid
      isCancelled
      isComplete
      isFinal
      hasActiveChildJobs
      organization {
        id
        slug
        features {
          isEnabledPrintJobCard: isEnabled(feature: "PRINT_JOB_CARD")
          isEnabledCancelProject: isEnabled(feature: "CANCEL_PROJECT")
        }
      }
      ...Job_goToEditProjectJobs
      ...Job_getMarkAsCompletedTooltip
      ...Job_getIsCompletable
      ...Job_getIsResetable
    }
  `,
);

const getShareActions = withFragment(
  (job: JobModel, {moverLinkToast, trackingLinkToast}) => {
    return [
      {
        text: 'Copy link to share with movers',
        onPress: () => {
          Clipboard.setString(makeUrl(`/1/jobs/${job.uuid}/view`));
          moverLinkToast.handleToast();
        },
        isHidden: job.isCancelled,
      },
      {
        text: 'Copy tracking link to share with customers',
        onPress: () => {
          Clipboard.setString(makeUrl(`/0/${job.organization.slug}/jobs/${job.uuid}/track`));
          trackingLinkToast.handleToast();
        },
        isHidden: job.isCancelled,
      },
    ];
  },
  gql`
    fragment Job_getShareActions on Job {
      id
      uuid
      isCancelled
      organization {
        id
        slug
      }
    }
  `,
);

const getActions = withFragment(
  (
    job: JobModel,
    {
      responsive,
      navigator,
      projectUuid,
      moverLinkToast,
      trackingLinkToast,
      isProjectAtMaxJobs,
      userRole,

      // Desktop hook handlers
      markAsCompletedActionHook,
      cancelJobActionHook,
      restoreJobActionHook,
      resetJobActionHook,
      finalizeJobActionHook,
      unfinalizeJobActionHook,

      // Mobile onPress handlers
      onPressMarkAsCompleted,
      onPressCancelJob,
      onPressRestoreJob,
      onPressResetJob,
      onPressFinalizeJob,
      onPressUnfinalizeJob,
    },
  ) => {
    return [
      {
        label: 'Job',
        actions: getUpdateActions(job, {
          responsive,
          navigator,
          projectUuid,
          isProjectAtMaxJobs,
          userRole,
          markAsCompletedActionHook,
          cancelJobActionHook,
          restoreJobActionHook,
          resetJobActionHook,
          finalizeJobActionHook,
          unfinalizeJobActionHook,
          onPressMarkAsCompleted,
          onPressCancelJob,
          onPressRestoreJob,
          onPressResetJob,
          onPressFinalizeJob,
          onPressUnfinalizeJob,
        }),
      },
      {
        label: 'Share',
        actions: getShareActions(job, {moverLinkToast, trackingLinkToast}),
      },
    ];
  },
  gql`
    ${getUpdateActions.fragment}
    ${getShareActions.fragment}

    fragment Job_getActions on Job {
      id
      ...Job_getUpdateActions
      ...Job_getShareActions
    }
  `,
);

// Used only on Crew.
const getCrewPrimaryStatusColor = withFragment(
  (job: JobModel) => {
    if (job.project.isCancelled) {
      return colors.red.warning;
    }
    switch (job.crewPrimaryStatus) {
      // TODO(dan): Remove cancelled status once fully migrated for isEnabledCancelProject
      case 'CANCELLED':
        return colors.red.warning;
      case 'NOT_READY':
      case 'READY':
        return colors.gray.secondary;
      case 'IN_PROGRESS':
        return colors.orange.status;
      case 'INCOMPLETE':
      case 'COMPLETE':
      case 'NOT_FINAL':
      case 'FINAL':
        return colors.green.status;
      default:
        return colors.gray.secondary;
    }
  },
  gql`
    fragment Job_getCrewPrimaryStatusColor on Job {
      id
      crewPrimaryStatus
      project {
        id
        isCancelled
      }
    }
  `,
);

// Used only on Crew.
const getCrewPrimaryStatusText = withFragment(
  (job: JobModel) => {
    if (job.project.isCancelled) {
      return 'Cancelled';
    }
    switch (job.crewPrimaryStatus) {
      // TODO(dan): Remove cancelled status once fully migrated for isEnabledCancelProject
      case 'CANCELLED':
        return 'Cancelled';
      case 'NOT_READY':
      case 'READY':
        return 'Not Started';
      case 'IN_PROGRESS':
        return 'In Progress';
      case 'INCOMPLETE':
      case 'COMPLETE':
      case 'NOT_FINAL':
      case 'FINAL':
        return 'Completed';
      default:
        return '';
    }
  },
  gql`
    fragment Job_getCrewPrimaryStatusText on Job {
      id
      crewPrimaryStatus
      project {
        id
        isCancelled
      }
    }
  `,
);

const Job = {
  FIELDS: {
    LOCATION_NOTES: 'Location Notes',
  },

  KIND,
  getKindLabel,

  // TODO(peter): migrate this to an enums file
  STATUS: {
    BOOKED: 'BOOKED',
    LEAD: 'LEAD',
  },

  PRIMARY_STATUS,

  // Formatters
  formatKindAsEnum: (kind: any) => _.toUpper(_.snakeCase(kind)),
  formatKindAsParam: (kind: any) => _.replace(_.snakeCase(kind), new RegExp('_', 'g'), '-'), // eslint-disable-line

  // Getters
  getUpdateActions,
  getShareActions,
  getActions,
  getAuthorizeDotNetUrlWithAmount,
  getActiveJobUsersCountText,
  getCalendarPrimaryStatusColor,
  getCalendarPrimaryStatusIcon,
  getCalendarPrimaryStatusText,
  getCalendarPrimaryStatusTextWithName,
  getCalendarSecondaryStatusIcon,
  getCalendarSecondaryStatusText,
  getCrews,
  getCrewSizeText,
  getTruckCountText,
  getDispatchDisplayDate,
  getDispatchJobTrucksCount,
  getDispatchJobUsersCount,
  getDispatchJobUsersNames,
  getDisplayArrivalWindow,
  getDisplayDate,
  getDisplayReferral,
  getDisplayText,
  getDrivingPrimaryStatusColor,
  getDrivingStatusText,
  getEstimatedArrivalText,
  getDisplayEstimateHours,
  getEstimateTotal,
  getEstimatedRangeJobCounter,
  getDisplayFirstAndLastCityState,
  getFullName,
  getJobDate,
  getJobDisplayDate,
  getJobNameForCrew,
  getHourMinimumText,
  getIsEstimatedRange,
  getIsChildJob,
  getIsCompletable,
  getIsResetable,
  getIsBeforeMove,
  getIsDuringMove,
  getIsAfterMove,
  getIsCommercial,
  getIsEstimate,
  getIsMove,
  getIsSalesLocked,
  getShowMovers,
  getShowTrucks,
  getPaymentName,
  getTipName,
  getLocations,
  getLocationUpdateText,
  getMaxEstimateHours,
  getOperationsStatus,
  getOperationsStatusText,
  getOrganizationBusinessAddress,
  getOrganizationBusinessName,
  getOrganizationBusinessPhoneNumber,
  getOriginArrivalWindow,
  getPrimaryJobRate,
  getStartLocationTitle,
  getStartTimeAt,
  getTimesheetScreen,
  getTimesheetMissingRequirements,
  getTravelFeeText,

  primaryStatusFilteringOptions,

  hasEstimateHours,
  hasFuelFee,
  hasEstimatedRangeJobCounter,

  isInPrepareState,

  sortJobsBySequence,

  getArrivalWindow: (job: any) => {
    return (
      `${Datetime.convertToDisplayTime(job.startTime1)} - ` +
      `${Datetime.convertToDisplayTime(job.startTime2)}`
    );
  },

  getAdditionalItems,

  // Used only on Crew.
  getCrewPrimaryStatusColor,
  getCrewPrimaryStatusText,

  getIsPrepareJobStarted: ({move}: any) => {
    return !!_.get(move, 'isPrepareJobStarted');
  },

  getTotalDistanceText,

  // Navigations
  goToEditProjectJobs,

  /**
   * DEPRECATED
   */

  // TODO(warren): Outdate usage of this method.
  getHourlyRate: ({hourlyRate, organization}: any) => {
    if (hourlyRate === 0) {
      return '---';
    }
    if (getNoDemoSlug(organization.slug) === 'caremoremoving') {
      return (
        `${Currency.display(hourlyRate * 100, {shouldHideCentsIfZero: true})} ` +
        `(${Currency.display((hourlyRate - 15) * 100, {shouldHideCentsIfZero: true})} with cash)`
      );
    } else {
      return Currency.display(hourlyRate * 100, {shouldHideCentsIfZero: true});
    }
  },
};

export default Job;
