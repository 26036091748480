const CALL_SUMMARY = 'CALL_SUMMARY';
const CALL_ACTION_ITEMS = 'CALL_ACTION_ITEMS';
const CALL_LEAD_DETAILS = 'CALL_LEAD_DETAILS';
const COMPLETE_CALL_TASKS = 'COMPLETE_CALL_TASKS';
const GENERATE_EMAIL = 'GENERATE_EMAIL';
const GENERATE_SMS = 'GENERATE_SMS';
const CUSTOM = 'CUSTOM';

const ChatCompletionKind = {
  CALL_SUMMARY,
  CALL_ACTION_ITEMS,
  CALL_LEAD_DETAILS,
  COMPLETE_CALL_TASKS,
  GENERATE_EMAIL,
  GENERATE_SMS,
  CUSTOM,
};

export default ChatCompletionKind;
