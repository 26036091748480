// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled} from '@supermove/components';
import {Form, useResponsive} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import Line from '@shared/design/components/Line';
import {CompanyFormProps} from '@shared/modules/Company/forms/CompanyForm';
import SupermoveProductKind from '@shared/modules/SupermoveProduct/enums/SupermoveProductKind';
import {SupermoveProductFormProps} from '@shared/modules/SupermoveProduct/forms/SupermoveProductForm';

const StepHeaderText = Styled.Text`
  ${Typography.Responsive.Heading2}
`;

const StepHeaderDescription = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const OfficeProductfields = ({
  form,
  field: parentField,
  activePrimaryOrganizationsForCore,
}: {
  form: Form<{companyForm: CompanyFormProps}>;
  field: string;
  activePrimaryOrganizationsForCore: OrganizationModel[];
}) => {
  const responsive = useResponsive();
  const supermoveProductForms = _.get(form.values, `${parentField}.supermoveProductForms`);
  const index = _.findIndex(
    supermoveProductForms,
    (supermoveProductForm: SupermoveProductFormProps) =>
      supermoveProductForm.kind === SupermoveProductKind.OFFICE,
  );
  const field = `${parentField}.supermoveProductForms.${index}`;
  return (
    <React.Fragment>
      <FieldInput
        {...form}
        index={0}
        name={`${field}.contractedTruckQuantity`}
        label={'Number of Trucks'}
        isRequired
        isResponsive
        input={{
          placeholder: 'Enter number of trucks',
        }}
        handleBlur={(event) => {
          const text = event.target.value;
          form.setFieldValue(`${field}.contractedTruckQuantity`, _.parseInt(text) || '');
        }}
      />
      <Space height={16} />
      <Line />
      <Space height={16} />
      <StepHeaderText responsive={responsive}>Select Existing Company as Template</StepHeaderText>
      <Space height={8} />
      <StepHeaderDescription
        responsive={responsive}
      >{`The following modules will be copied over for Core: project types and job types, billing libraries, document templates, email templates, SMS templates, reports, inventory templates, task templates.`}</StepHeaderDescription>
      <Space height={16} />
      <FieldInput
        {...form}
        index={1}
        // sourceOrganizationId is on companyForm
        name={`${parentField}.sourceOrganizationId`}
        label={'Company'}
        component={DropdownInput}
        isResponsive
        input={{
          isClearable: true,
          setFieldValue: form.setFieldValue,
          placeholder: 'Select company',
          options: _.sortBy(activePrimaryOrganizationsForCore, ({name}) => _.toLower(name)).map(
            ({name, id}) => ({
              label: name,
              value: id,
            }),
          ),
          style: {flex: 1},
          onChangeValue: (newValue: string, option: {label: string}) => {
            if (option) {
              // The name is used for display purposes only on the review step
              form.setFieldValue(`${parentField}.sourceOrganizationName`, option.label);
            }
          },
        }}
        style={{flex: 1}}
      />
    </React.Fragment>
  );
};

export default OfficeProductfields;
