// Libraries
import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';

export {default as flatten} from 'flat';
export {default as Fuse} from 'fuse.js';
export {default as humanize} from 'humanize-string';
export {default as invariant} from 'invariant';
export {default as pluralize} from 'pluralize';
export {default as titleize} from 'titleize';
export {default as uuid} from 'uuid/v4';
export {default as Collection} from './Collection';
export {default as Currency} from './Currency';
export {default as Datetime} from './Datetime';
export {default as Explo} from './Explo';
export {default as Distance} from './Distance';
export {default as Document} from './Document';
export {default as Duration} from './Duration';
export {default as Email} from './Email';
export {default as Float} from './Float';
export {default as HTML} from './HTML';
export {default as Json} from './Json';
export {default as Language} from './Language';
export {default as List} from './List';
export {default as Location} from './Location';
export * from './MakeOptional';
export {default as Percent} from './Percent';
export {default as Phone} from './Phone';
export {default as Retry} from './Retry';
export {default as setFormServerErrors} from './setFormServerErrors';
export {default as Sleep} from './Sleep';
export {default as URL} from './URL';
export {default as Validation} from './Validation';
export {default as Weight} from './Weight';

// Coerce two given IDs into strings and compare for equality.
// This is because sometimes IDs are ints and sometimes IDs are strings.
export const isSameId = (idOne?: string, idTwo?: string) => String(idOne) === String(idTwo);

export const removeTypename = (array: Array<{__typename: string}>) =>
  array.map(({__typename, ...item}) => item);

// Used to add a fragment to a function definition in one line.
// withFragment(() => {}, gql`MY FRAGMENT`);
export const withFragment = <TSrc, TDest, TFragment>(
  object: ((src: TSrc, ...args: any) => TDest) & {fragment?: TFragment},
  fragment: TFragment,
) => {
  object.fragment = fragment;
  return object;
};

export const makeUrl = (path: string) => {
  const {hostname, port, protocol} = window.location;
  return `${protocol}//${hostname}${port ? `:${port}` : port}${path}`;
};

// Sorts the `array` based on the order passed in for `values`. If an item is not
// included in `values`, then it is at the bottom of the list.
export const sortByValues = <T>(array: T[], key: string, values: T[]) => {
  return _.sortBy(array, (item) => {
    const value = _.get(item, key);
    const index = values.indexOf(value);
    if (index < 0) {
      return Infinity;
    }
    return index;
  });
};

export const sortByAlphabetical = <T extends Record<K, string>, K extends keyof T>(key: K) => {
  return (a: T, b: T) => {
    return a[key].localeCompare(b[key]);
  };
};

export const blur = (ref: React.MutableRefObject<React.ReactInstance>) => {
  const node = ReactDOM.findDOMNode(ref.current) as HTMLElement;
  setTimeout(() => node && node.blur(), 0);
};

// This is a hacky way to focus on a field after all callbacks have been called.
export const focus = (ref: React.MutableRefObject<React.ReactInstance>) => {
  const node = ReactDOM.findDOMNode(ref.current) as HTMLElement;
  setTimeout(() => node && node.focus(), 0);
};

export const openNewTab = (url: string) => {
  const link = document.createElement('a');
  link.target = '_blank';
  link.href = url;
  link.click();
};

export const downloadFromUrl = (url: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.click();
};

export const downloadMultipleFromUrls = async (urls: string[]) => {
  for (const url of urls) {
    downloadFromUrl(url);
    // Normally this rule is good because you'd want each async task to start at the same time
    // But here we actively want to wait a bit on each iteration before starting the next one
    // eslint-disable-next-line no-await-in-loop
    await new Promise((resolve) => {
      setTimeout(resolve, 750); // Delay to prevent browser from blocking due to rapid windows opening
    });
  }
};

// Used to count the number of decimal places in a number
// Good to use with number.toFixed(numOfDecimalPlaces) for Javascript floats
export const countDecimalPlaces = (number: number) => {
  const numberStr = String(number);
  const index = numberStr.indexOf('.');
  if (index >= 0) {
    return numberStr.length - index - 1;
  } else {
    return 0;
  }
};

// Used to filter out null or undefined values
export const existenceFilter = <T>(item: T | null | undefined): item is T => {
  return Boolean(item);
};

// Used to check if a field exists on an object
export const fieldExists = <T, K extends keyof T, V = Exclude<T[K], undefined | null>>(
  obj: T,
  key: K,
): obj is T & {[P in K]: V} => {
  return obj !== null && obj !== undefined && obj[key] !== null && obj[key] !== undefined;
};
