// Libraries
import _ from 'lodash';

// Supermove
import {useEffect, useState, useMemo} from '@supermove/hooks';

export interface StepType {
  kind: string;
  steps?: StepType[];
}

const getAllStepKinds = (steps: StepType[]) => {
  const flattenedStepKinds = _.flatMap(steps, (step: StepType) => [
    step.kind,
    ...(step.steps || []).map((subStep: StepType) => subStep.kind),
  ]);
  return flattenedStepKinds;
};

const getNextStepToComplete = ({
  steps,
  completedSteps,
}: {
  steps: StepType[];
  completedSteps: string[];
}) => {
  const flattenedStepKinds = getAllStepKinds(steps);
  const nextStep = _.first(_.difference(flattenedStepKinds, completedSteps));
  return nextStep;
};

const getCurrentStepIndex = ({steps, kind}: {steps: StepType[]; kind: string}) => {
  const parentIndex = _.findIndex(steps, {kind});
  if (parentIndex !== -1) {
    return parentIndex;
  } else {
    const parentIndexWithSteps = _.findIndex(
      steps,
      (obj) => _.isArray(obj.steps) && _.findIndex(obj.steps, {kind}) !== -1,
    );
    if (parentIndexWithSteps !== -1) {
      return parentIndexWithSteps;
    }
  }
  return -1;
};

const useProgress = ({
  steps,
  getPreviousStepKind,
  getNextStepKind,
}: {
  steps: StepType[];
  getPreviousStepKind: (kind: string) => string | null;
  getNextStepKind: (kind: string) => string | null;
}) => {
  const firstStepKind = steps[0].kind;
  const [currentStepKind, setCurrentStepKind] = useState<string>(firstStepKind);
  const [completedSteps, setCompletedSteps] = useState<string[]>([]);
  const isViewingFirstStep = currentStepKind === firstStepKind;

  // Update the previous and next step kinds when the current step kind changes
  const [previousStepKind, setPreviousStepKind] = useState<string | null>(
    getPreviousStepKind(firstStepKind),
  );
  const [nextStepKind, setNextStepKind] = useState<string | null>(getNextStepKind(firstStepKind));
  useEffect(() => {
    setPreviousStepKind(getPreviousStepKind(currentStepKind));
    setNextStepKind(getNextStepKind(currentStepKind));
  }, [currentStepKind]);

  const allStepKinds = useMemo(() => getAllStepKinds(steps), [steps]);

  const [nextStepToComplete, setNextStepToComplete] = useState('');
  useEffect(
    () => setNextStepToComplete(getNextStepToComplete({steps, completedSteps}) || ''),
    [completedSteps, steps],
  );

  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  useEffect(
    () => setCurrentStepIndex(getCurrentStepIndex({steps, kind: currentStepKind})),
    [steps, currentStepKind],
  );

  return {
    currentStepKind,
    setCurrentStepKind,
    isViewingFirstStep,
    previousStepKind,
    nextStepKind,
    completedSteps,
    setCompletedSteps,
    allStepKinds,
    nextStepToComplete,
    currentStepIndex,
  };
};

export default useProgress;
