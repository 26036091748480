export interface SupermoveProductFormProps {
  supermoveProductId: number | null;
  companyId: string | number | null;
  kind: string;
  status: string;
  numberOfLicenses: number | null;
  contractedTruckQuantity: number | null;

  // private
  isSetupComplete: boolean;
}

const _new = ({
  kind,
  status,
  numberOfLicenses,
  contractedTruckQuantity,
  companyId,
}: {
  kind: string;
  status: string;
  numberOfLicenses?: number;
  contractedTruckQuantity?: number;
  companyId?: string | number;
}) => ({
  supermoveProductId: null,
  companyId: companyId || null,
  kind,
  status,
  numberOfLicenses: numberOfLicenses || null,
  contractedTruckQuantity: contractedTruckQuantity || null,

  // private
  isSetupComplete: false,
});

const toForm = (supermoveProductForm: SupermoveProductFormProps) => ({
  supermoveProductId: supermoveProductForm.supermoveProductId,
  companyId: supermoveProductForm.companyId,
  kind: supermoveProductForm.kind,
  status: supermoveProductForm.status,
  numberOfLicenses: supermoveProductForm.numberOfLicenses,
  contractedTruckQuantity: supermoveProductForm.contractedTruckQuantity,

  // private
  isSetupComplete: supermoveProductForm.isSetupComplete,
});

const toMutation = (supermoveProductForm: SupermoveProductFormProps) => ({
  supermoveProductId: supermoveProductForm.supermoveProductId,
  companyId: supermoveProductForm.companyId,
  kind: supermoveProductForm.kind,
  status: supermoveProductForm.status,
  numberOfLicenses: supermoveProductForm.numberOfLicenses,
  contractedTruckQuantity: supermoveProductForm.contractedTruckQuantity,
});

const SupermoveProductForm = {
  new: _new,
  toForm,
  toMutation,
};

export default SupermoveProductForm;
