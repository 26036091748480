/* eslint-disable import/no-import-module-exports */
// False positive due to https://github.com/import-js/eslint-plugin-import/issues/2181
import 'react-hot-loader/patch';
import React from 'react';
import ReactDOM from 'react-dom';
import {AppContainer} from 'react-hot-loader';

import {Root} from './root';

// Follows the structure required by react-hot-loader:
//   https://gaearon.github.io/react-hot-loader/getstarted/

function render(Component: React.FC): void {
  ReactDOM.render(
    <AppContainer>
      <Component />
    </AppContainer>,
    document.getElementById('___gatsby'),
  );
}

render(Root);

// @ts-expect-error TS(2339)
if (module.hot) {
  // @ts-expect-error TS(2339)
  module.hot.accept('./root', () => {
    render(Root);
  });
}
