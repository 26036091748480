// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Form, useResponsive} from '@supermove/hooks';
import {CompanyModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import FieldValue from '@shared/design/components/Field/FieldValue';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import CreateCompanyBranchStep from '@shared/modules/Organization/enums/CreateCompanyBranchStep';
import OrganizationKind from '@shared/modules/Organization/enums/OrganizationKind';
import {CompanyBranchFormToFormType} from '@shared/modules/Organization/forms/CompanyBranchForm';

const Container = Styled.View`
  height: 100%;
`;

const ReviewPanel = ({
  stepKind,
  setCurrentStepKind,
  BodyComponent,
}: {
  stepKind: string;
  setCurrentStepKind: (kind: string) => void;
  BodyComponent: React.ComponentType;
}) => {
  return (
    <ActionPanel
      title={CreateCompanyBranchStep.getStep(stepKind)?.title || ''}
      ActionButtonComponent={() => (
        <EditPanel.EditButton
          handleEdit={() => setCurrentStepKind(stepKind)}
          isEditing={false}
          isDisabled={false}
        />
      )}
      BodyComponent={BodyComponent}
    />
  );
};

const BranchDetailsPanel = ({
  form,
  field,
  setCurrentStepKind,
}: {
  form: Form<{companyBranchForm: CompanyBranchFormToFormType}>;
  field: string;
  setCurrentStepKind: (kind: string) => void;
}) => {
  return (
    <ReviewPanel
      stepKind={CreateCompanyBranchStep.BRANCH_DETAILS}
      setCurrentStepKind={setCurrentStepKind}
      BodyComponent={() => {
        return (
          <React.Fragment>
            <FieldValue
              isResponsive
              label={'Branch Name'}
              value={_.get(form.values, `${field}.name`)}
            />
            <Space height={16} />
            <FieldValue
              isResponsive
              label={'Identifier'}
              value={_.get(form.values, `${field}.slug`)}
            />
          </React.Fragment>
        );
      }}
    />
  );
};

const BranchTypePanel = ({
  form,
  field,
  setCurrentStepKind,
  company,
}: {
  form: Form<{companyBranchForm: CompanyBranchFormToFormType}>;
  field: string;
  setCurrentStepKind: (kind: string) => void;
  company: CompanyModel;
}) => {
  const kind = _.get(form.values, `${field}.kind`);
  const sourceOrganization = _.find(company.organizations, {
    id: _.get(form.values, `${field}.sourceOrganizationId`),
  });
  return (
    <ReviewPanel
      stepKind={CreateCompanyBranchStep.BRANCH_TYPE}
      setCurrentStepKind={setCurrentStepKind}
      BodyComponent={() => {
        return (
          <React.Fragment>
            <FieldValue
              isResponsive
              label={'Branch Type'}
              value={OrganizationKind.getLabel(kind)}
            />
            {kind === OrganizationKind.FRANCHISE && (
              <React.Fragment>
                <Space height={16} />
                <FieldValue
                  isResponsive
                  label={'Duplicate Existing Branch'}
                  value={sourceOrganization?.name || 'None'}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        );
      }}
    />
  );
};

const CreateCompanyBranchReviewStep = ({
  form,
  field,
  setCurrentStepKind,
  company,
}: {
  form: Form<{companyBranchForm: CompanyBranchFormToFormType}>;
  field: string;
  setCurrentStepKind: (kind: string) => void;
  company: CompanyModel;
}) => {
  const responsive = useResponsive();
  return (
    <Container style={responsive.desktop ? {} : {backgroundColor: colors.white}}>
      <BranchDetailsPanel form={form} field={field} setCurrentStepKind={setCurrentStepKind} />
      <Space height={16} />
      <BranchTypePanel
        form={form}
        field={field}
        setCurrentStepKind={setCurrentStepKind}
        company={company}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateCompanyBranchReviewStep.fragment = gql`
  fragment CreateCompanyBranchReviewStep on Company {
    id
    organizations {
      id
      name
    }
  }
`;

export default CreateCompanyBranchReviewStep;
