// Supermove
import {gql} from '@supermove/graphql';
import {MutationError, useForm, useFormMutation} from '@supermove/hooks';

interface UseUpdateCompanyIdentifierMutation {
  updateCompanyIdentifierForm: {
    companyIdentifierId: number;
    value: number | null;
  };
  onSuccess: () => void;
  onError: (error: MutationError[]) => void;
}

const useUpdateCompanyIdentifierMutation = ({
  updateCompanyIdentifierForm,
  onSuccess,
  onError,
}: UseUpdateCompanyIdentifierMutation) => {
  const form = useForm({
    initialValues: {
      updateCompanyIdentifierForm,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateCompanyIdentifierMutation.mutation,
    variables: {
      updateCompanyIdentifierForm: form.values.updateCompanyIdentifierForm,
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateCompanyIdentifierMutation.mutation = gql`
    mutation useUpdateCompanyIdentifierMutation($updateCompanyIdentifierForm: UpdateCompanyIdentifierForm!) {
        response: updateCompanyIdentifier(updateCompanyIdentifierForm: $updateCompanyIdentifierForm) {
            companyIdentifier {
                id
                current
            }
            ${gql.errors}
        }
    }
`;

export default useUpdateCompanyIdentifierMutation;
