const CUSTOM = 'CUSTOM';
const NO_SURVEY = 'NO_SURVEY';
const VIRTUAL_WALKTHROUGH = 'VIRTUAL_WALKTHROUGH';

const SURVEY_METHOD_KINDS = [CUSTOM, NO_SURVEY, VIRTUAL_WALKTHROUGH];

export type SurveyMethodKindType = (typeof SURVEY_METHOD_KINDS)[number];

const SurveyMethodKind = {
  CUSTOM,
  NO_SURVEY,
  VIRTUAL_WALKTHROUGH,
};

export default SurveyMethodKind;
