// Libraries
import _ from 'lodash';

import {Config} from '@supermove/sdk';

const Document = {
  clickFile: ({
    file,
    filename,
    ...attributes
  }: {
    file: File;
    filename: string;
  } & Record<string, unknown>) => {
    const url = window.URL.createObjectURL(file);
    const link = document.createElement('a');

    _.forEach(attributes, (value, key) => {
      // @ts-expect-error TS wants this to be the non-readonly anchor keys, doable to get that type but a little complicated
      link[key] = value;
    });

    link.href = url;
    link.click();

    // For Firefox it is necessary to delay revoking the ObjectURL
    // @ts-expect-error TS expects string but we are passing File
    setTimeout(() => window.URL.revokeObjectURL(file), 100);
  },

  /**
   * Opens a file in a new tab as a blob url.
   */
  openFile: ({file, filename}: {file: File; filename: string}) => {
    Document.clickFile({file, filename, target: '_blank'});
  },

  /**
   * Starts a download for the file with a given filename.
   */
  downloadFile: ({file, filename}: {file: File; filename: string}) => {
    Document.clickFile({file, filename, download: filename});
  },

  /**
   * Opens up a hidden iframe, renders the PDF, then opens the system print dialog.
   */
  printFile: ({file}: {file: Blob}) => {
    const url = window.URL.createObjectURL(file);
    const iframe = document.createElement('iframe');

    iframe.setAttribute('style', 'display: none;');
    iframe.setAttribute('type', 'application/pdf');
    iframe.setAttribute('src', url);

    // Add the iframe to the DOM and call print. Must be rendered in the DOM to
    // allow calling print.
    document.body.appendChild(iframe);
    setTimeout(() => iframe.contentWindow?.print(), 1000);

    // For Firefox it is necessary to delay revoking the ObjectURL
    // @ts-expect-error TS expects string but we are passing File
    setTimeout(() => window.URL.revokeObjectURL(file), 100);
  },

  /**
   * Opens up a hidden iframe, renders the PDF, then opens the system print dialog.
   * This function will retry the print operation if the iframe is not found.
   * Maximum of 5 attempts.
   */
  printFileWithRetries: ({file}: {file: Blob}) => {
    const url = window.URL.createObjectURL(file);
    const iframe = document.createElement('iframe');

    iframe.setAttribute('style', 'display: none;');
    iframe.setAttribute('type', 'application/pdf');
    iframe.setAttribute('src', url);

    // Add the iframe to the DOM and call print. Must be rendered in the DOM to
    // allow calling print.
    document.body.appendChild(iframe);

    // Try to print the file. If the iframe is not found, retry the operation.
    let attempts = 0;
    const maxAttempts = 5;
    const tryPrint = () => {
      if (iframe.contentWindow) {
        iframe.contentWindow.print();
      } else if (attempts < maxAttempts) {
        attempts += 1;
        setTimeout(tryPrint, 1000);
      }
    };
    setTimeout(tryPrint, 1000);

    // For Firefox it is necessary to delay revoking the ObjectURL
    // @ts-expect-error TS expects string but we are passing File
    setTimeout(() => window.URL.revokeObjectURL(file), 100);
  },

  /**
   * Hits our endpoint that fetches a PDF from a specified url.
   */
  generatePDFFromUrl: async ({filename, url}: {filename: string; url: string}) => {
    const params = {filename, url};
    const response = await fetch(`${Config.getAPIHost()}/manager/v1/generate_pdf_from_url`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });

    const blob = await response.blob();
    return blob;
  },

  /**
   * Fetches an existing document PDF from S3
   */
  fetchPDFFromS3: async ({filename, documentUuid}: {filename: string; documentUuid: string}) => {
    const params = {filename, uuid: documentUuid};
    const response = await fetch(`${Config.getAPIHost()}/manager/v1/fetch_document_pdf_from_s3`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });

    const blob = await response.blob();
    return blob;
  },
};

export default Document;
