/* eslint-disable react/no-danger */
// @ts-expect-error library is not typed
import DOMPurify from 'dompurify';
import React, {useEffect, useRef, useState} from 'react';

// Supermove
import {useMemo} from '@supermove/hooks';

const parseStylesAndBody = (rawHTML: string) => {
  let htmlStyles = '';
  let htmlBody = '';

  // Extract styles
  // Find start and end of style opening tag
  const styleTagStart = rawHTML.indexOf('<style');
  const cssStart = rawHTML.indexOf('>', styleTagStart) + 1;
  const cssEnd = rawHTML.indexOf('</style>');

  // We might not have a style tag
  if (styleTagStart !== -1 && cssStart !== 0 && cssEnd !== -1) {
    htmlStyles = `<style>${rawHTML.slice(cssStart, cssEnd)}</style>`;
  }

  // Extract html body
  // +6 for the length of <body>
  const bodyStart = rawHTML.indexOf('<body>') + 6;
  const bodyEnd = rawHTML.indexOf('</body>');

  // We might not have a body tag
  if (bodyStart !== 5 && bodyEnd !== -1) {
    htmlBody = rawHTML.slice(bodyStart, bodyEnd);
  } else {
    htmlBody = rawHTML;
  }

  return {htmlStyles, htmlBody};
};

const addATagsToLinks = (html: string) => {
  const urlRegex =
    /\b(?:(?:https?:\/\/|www\.)[^\s<]+|[a-zA-Z0-9.-]+\.(?:com|org|net|gov|edu|co|uk|ca|mx))\b/gi;

  return html.replace(urlRegex, (match, offset, fullText) => {
    // Check if "match" is already inside an <a> tag
    const beforeText = fullText.slice(0, offset);
    const openAnchorIndex = beforeText.lastIndexOf('<a ');
    const closeAnchorIndex = beforeText.lastIndexOf('</a>');

    // If we find an <a> tag without a closing tag after it,
    // we're inside a link, so skip
    if (openAnchorIndex > closeAnchorIndex) {
      return match;
    }

    // Prepend "https://" if it doesn't start with http/https
    let url = match;
    if (!/^https?:\/\//i.test(url)) {
      url = `https://${url}`;
    }

    // Return the anchor-wrapped URL
    return `<a href="${url}" target="_blank">${match}</a>`;
  });
};

const SanitizedHTML = ({rawHTML, linkifyUrls}: {rawHTML: string; linkifyUrls?: boolean}) => {
  const {htmlStyles, htmlBody} = parseStylesAndBody(rawHTML);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [iframeHeight, setIframeHeight] = useState<number | null | undefined>(null);

  // Sanitize the HTML and add a click handler to prevent links from opening
  // We need to recurse through all parent elements of an element to find one that may be a link,
  // since the click event may be triggered on a child element of the link that is a descendant of the <a> tag
  // Also, emails allow links with no protocol, but those are treated as relatives links in the preview
  const sanitizedHtml = useMemo(() => {
    const bodyWithSanitization = DOMPurify.sanitize(htmlBody);
    const bodyWithFixedLinks = linkifyUrls
      ? addATagsToLinks(bodyWithSanitization)
      : bodyWithSanitization;
    return `<body>
      ${bodyWithFixedLinks}
    <script>
      document.addEventListener('click', function(event) {
        let target = event.target;
        while (target && target.tagName !== 'A') {
          target = target.parentElement;
        }
        if (target && target.tagName === 'A') {
          event.preventDefault();
          window.open(target.href, '_blank');
        }
      }, true);
    </script>
    </body>`;
  }, [htmlBody]);

  useEffect(() => {
    // Write the HTML into the iframe document
    const iframeDoc = iframeRef.current?.contentWindow?.document;
    if (iframeDoc) {
      iframeDoc.open();
      iframeDoc.write(htmlStyles, sanitizedHtml);
      iframeDoc.close();
    }

    // Handle setting iframe height and retry to ensure content has loaded
    let retryCount = 0;
    const intervalId = setInterval(() => {
      const newHeight = iframeRef.current?.contentWindow?.document.body.scrollHeight;
      if (iframeHeight !== newHeight) {
        setIframeHeight(newHeight);
      }
      // Stop retrying after 10 attempts (2 seconds)
      retryCount += 1;
      if (retryCount === 10) {
        clearInterval(intervalId);
      }
    }, 200);
    return () => clearInterval(intervalId);
  }, [htmlStyles, iframeHeight, rawHTML, sanitizedHtml]);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        alignItems: 'center',
        // @ts-expect-error ts says this doesn't exist
        paddingVertical: '24px',
        justifyContent: 'center',
      }}
    >
      <iframe
        ref={iframeRef}
        style={{
          height: `${iframeHeight}px`,
          width: '100%',
          border: 'none',
        }}
        width='100%'
        title='Content container'
        sandbox='allow-downloads allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox'
        scrolling='no'
      />
    </div>
  );
};

export default SanitizedHTML;
