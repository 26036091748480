import React from 'react';

import {BrowserTyped} from '@supermove/app';

import AppWrapper from 'core/AppWrapper';

import {client, clientTyped} from './config';

export const Root = (props: {}) => {
  return (
    <BrowserTyped client={client} clientTyped={clientTyped}>
      <AppWrapper />
    </BrowserTyped>
  );
};
