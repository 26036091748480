// Libraries
import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {ResponsiveType, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const PageHeaderContainer = Styled.View<{responsive: ResponsiveType}>`
  z-index: 100;
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 0 : 16)}px;
  flex-direction: row;
  width: 100%;
`;

const TitleContainer = Styled.View`
  flex: 1;
`;

const PageTitle = Styled.Text`
  ${Typography.Responsive.Heading2}
`;

const PageDescription = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

interface CompanyPageContentHeaderProps {
  pageTitle: string;
  pageDescription?: string;
  actionElement?: React.ReactNode;
}

const CompanyPageContentHeader = ({
  pageTitle,
  pageDescription,
  actionElement,
}: CompanyPageContentHeaderProps) => {
  const responsive = useResponsive();
  return (
    <PageHeaderContainer responsive={responsive}>
      <TitleContainer>
        <PageTitle responsive={responsive}>{pageTitle}</PageTitle>
        <Space height={8} />
        <PageDescription responsive={responsive}>{pageDescription}</PageDescription>
      </TitleContainer>
      <Space width={16} />
      {actionElement}
    </PageHeaderContainer>
  );
};

export default CompanyPageContentHeader;
